import { computed, makeObservable, observable, reaction } from 'mobx';
import { PolicyCard } from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import { Vec2 } from '~/domain/geometry';
import { ArrowStrategy } from '~/domain/layout/abstract';
import {
  Arrow,
  ArrowColor,
  ArrowEnding,
  ArrowsMap,
  EndingFigure,
} from '~/domain/layout/abstract/arrows';
import { CimulatorPlacementStrategy } from '~/domain/layout/cimulator/placement';
import { PolicyStore } from '~/store/stores/policy';
import { RuleStatusKind } from '~/store/stores/policy/types';

enum ArrowDirection {
  IngressToSelector = 'ingress -> selector',
  SelectorToEgress = 'selector -> egress',
}

export class CimulatorArrowStrategy extends ArrowStrategy {
  @observable private placement: CimulatorPlacementStrategy;
  @observable private policy: PolicyStore;

  constructor(placement: CimulatorPlacementStrategy, policy: PolicyStore) {
    super();
    makeObservable(this);
    this.placement = placement;
    this.policy = policy;

    reaction(
      () => [
        this.arrowsMap,
        this.policy.cardsList,
        this.placement.cardsBBoxes,
        this.placement.accessPointCoords,
        this.policy.hasEgressRules,
        this.policy.hasIngressRules,
      ],
      () => {
        this.arrows.clear();
        this.arrowsMap.forEach((arrow, arrowId) => {
          this.arrows.set(arrowId, arrow);
        });
      },
    );
  }

  @computed get arrowsMap(): ArrowsMap {
    const arrows: ArrowsMap = new Map();
    if (!this.policy.cardsMap) return arrows;

    const { ingress, egress, selector } = this.policy.cardsMap;
    if (selector == null) return arrows;

    const selectorMidPoints = this.cardsMidPoints.get(selector.id);
    if (selectorMidPoints == null) return arrows;

    const [selectorL, selectorR] = selectorMidPoints;

    const buildMainArrow = (card: PolicyCard, direction: ArrowDirection) => {
      const midCoords = this.cardsMidPoints.get(card.id);
      if (midCoords == null) return;

      const [leftCenter, rightCenter] = midCoords;

      let [cardFigure, targetFigure] = [EndingFigure.Plate, EndingFigure.Arrow];
      let [cardEndCoords, targetEndCoords] = [
        rightCenter.clone(),
        selectorL.clone(),
      ];

      if (direction === ArrowDirection.SelectorToEgress) {
        [cardFigure, targetFigure] = [targetFigure, cardFigure];
        [cardEndCoords, targetEndCoords] = [
          leftCenter.clone(),
          selectorR.clone(),
        ];
      }

      const cardEnding: ArrowEnding = {
        endingId: card.id,
        figure: cardFigure,
        coords: cardEndCoords,
      };

      const selectorEnding: ArrowEnding = {
        endingId: selector.id,
        figure: targetFigure,
        coords: targetEndCoords,
      };

      let arrowId = `${card.id} -> ${selector.id}`;
      if (direction === ArrowDirection.SelectorToEgress) {
        arrowId = `${selector.id} -> ${card.id}`;
      }

      const [start, end] =
        direction === ArrowDirection.IngressToSelector
          ? [cardEnding, selectorEnding]
          : [selectorEnding, cardEnding];

      const arrow: Arrow = {
        arrowId,
        color: this.getArrowColor(card.id),
        noHandles: true,

        start,
        end,
      };

      arrows.set(arrowId, arrow);
    };

    const buildInnerArrows = (card: PolicyCard, direction: ArrowDirection) => {
      card.endpointsMap.forEach((ep, epId) => {
        const endpointId = card.fullEndpointId(epId);

        const innerMidPoints = this.innerMidPoints.get(endpointId);
        if (innerMidPoints == null) return;

        const [leftCenter, rightCenter] = innerMidPoints;

        let arrowId = `${endpointId} -> ${selector.id}`;
        if (direction === ArrowDirection.SelectorToEgress) {
          arrowId = `${selector.id} -> ${endpointId}`;
        }

        let [innerFigure, selectorFigure] = [
          EndingFigure.Plate,
          EndingFigure.Arrow,
        ];
        let [innerEndCoords, selectorEndCoords] = [
          rightCenter.clone(),
          selectorL.clone(),
        ];

        if (direction === ArrowDirection.SelectorToEgress) {
          [innerFigure, selectorFigure] = [selectorFigure, innerFigure];
          [innerEndCoords, selectorEndCoords] = [
            leftCenter.clone(),
            selectorR.clone(),
          ];
        }

        const innerEnding: ArrowEnding = {
          endingId: endpointId,
          figure: innerFigure,
          coords: innerEndCoords,
        };

        const selectorEnding: ArrowEnding = {
          endingId: selector.id,
          figure: selectorFigure,
          coords: selectorEndCoords,
        };

        const [start, end] =
          direction === ArrowDirection.IngressToSelector
            ? [innerEnding, selectorEnding]
            : [selectorEnding, innerEnding];

        const arrow: Arrow = {
          arrowId,
          color: this.getInnerArrowColor(card, ep),
          noHandles: true,

          start,
          end,
        };

        arrows.set(arrowId, arrow);
      });
    };

    const buildArrows = (card: PolicyCard, direction: ArrowDirection) => {
      if (card.endpointsMap.size === 0) {
        buildMainArrow(card, direction);
      }

      if (card.endpointsMap.size > 0) {
        buildInnerArrows(card, direction);
      }
    };

    ingress.list.filter(this.policy.isVisibleCard).forEach(card => {
      buildArrows(card, ArrowDirection.IngressToSelector);
    });

    egress.list.filter(this.policy.isVisibleCard).forEach(card => {
      buildArrows(card, ArrowDirection.SelectorToEgress);
    });

    return arrows;
  }

  @computed get cardsMidPoints(): Map<string, [Vec2, Vec2]> {
    const index: Map<string, [Vec2, Vec2]> = new Map();

    this.policy.cardsList?.forEach(c => {
      const bbox = this.placement.cardsBBoxes.get(c.id);
      if (bbox == null) return;

      const centerY = bbox.y + bbox.h / 2;

      const leftCenter = Vec2.fromXY({ x: bbox.x, y: centerY });
      const rightCenter = Vec2.fromXY({ x: bbox.x + bbox.w, y: centerY });

      index.set(c.id, [leftCenter, rightCenter]);
    });

    return index;
  }

  @computed get innerMidPoints(): Map<string, [Vec2, Vec2]> {
    const index: Map<string, [Vec2, Vec2]> = new Map();

    this.policy.cardsList?.forEach(c => {
      const bbox = this.placement.cardsBBoxes.get(c.id);
      if (bbox == null) return;

      c.endpointsMap.forEach((ep, epId) => {
        const endpointId = c.fullEndpointId(epId);
        const innerPointCoords = this.placement.accessPointCoords.get(
          endpointId,
        );
        if (innerPointCoords == null) return;

        const innerY = innerPointCoords.y;
        const leftCenter = Vec2.fromXY({ x: bbox.x, y: innerY });
        const rightCenter = Vec2.fromXY({ x: bbox.x + bbox.w, y: innerY });

        index.set(endpointId, [leftCenter, rightCenter]);
      });
    });

    return index;
  }

  private getArrowColor = (cardId: string): ArrowColor => {
    if (this.policy.isAllowedEndpoint(cardId)) return ArrowColor.Green;
    return this.getDefaultCardArrowColor(cardId);
  };

  private getInnerArrowColor = (
    card: PolicyCard,
    endpoint: PolicyEndpoint,
  ): ArrowColor => {
    const status = this.policy.getRuleStatusInfo(card, endpoint);

    if (!status) return ArrowColor.Neutral;

    if (
      status.kind === RuleStatusKind.UnsupportedByReason ||
      status.kind === RuleStatusKind.Ineffective
    ) {
      return ArrowColor.Neutral;
    }

    const allowed = [
      RuleStatusKind.AllowedByDefaultAllow,
      RuleStatusKind.AllowedByExplicitRule,
      RuleStatusKind.AllowedByOtherRule,
    ].includes(status.kind);

    if (allowed) return ArrowColor.Green;
    return this.getDefaultCardArrowColor(card.id);
  };

  private getDefaultCardArrowColor = (cardId: string): ArrowColor => {
    if (
      this.policy.cardsMap?.egress.has(cardId) &&
      !this.policy.hasEgressRules
    ) {
      return ArrowColor.Green;
    }

    if (
      this.policy.cardsMap?.ingress.has(cardId) &&
      !this.policy.hasIngressRules
    ) {
      return ArrowColor.Green;
    }

    return ArrowColor.Red;
  };
}
