import { observer } from 'mobx-react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Map as MapComponent } from '~/components/Map';
import {
  PoliciesPanel,
  ResizeProps as DPResizeProps,
} from '~/components/PoliciesPanel';
import { PolicyCard as CimulatorPolicyCard } from '~/components/PolicyCard';
import { PolicyCard } from '~/domain/cimulator/cards';
import { TrafficDirection } from '~/domain/cimulator/types';
import { Flow } from '~/domain/flows';
import { useFileUpload } from '~/domain/helpers/files';
import {
  CimulatorArrowStrategy,
  CimulatorPlacementStrategy,
} from '~/domain/layout/cimulator';
import { QueryParams, URLUtils } from '~/domain/url';
import { useNotifier } from '~/notifier';
import * as storage from '~/storage/local';
import { useStore } from '~/store';
import {
  AssistantEmitterActions,
  emitter as assistantEmitter,
} from '~/store/stores/assistant/emitter';
import { PolicyAssistantTutorial } from '~/store/stores/assistant/general';
import { PolicyAssistantMarkdownTutorial } from '~/store/stores/assistant/tutorials/markdown';
import { AnalyticsTrackKind, track } from '~/utils/analytics';
import { DownloadDialog } from './DownloadDialog';
import { useGistShare } from './hooks/useGistShare';
import { LoadingOverlay } from './LoadingOverlay';
import { OnboardingDialog } from './OnboardingDialog';
import { RemoveIngressEgressDialog } from './RemoveIngressEgressDialog';
import { ReplaceWithNewPolicyDialog } from './ReplaceWithNewPolicyDialog';
import { ShareDialog } from './ShareDialog';
import css from './styles.scss';
import { TopBar } from './TopBar';

export const CimulatorApp = observer(() => {
  // *** Globals ***
  const store = useStore();
  const notifier = useNotifier();
  // *** /Globals ***

  useEffect(() => {
    const counter = storage.incrementVisitsCounter();

    const policyUrl = URLUtils.getQueryParam(QueryParams.PolicyUrl);
    const policyTutorial = URLUtils.getQueryParam(QueryParams.PolicyTutorial);
    const policyGist = URLUtils.getQueryParam(QueryParams.PolicyGist);

    const query = {
      hasPolicyUrl: Boolean(policyUrl),
      policyTutorial: policyTutorial ?? null,
      policyGist: policyGist ?? null,
    };

    if (counter === 1) {
      track(AnalyticsTrackKind.FirstVisit, { query });
    } else {
      track(AnalyticsTrackKind.FollowingVisit, { counter, query });
    }
  }, []);

  // *** Refs ***
  const alertsPortal = useRef<HTMLDivElement>(null);
  // *** /Refs ***

  // *** Dialogs ***
  const [isOnboardingDialogOpen, setIsOnboardingDialogOpen] = useState<boolean>(
    !store.controls.skipOnboardingDialog && store.controls.tutorial === 'main',
  );
  const [isDownloadDialogOpen, setIsDownloadDialogOpen] =
    useState<boolean>(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState<boolean>(false);
  const [ingressEgressWarningAlert, setIngressEgressWarningAlert] =
    useState<TrafficDirection | null>(null);
  const [replaceWithNewPolicyDialog, setReplaceWithNewPolicyDialog] = useState<
    { kind: 'upload' } | { kind: 'new' } | null
  >();

  const onDownloadDialogClose = useCallback(() => {
    setIsDownloadDialogOpen(false);
  }, []);

  const onShareDialogClose = useCallback(() => {
    setIsShareDialogOpen(false);
  }, []);

  const hideIngressEgressWarningAlert = useCallback(() => {
    setIngressEgressWarningAlert(null);
  }, []);

  const onOnboardingDialogClose = useCallback(() => {
    setIsOnboardingDialogOpen(false);
  }, []);

  const onConfirmReplaceWithNewPolicyDialog = useCallback(() => {
    if (replaceWithNewPolicyDialog?.kind === 'new') {
      store.policy.createNew();
    } else if (replaceWithNewPolicyDialog?.kind === 'upload') {
      uploadPolicyFile();
    }
    setReplaceWithNewPolicyDialog(null);
  }, [replaceWithNewPolicyDialog?.kind]);

  const onCancelReplaceWithNewPolicyDialog = useCallback(() => {
    setReplaceWithNewPolicyDialog(null);
  }, [replaceWithNewPolicyDialog?.kind]);

  // *** /Dialogs ***

  // *** Policy YAML ***
  const [policyYaml, setPolicyYaml] = useState('');

  useEffect(() => {
    setPolicyYaml(store.policy.policyCurrentSpecYaml ?? '');
  }, [store.policy.policyCurrentSpecYaml]);

  const onCreateNewPolicy = useCallback(() => {
    if (store.policy.hasSomeRules) {
      setReplaceWithNewPolicyDialog({ kind: 'new' });
    } else {
      store.policy.createNew();
    }
  }, [store.policy.hasSomeRules]);
  // *** /Policy YAML ***

  // *** Download policy ***
  const initDownloadPolicy = useCallback(() => {
    if (!store.controls.skipDownloadPolicyDialog) {
      setIsDownloadDialogOpen(true);
    }
    store.policy.downloadYaml();
  }, [store.controls.skipDownloadPolicyDialog]);

  const onDownloadPolicy = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    store.policy.downloadYaml();
  }, []);
  // *** /Download policy ***

  // *** Share yaml ***
  const shareGist = useGistShare();

  const initShareYaml = useCallback(() => {
    setIsShareDialogOpen(true);
  }, []);
  // *** /Share yaml

  // *** Default deny ***
  const toggleDefaultDenyIngress = useCallback(() => {
    if (store.policy.hasIngressRules && store.policy.hasSomeIngress) {
      setIngressEgressWarningAlert(TrafficDirection.Ingress);
    } else {
      store.policy.toggleDefaultDenyIngress();
    }
  }, [
    store.policy.hasIngressRules,
    store.policy.hasSomeIngress,
    store.policy.enableDefaultDenyIngress,
  ]);

  const toggleDefaultDenyEgress = useCallback(() => {
    if (store.policy.hasEgressRules && store.policy.hasSomeEgress) {
      setIngressEgressWarningAlert(TrafficDirection.Egress);
    } else {
      store.policy.toggleDefaultDenyEgress();
    }
  }, [
    store.policy.hasEgressRules,
    store.policy.toggleDefaultDenyEgress,
    store.policy.hasSomeEgress,
  ]);

  useEffect(() => {
    return assistantEmitter.on(
      AssistantEmitterActions.ToggleDefaultDenyIngress,
      toggleDefaultDenyIngress,
    );
  }, [assistantEmitter, toggleDefaultDenyIngress]);

  useEffect(() => {
    return assistantEmitter.on(
      AssistantEmitterActions.ToggleDefaultDenyEgress,
      toggleDefaultDenyEgress,
    );
  }, [assistantEmitter, toggleDefaultDenyEgress]);

  const disableDefaultDeny = useCallback(() => {
    if (ingressEgressWarningAlert === TrafficDirection.Ingress) {
      store.policy.toggleDefaultDenyIngress();
    } else if (ingressEgressWarningAlert === TrafficDirection.Egress) {
      store.policy.toggleDefaultDenyEgress();
    }
    hideIngressEgressWarningAlert();
  }, [
    ingressEgressWarningAlert,
    store.policy.disableDefaultDenyIngress,
    store.policy.disableDefaultDenyEgress,
  ]);
  // *** /Default deny ***

  // *** Upload policy ***
  const [fetchingYaml, setFetchingYaml] = useState(false);

  const openTutorial = useCallback(
    (tutorial: PolicyAssistantTutorial) => {
      store.controls.setTutorial(tutorial.id);
      const done = () => {
        tutorial.yaml && loadYaml(tutorial.yaml);
        dropPolicyTutorialFromUrl();
      };
      if (!(tutorial instanceof PolicyAssistantMarkdownTutorial)) {
        done();
        return;
      }
      const promises: Promise<string>[] = [];
      if (!tutorial.markdown) {
        promises.push(store.assistant.fetchTutorialMarkdown(tutorial));
      }
      if (tutorial.yaml === null) {
        promises.push(store.assistant.fetchTutorialYaml(tutorial));
      }
      promises.length && store.assistant.startTutorialsFetch();
      Promise.all(promises)
        .then(done)
        .catch(error => {
          console.log(error);
          notifier.error({ message: "Can't fetch tutorial content" }).show();
        })
        .finally(store.assistant.stopTutorialsFetch);
    },
    [store.policy.hasSomeRules],
  );

  useEffect(() => {
    store.assistant
      .fetchTutorialsList()
      .then(() => {
        if (store.assistant.currentTutorial) {
          openTutorial(store.assistant.currentTutorial);
        }
      })
      .catch(error => {
        console.log(error);
        notifier.error({ message: "Can't fetch tutorials list" }).show();
      });
  }, []);

  useEffect(() => {
    return assistantEmitter.on(
      AssistantEmitterActions.OpenTutorial,
      openTutorial,
    );
  }, [assistantEmitter, openTutorial]);

  const loadYaml = useCallback((yaml: string) => {
    console.log('yaml', yaml);
    const result = store.policy.loadYamlAsNewPolicy({ policyYaml: yaml });
    if (!result.ok) {
      track(AnalyticsTrackKind.UploadInvalidPolicyYaml);
      notifier
        .error({
          message: (
            <div>
              <b>Invalid policy YAML</b>
              <ul>
                {result.errors.map((error, idx) => {
                  return <li key={`${error}-${idx}`}>{error}</li>;
                })}
              </ul>
            </div>
          ),
          timeout: 20000,
        })
        .show();
    }
  }, []);

  const policyTemplateUrl = useMemo(() => {
    return URLUtils.getQueryParam(QueryParams.PolicyUrl);
  }, [window.location.search]);

  const dropPolicyTemplateFromUrl = useCallback(() => {
    return URLUtils.setQueryParam(QueryParams.PolicyUrl, null);
  }, []);

  const dropPolicyTutorialFromUrl = useCallback(() => {
    return URLUtils.setQueryParam(QueryParams.PolicyTutorial, null);
  }, []);

  const policyGistId = useMemo(() => {
    return URLUtils.getQueryParam(QueryParams.PolicyGist);
  }, [window.location.search]);

  const dropPolicyGistIdFromUrl = useCallback(() => {
    return URLUtils.setQueryParam(QueryParams.PolicyGist, null);
  }, []);

  const uploadPolicy = useCallback(() => {
    if (!policyTemplateUrl && !policyGistId) return;

    if (policyGistId) {
      uploadPolicyByGistId(policyGistId);
    } else if (policyTemplateUrl) {
      uploadPolicyByUrl(policyTemplateUrl);
    }

    dropPolicyTemplateFromUrl();
    dropPolicyGistIdFromUrl();
  }, [policyTemplateUrl, policyGistId]);

  const uploadPolicyByUrl = useCallback((url: string) => {
    setFetchingYaml(true);
    fetch(url)
      .then(resp => resp.text())
      .then(yaml => {
        track(AnalyticsTrackKind.UploadPolicy, {
          source: 'template',
        });
        loadYaml(yaml);
      })
      .catch(() =>
        notifier.error({ message: "Can't upload policy yaml from url" }).show(),
      )
      .finally(() => setFetchingYaml(false));
  }, []);

  const uploadPolicyByGistId = useCallback((gistId: string) => {
    setFetchingYaml(true);

    const headers: Record<string, string> = {
      Accept: 'application/vnd.github.v3+json',
    };
    if (store.user.githubAccessToken) {
      headers['Authorization'] = `bearer ${store.user.githubAccessToken}`;
    }

    fetch(`https://api.github.com/gists/${gistId}`, { headers })
      .then(resp => {
        if (resp.status === 401 || resp.status === 403) {
          store.user.githubAccessToken = null;
          throw new Error('Unauthorized GitHub access');
        }
        return resp.json();
      })
      .then(json => {
        const firstYamlFile = Object.entries(json.files).find(([key]) => {
          return key.endsWith('.yaml') || key.endsWith('.yml');
        });
        if (!firstYamlFile) {
          return notifier.warning({
            message: "Can't find policy yaml file in GitHub Gist",
            timeout: 5000,
          });
        }
        const filedata = firstYamlFile[1] as { content: string };
        track(AnalyticsTrackKind.UploadPolicy, {
          source: 'gist',
        });
        return loadYaml(filedata.content);
      })
      .catch(() =>
        notifier
          .error({ message: "Can't upload policy yaml from gist" })
          .show(),
      )
      .finally(() => setFetchingYaml(false));
  }, []);

  const uploadPolicyFile = useFileUpload(['.yml', '.yaml'], loadYaml);

  useEffect(() => {
    if (!policyTemplateUrl && !policyGistId) {
      store.policy.restore();
      return;
    }
    uploadPolicy();
  }, []);

  const [appIsReady, setAppIsReady] = useState<boolean>(false);
  useEffect(() => {
    if (store.policy.isReady) {
      const timer = setTimeout(() => setAppIsReady(true));
      return () => clearTimeout(timer);
    }
    return;
  }, [store.policy.isReady]);

  const onSelectUploadPolicy = useCallback((event?: React.SyntheticEvent) => {
    if (store.policy.hasSomeRules) {
      setReplaceWithNewPolicyDialog({ kind: 'upload' });
    } else {
      uploadPolicyFile(event);
    }
  }, []);
  // *** /Upload policy ***

  // *** Upload flows ***
  useEffect(() => {
    store.policy.setOnUploadFlowsError((error: unknown) => {
      notifier
        .error({
          message: error instanceof Error ? error.message : String(error),
          timeout: 5000,
        })
        .show();
    });
  }, []);

  const uploadFlows = useCallback(
    (
      event: React.SyntheticEvent,
      onSuccess?: (flows: Flow[]) => void,
      onError?: (error: unknown) => void,
    ) => {
      event.preventDefault();
      store.policy.uploadFlows(
        ({ aggregatedFlows, originFlowsCnt, rejectedFlowsCnt }) => {
          onSuccess?.(aggregatedFlows);
          track(AnalyticsTrackKind.UploadedFlows, {
            originFlowsCnt,
            rejectedFlowsCnt,
            aggregatedFlowsCnt: aggregatedFlows.length,
          });
        },
        onError,
      );
    },
    [],
  );

  const onCloseFlowsTableSidebar = useCallback(() => {
    store.controls.selectTableFlow(null);
  }, []);
  // *** /Upload flows ***

  // *** Map ***
  const [mapVisibleHeight, setMapVisibleHeight] = useState<number | null>(null);
  const [mapWasDragged, setMapWasDragged] = useState<boolean>(false);

  const onPanelResize = useCallback((rp: DPResizeProps) => {
    const vh = rp.panelTopInPixels;
    setMapVisibleHeight(vh);
  }, []);

  const onMapDrag = useCallback((val: boolean) => setMapWasDragged(val), []);

  const placementStrategy = useMemo(() => {
    return new CimulatorPlacementStrategy(store.policy);
  }, []);

  const arrowStrategy = useMemo(() => {
    return new CimulatorArrowStrategy(placementStrategy, store.policy);
  }, [placementStrategy]);

  const cardRenderer = useCallback(
    (card: PolicyCard) => {
      const coords = placementStrategy.cardsBBoxes.get(card.id);
      if (coords == null) return null;

      const onHeightChange = (h: number) => {
        return placementStrategy.setCardHeight(card.id, h);
      };

      return (
        <CimulatorPolicyCard
          key={card.id}
          card={card}
          selectedCardId={store.controls.selectedCardId}
          selectedEndpointId={store.controls.selectedEndpointId}
          coords={coords}
          defaultDenyIngress={Boolean(store.policy.hasIngressRules)}
          defaultDenyEgress={Boolean(store.policy.hasEgressRules)}
          onHeightChange={onHeightChange}
          onAccessPointCoords={placementStrategy.setAccessPointCoords}
          onToggleDefaultDenyIngress={toggleDefaultDenyIngress}
          onToggleDefaultDenyEgress={toggleDefaultDenyEgress}
        />
      );
    },
    [
      store.controls.selectedCardId,
      store.controls.selectedEndpointId,
      placementStrategy,
      placementStrategy.cardsBBoxes,
      store.policy.cardsList,
      store.policy.hasIngressRules,
      store.policy.hasEgressRules,
      toggleDefaultDenyIngress,
      toggleDefaultDenyEgress,
    ],
  );
  // *** /Map ***

  return (
    <div className={css.cimulator}>
      <TopBar />
      <div className={css.map}>
        <MapComponent
          placement={placementStrategy}
          cards={store.policy.visibleCardsList ?? []}
          cardRenderer={cardRenderer}
          arrows={arrowStrategy}
          visibleHeight={mapVisibleHeight ?? 0}
          wasDragged={mapWasDragged}
          onCardHeightChange={placementStrategy.setCardHeight}
          onMapDrag={onMapDrag}
        />
      </div>

      <PoliciesPanel
        flows={store.policy.aggregatedFlows}
        currentNamespace={store.controls.policyNamespace}
        policyYaml={policyYaml}
        onDownloadYaml={initDownloadPolicy}
        onShareYaml={initShareYaml}
        selectedCardId={store.controls.selectedCardId}
        selectedEndpointId={store.controls.selectedEndpointId}
        onUploadFlows={uploadFlows}
        onPanelResize={onPanelResize}
        onPolicyYamlChange={loadYaml}
        selectedFlow={store.controls.selectedTableFlow}
        onSelectFlow={store.controls.selectTableFlow}
        onCloseFlowsTableSidebar={onCloseFlowsTableSidebar}
        onUploadPolicy={onSelectUploadPolicy}
        onCreateNewPolicy={onCreateNewPolicy}
      />

      {(!appIsReady || fetchingYaml || store.policy.uploadingFlows) && (
        <LoadingOverlay
          transparent={appIsReady}
          text={
            !appIsReady
              ? 'Loading Network Policy Editor…'
              : fetchingYaml
              ? 'Fetching policy yaml from url…'
              : store.policy.uploadingFlows
              ? 'Processing flows…'
              : ''
          }
        />
      )}

      <div ref={alertsPortal} className={css.alertsPortal} id="alerts-portal" />

      {alertsPortal.current && ingressEgressWarningAlert && (
        <RemoveIngressEgressDialog
          dir={ingressEgressWarningAlert}
          isOpen={Boolean(ingressEgressWarningAlert)}
          onCancel={hideIngressEgressWarningAlert}
          onConfirm={disableDefaultDeny}
          containerRef={alertsPortal.current}
        />
      )}

      {alertsPortal.current && (
        <ReplaceWithNewPolicyDialog
          isOpen={Boolean(replaceWithNewPolicyDialog?.kind)}
          text={
            replaceWithNewPolicyDialog?.kind === 'new' ? (
              <p>
                Unsaved network policy will be replaced with new empty policy.{' '}
                <a href="#" onClick={onDownloadPolicy}>
                  Download
                </a>{' '}
                current policy to save it.
              </p>
            ) : replaceWithNewPolicyDialog?.kind === 'upload' ? (
              <p>
                The editor will load policy from external URL. Unsaved network
                policy will be replaced with other policy.{' '}
                <a href="#" onClick={onDownloadPolicy}>
                  Download
                </a>{' '}
                current policy to save it.
              </p>
            ) : null
          }
          buttonText={
            replaceWithNewPolicyDialog?.kind === 'new'
              ? 'Create new empty policy'
              : replaceWithNewPolicyDialog?.kind === 'upload'
              ? 'Upload other policy'
              : ''
          }
          onCancel={onCancelReplaceWithNewPolicyDialog}
          onConfirm={onConfirmReplaceWithNewPolicyDialog}
          containerRef={alertsPortal.current}
        />
      )}

      {alertsPortal.current && (
        <OnboardingDialog
          isOpen={isOnboardingDialogOpen}
          onClose={onOnboardingDialogClose}
          containerRef={alertsPortal.current}
        />
      )}

      {alertsPortal.current && (
        <DownloadDialog
          isOpen={isDownloadDialogOpen}
          onClose={onDownloadDialogClose}
          containerRef={alertsPortal.current}
        />
      )}

      {alertsPortal.current && (
        <ShareDialog
          isOpen={isShareDialogOpen}
          onClose={onShareDialogClose}
          shareGist={shareGist}
          containerRef={alertsPortal.current}
        />
      )}
    </div>
  );
});
