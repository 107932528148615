import { Address4, Address6 } from 'ip-address';
import isCidr from 'is-cidr';
import { PolicyCard } from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import {
  EndpointMatchExpression,
  EndpointRequirementOperator,
  PolicyKind,
} from '~/domain/cimulator/types';
import {
  FQDNMatchPatternRegexStringRegexp,
  RuleData,
  RuleKind,
} from './general';

export function validateRuleData(
  policyKind: PolicyKind,
  card: PolicyCard,
  rule: RuleData,
): Error | null {
  const checkMatchExpression = (expr: EndpointMatchExpression) => {
    if (expr.key.trim().length === 0) {
      return new Error('key is required in match expression');
    }
    if (
      (expr.operator === EndpointRequirementOperator.In ||
        expr.operator === EndpointRequirementOperator.NotIn) &&
      expr.values?.length === 0
    ) {
      return new Error('values is required in In/NotIn match expression');
    }
    return null;
  };
  switch (rule.kind) {
    case RuleKind.NamespaceSelector: {
      if (
        !rule.namespaceSelector.matchLabels?.length &&
        !rule.namespaceSelector.matchExpressions?.length
      ) {
        return new Error(`Namespace selector is required`);
      }
      if (rule.namespaceSelector.matchExpressions?.length) {
        const expr = rule.namespaceSelector.matchExpressions.find(
          checkMatchExpression,
        );
        if (expr) return checkMatchExpression(expr);
      }
      break;
    }
    case RuleKind.PodSelector: {
      if (
        card.isInCluster &&
        !rule.namespaceSelector.matchLabels?.length &&
        !rule.namespaceSelector.matchExpressions?.length &&
        !rule.namespaceSelector.selectAll
      ) {
        return new Error(`Namespace selector is required`);
      }
      if (card.isInCluster && rule.namespaceSelector.matchExpressions?.length) {
        const expr = rule.namespaceSelector.matchExpressions.find(
          checkMatchExpression,
        );
        if (expr) return checkMatchExpression(expr);
      }
      if (
        !rule.podSelector.matchLabels?.length &&
        !rule.podSelector.matchExpressions?.length
      ) {
        return new Error(`Pod selector is required`);
      }
      if (rule.podSelector.matchExpressions?.length) {
        const expr = rule.podSelector.matchExpressions.find(
          checkMatchExpression,
        );
        if (expr) return checkMatchExpression(expr);
      }
      break;
    }
    case RuleKind.Service: {
      if (card.isInCluster) {
        if (
          !rule.namespaceSelector.matchLabels?.length &&
          !rule.namespaceSelector.selectAll
        ) {
          return new Error(`Namespace is required`);
        } else if (
          rule.namespaceSelector.matchLabels?.length !== 1 ||
          rule.namespaceSelector.matchLabels?.[0]?.includes('=')
        ) {
          return new Error(`Namespace must be plain string`);
        }
      }
      if (
        !rule.podSelector.matchLabels?.length &&
        !rule.podSelector.matchExpressions?.length
      ) {
        return new Error(`Service name or label is required`);
      }
      if (rule.podSelector.matchExpressions?.length) {
        const expr = rule.podSelector.matchExpressions.find(
          checkMatchExpression,
        );
        if (expr) return checkMatchExpression(expr);
      }
      break;
    }
    case RuleKind.Fqdn: {
      if (!rule.fqdn || !FQDNMatchPatternRegexStringRegexp.test(rule.fqdn)) {
        return new Error(
          `Invalid FQDN. Only 0-9, a-z, A-Z and ., - and * chars are allowed`,
        );
      }
      break;
    }
    case RuleKind.Cidr: {
      if (
        !isCidr(rule.cidr) ||
        (!Address4.isValid(rule.cidr) && !Address6.isValid(rule.cidr))
      ) {
        return new Error(`Invalid CIDR`);
      }
      const foundInvalidExceptCidr = rule.exceptCidrs
        .filter(s => s.length)
        .some(exceptCidr => {
          return (
            !isCidr(exceptCidr) ||
            (!Address4.isValid(exceptCidr) && !Address6.isValid(exceptCidr))
          );
        });
      if (foundInvalidExceptCidr) {
        return new Error(`Invalid Except CIDR`);
      }
      break;
    }
  }

  if (rule.ports.length > 0 && !PolicyEndpoint.isValidPortsArray(rule.ports)) {
    return new Error('Invalid port');
  }

  return null;
}
