import { AnchorButton } from '@blueprintjs/core';
import { Popover2, Classes } from '@blueprintjs/popover2';
import React from 'react';

import css from './TopBar.scss';

export function TopBar() {
  return (
    <div className={css.wrapper}>
      <Popover2
        content={<PopoverContent />}
        interactionKind="hover"
        popoverClassName={`${Classes.POPOVER2_CONTENT_SIZING} ${css.isovalentPopup}`}
        position="bottom"
        usePortal={true}
      >
        <div className={css.inner}>
          <img
            src="/static/assets/images/networkpolicy-logo.svg"
            alt=""
            className={css.networkpolicylogo}
          />{' '}
          by{' '}
          <span className={css.isovalentText}>Isovalent</span>
        </div>
      </Popover2>
    </div>
  );
}

function PopoverContent() {
  return (
    <div className={css.popoverContent}>
      <div className={css.section}>
        <img
          src="/static/assets/images/isovalent-logo-wordmark.svg"
          alt=""
          className={`${css.icon} ${css.isovalentWordmark}`}
        />
        <div className={css.text}>
          Isovalent is the creator of Cilium — eBPF-based networking, security
          and observability
        </div>
        <div className={css.footer}>
          <span>Learn more on</span>
          <a
            href="https://isovalent.com"
            target="_blank"
            rel="noopener noreferrer"
            className={css.isovalentLink}
          >
            isovalent.com
          </a>
        </div>
      </div>
      <div className={css.section}>
        <img
          src="/static/assets/images/buildings-gold-icon.svg"
          alt=""
          className={`${css.icon} ${css.buildingsIcon}`}
        />
        <div className={css.text}>
          This is the free SaaS version of the Network Policy Editor.
          On-prem support is available in the enterprise version
        </div>
        <div className={css.footer}>
          <AnchorButton
            intent="primary"
            href="https://isovalent.com/request-demo/"
            target="_blank"
            rel="noopener noreferrer"
            className={css.demoButton}
          >
            Request a demo
          </AnchorButton>
        </div>
      </div>
    </div>
  );
}
