import { Button, ButtonGroup } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { PolicyAssistant } from '~/components/PolicyAssistant';
import { TutorialSelector } from '~/components/PolicyAssistant/TutorialSelector';
import { ResizablePanel, ResizeProps } from '~/components/ResizablePanel';
import { Flow } from '~/domain/flows';
import {
  AssistantEmitterActions,
  emitter,
} from '~/store/stores/assistant/emitter';
import { AnalyticsTrackKind, track } from '~/utils/analytics';
import { HubblePanel, PanelKind } from './HubblePanel';
import css from './styles.scss';
import { YamlPanel } from './YamlPanel';

export interface Props {
  currentNamespace: string | null;
  policyYaml: string;
  flows: Flow[];
  selectedFlow?: Flow | null;
  selectedCardId?: string | null;
  selectedEndpointId?: string | null;
  onDownloadYaml?: () => void;
  onShareYaml?: () => void;
  onPanelResize?: (resizeProps: ResizeProps) => void;
  onPolicyYamlChange?: (yaml: string) => void;
  onSelectFlow?: (flow: Flow | null) => void;
  onCloseFlowsTableSidebar?: () => void;
  onUploadPolicy?: (event: React.SyntheticEvent) => void;
  onCreateNewPolicy?: () => void;
  onUploadFlows?: (
    event: React.SyntheticEvent,
    onSuccess?: (flows: Flow[]) => void,
    onError?: (error: unknown) => void,
  ) => void;
}

enum RightPanelKind {
  Hubble = 'hubble',
  Tutorial = 'tutorial',
}

export const PoliciesPanel = observer(function PoliciesPanel(props: Props) {
  const [rightPanelKind, setRightPanelKind] = useState<RightPanelKind>(
    RightPanelKind.Tutorial,
  );

  const [hubblePanelKind, setHubblePanelKind] = useState<PanelKind>(
    PanelKind.Promo,
  );

  useEffect(() => {
    return emitter.on(AssistantEmitterActions.OpenHubblePanel, () => {
      setRightPanelKind(RightPanelKind.Hubble);
    });
  }, [emitter]);

  const onHubblePanelSetKind = useCallback((kind: PanelKind) => {
    setHubblePanelKind(kind);
  }, []);

  const onSelectTutorialPanel = useCallback(() => {
    setRightPanelKind(RightPanelKind.Tutorial);
  }, []);

  const onSelectHubblePanel = useCallback(() => {
    setRightPanelKind(RightPanelKind.Hubble);
    track(AnalyticsTrackKind.ShowUploadFlowsPanel, {
      source: 'right-panel-tab',
    });
  }, []);

  const content = (
    <div className={css.wrapper}>
      <div className={css.centerPanel}>
        <YamlPanel
          policyYaml={props.policyYaml}
          selectedCardId={props.selectedCardId}
          selectedEndpointId={props.selectedEndpointId}
          onDownloadYaml={props.onDownloadYaml}
          onShareYaml={props.onShareYaml}
          onUploadPolicy={props.onUploadPolicy}
          onCreateNewPolicy={props.onCreateNewPolicy}
        />
      </div>
      <div className={css.rightPanel}>
        <div className={css.rightPanelTabsWrapper}>
          <ButtonGroup className={css.rightPanelTabs}>
            <TutorialSelector
              onChange={onSelectTutorialPanel}
              active={rightPanelKind === RightPanelKind.Tutorial}
            />
            <Button
              onClick={onSelectHubblePanel}
              active={rightPanelKind === RightPanelKind.Hubble}
            >
              Flows upload
            </Button>
          </ButtonGroup>
        </div>
        <div className={css.rightPanelContent}>
          {rightPanelKind === RightPanelKind.Tutorial && <PolicyAssistant />}
          {rightPanelKind === RightPanelKind.Hubble && (
            <HubblePanel
              flows={props.flows}
              kind={hubblePanelKind}
              selectedFlow={props.selectedFlow}
              onSelectFlow={props.onSelectFlow}
              onUploadFlows={props.onUploadFlows}
              onCloseFlowsTableSidebar={props.onCloseFlowsTableSidebar}
              onSetPanelKind={onHubblePanelSetKind}
            />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <ResizablePanel onPanelResize={props.onPanelResize}>
      {{ content }}
    </ResizablePanel>
  );
});

export { ResizeProps };
