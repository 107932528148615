import AJV from 'ajv';
import addAJVFormats from 'ajv-formats';
import { debounce } from 'lodash';
import { autorun, makeAutoObservable, reaction, runInAction } from 'mobx';
import PouchDB from 'pouchdb';
import * as YAML from 'yaml';
import { schema as cnpSchema } from '~/domain/cilium/cnp/schema';
import {
  EgressRule,
  EndpointSelector,
  IngressRule,
  Rule,
} from '~/domain/cilium/cnp/types';
import {
  CiliumNetworkPolicyBuilder,
  KubernetesNetworkPolicyBuilder,
} from '~/domain/cimulator';
import { PolicyCard } from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import {
  CardKind,
  CardSide,
  EndpointKind,
  PolicyKind,
} from '~/domain/cimulator/types';
import { Flow } from '~/domain/flows';
import {
  createFileReader,
  createFileUploader,
  downloadTextAsFile,
} from '~/domain/helpers/files';
import { FlowType, TrafficDirection } from '~/domain/hubble';
import { schema as knpSchema } from '~/domain/k8s/knp/schema';
import { NetworkPolicySpec } from '~/domain/k8s/knp/types';
import { KV, Labels } from '~/domain/labels';
import { YAML_POLICY_PARSE_REVIEWER } from '~/domain/misc';
import * as storage from '~/storage/local';
import ControlStore from '~/store/stores/controls';
import { AnalyticsTrackKind, track } from '~/utils/analytics';
import { memoize } from '~/utils/memoize';
import FlowsStore from '../flows';
import { SpecStore } from './policy-spec';
import {
  AnyCardsStore,
  ParsePolicyYAMLResult,
  PolicyInfoSnapshot,
  RuleStatusInfo,
} from './types';

interface PolicyStruct {
  id: string;
  isSingleSpec: boolean;
  specs: SpecStore[];
  currentSpecIdx: number;
}

export class PolicyStore {
  private _flows: FlowsStore;
  private _controls: ControlStore;

  private _db: PouchDB.Database;
  private _policies: Map<string, PolicyStruct>;

  // private _isSingleSpec = false;
  // private _specs: SpecStore[];
  // private _currentSpecIdx: number;

  private _isFlowsUploading = false;
  private _onUploadFlowsError?: (err: unknown) => void;
  private _ignoreFlowLabels = storage.getIgnoreFlowLabels(
    new Set([
      'io.cilium.k8s.policy.cluster',
      'io.cilium.k8s.policy.serviceaccount',
    ]),
  );

  static aggregateFlows = (flows: Flow[]) => {
    const map = new Map<string, Flow>();
    flows.forEach(flow => {
      if (!map.has(flow.policyAggregationHash)) {
        map.set(flow.policyAggregationHash, flow);
      }
    });
    return Array.from(map.values());
  };

  static parseYaml = (yaml: string): ParsePolicyYAMLResult => {
    try {
      const doc = YAML.parseDocument(yaml);
      const rawKind = doc.getIn(['kind']) as string;

      if (!rawKind) {
        return { ok: false, errors: ['Invalid policy YAML'] };
      }

      const policyKind =
        rawKind === 'NetworkPolicy'
          ? PolicyKind.KNP
          : rawKind.startsWith('Cilium') && rawKind.endsWith('NetworkPolicy')
          ? PolicyKind.CNP
          : null;

      if (!policyKind) {
        return { ok: false, errors: ['Invalid policy kind'] };
      }

      const { validate } =
        policyKind === PolicyKind.CNP
          ? PolicyStore.getCNPValidator()
          : PolicyStore.getKNPValidator();

      const valid = validate(doc.toJS({ reviver: YAML_POLICY_PARSE_REVIEWER }));
      if (!valid) {
        if (!validate.errors?.length) {
          return { ok: false, errors: ['Invalid policy'] };
        }
        const errors = validate.errors.map(error => {
          return `${error.instancePath}: ${error.message ?? 'invalid'}`;
        });
        return { ok: false, errors };
      }

      const policy =
        policyKind === PolicyKind.KNP
          ? KubernetesNetworkPolicyBuilder.parsePolicy(doc)
          : CiliumNetworkPolicyBuilder.parsePolicy(doc);

      if (policyKind === PolicyKind.CNP) {
        const isClusterwide = rawKind === 'CiliumClusterwideNetworkPolicy';
        return { ok: true, policyKind, isClusterwide, ...policy };
      } else {
        return { ok: true, policyKind, ...policy };
      }
    } catch (error) {
      return { ok: false, errors: [String(error)] };
    }
  };

  constructor(controls: ControlStore, flows: FlowsStore) {
    makeAutoObservable(this, {
      getCardBy: false,
      isVisibleCard: false,
      getRuleStatusInfo: false,
      flowToCards: false,
      flowsToCardEndpoints: false,
    });

    this._db = new PouchDB('policies', { auto_compaction: true });
    this._controls = controls;
    this._flows = flows;

    this._policies = new Map();

    this.setupPersistance();
    this.setupReactions();
  }

  /* PUBLIC GETTERS */
  get isReady(): boolean {
    return Boolean(this._policies?.size);
  }

  get policyName(): string | null {
    return this._controls.policyName;
  }

  get policyNamespace(): string | null {
    return this._controls.policyNamespace;
  }

  get policyKind() {
    if (!this.currentSpec) return null;
    return this.currentSpec.policyKind;
  }

  get currentSpecsCount() {
    return this.currentSpecs?.length ?? null;
  }

  get isCNP() {
    return this.policyKind === PolicyKind.CNP;
  }

  get isKNP() {
    return this.policyKind === PolicyKind.KNP;
  }

  get currentPolicy() {
    if (!this._controls.policyId) return null;
    return this._policies.get(this._controls.policyId) ?? null;
  }

  get currentSpecs() {
    return this.currentPolicy?.specs ?? null;
  }

  get currentSpec() {
    if (!this.currentPolicy || !this.currentSpecs) return null;
    return this.currentSpecs[this.currentPolicy.currentSpecIdx];
  }

  get curPolicyKindBuilder() {
    return this.isCNP
      ? CiliumNetworkPolicyBuilder
      : KubernetesNetworkPolicyBuilder;
  }

  get policyCnpSpec() {
    if (!this.currentSpec) return null;
    return PolicyStore.genPolicySpec(
      this.policyNamespace,
      this.currentSpec,
      CiliumNetworkPolicyBuilder,
    );
  }

  get policyKnpSpec() {
    if (!this.currentSpec) return null;
    return PolicyStore.genPolicySpec(
      this.policyNamespace,
      this.currentSpec,
      KubernetesNetworkPolicyBuilder,
    );
  }

  get policyCnpSpecs() {
    if (!this.currentSpecs) return null;
    return this.currentSpecs.map(spec => ({
      spec: PolicyStore.genPolicySpec(
        this.policyNamespace,
        spec,
        CiliumNetworkPolicyBuilder,
      ),
      unspprtdEgress:
        spec.originPolicyKind === PolicyKind.CNP ? spec.unspprtdEgress : [],
      unspprtdIngress:
        spec.originPolicyKind === PolicyKind.CNP ? spec.unspprtdIngress : [],
    }));
  }

  get policyKnpSpecs() {
    if (!this.currentSpecs) return null;
    return this.currentSpecs.map(spec => ({
      spec: PolicyStore.genPolicySpec(
        this.policyNamespace,
        spec,
        KubernetesNetworkPolicyBuilder,
      ),
      unspprtdEgress:
        spec.originPolicyKind === PolicyKind.CNP ? spec.unspprtdEgress : [],
      unspprtdIngress:
        spec.originPolicyKind === PolicyKind.CNP ? spec.unspprtdIngress : [],
    }));
  }

  get policyCurrentSpecYaml(): string | null {
    if (!this.currentSpec) return null;

    let yaml: string | null;
    if (this.isCNP) {
      yaml = CiliumNetworkPolicyBuilder.generateYaml(
        this.policyName,
        this.policyNamespace,
        [
          {
            spec: this.policyCnpSpec as Rule,
            unspprtdEgress:
              this.currentSpec.originPolicyKind === PolicyKind.CNP
                ? this.currentSpec.unspprtdEgress
                : [],
            unspprtdIngress:
              this.currentSpec.originPolicyKind === PolicyKind.CNP
                ? this.currentSpec.unspprtdIngress
                : [],
          },
        ],
        true,
      );
    } else {
      yaml = KubernetesNetworkPolicyBuilder.generateYaml(
        this.policyName,
        this.policyNamespace,
        [
          {
            spec: this.policyKnpSpec as NetworkPolicySpec,
            unspprtdEgress:
              this.currentSpec.originPolicyKind === PolicyKind.KNP
                ? this.currentSpec.unspprtdEgress
                : [],
            unspprtdIngress:
              this.currentSpec.originPolicyKind === PolicyKind.KNP
                ? this.currentSpec.unspprtdIngress
                : [],
          },
        ],
        true,
      );
    }

    return yaml;
  }

  get policySpecsYaml(): string | null {
    return this.isCNP ? this.policyCnpSpecsYaml : this.policyKnpSpecsYaml;
  }

  get policyCnpSpecsYaml(): string | null {
    if (!this.currentPolicy) return null;

    return CiliumNetworkPolicyBuilder.generateYaml(
      this.policyName,
      this.policyNamespace,
      this.policyCnpSpecs as Array<{
        spec: Rule;
        unspprtdEgress: EgressRule[];
        unspprtdIngress: IngressRule[];
      }>,
      this.currentPolicy.isSingleSpec,
    );
  }

  get policyKnpSpecsYaml(): string | null {
    if (!this.currentSpec || !this.currentPolicy) return null;

    return KubernetesNetworkPolicyBuilder.generateYaml(
      this.policyName,
      this.policyNamespace,
      [
        {
          spec: this.policyKnpSpec as NetworkPolicySpec,
          unspprtdEgress:
            this.currentSpec.originPolicyKind === PolicyKind.KNP
              ? this.currentSpec.unspprtdEgress
              : [],
          unspprtdIngress:
            this.currentSpec.originPolicyKind === PolicyKind.KNP
              ? this.currentSpec.unspprtdIngress
              : [],
        },
      ],
      this.currentPolicy.isSingleSpec,
    );
  }

  get ignoreFlowLabelsList() {
    return Array.from(this._ignoreFlowLabels);
  }

  get uploadingFlows() {
    return this._isFlowsUploading;
  }

  get aggregatedFlows() {
    const ignore = Array.from(this._ignoreFlowLabels);
    const filterLabel = (label: KV) => {
      return ignore.every(key => !label.key.includes(key));
    };

    const flows = this._flows.list.map(flow => {
      const newRef = { ...flow.ref };
      if (newRef.source) {
        newRef.source.labelsList = flow.sourceLabels
          .filter(filterLabel)
          .map(label => Labels.concatKV(label));
      }
      if (newRef.destination) {
        newRef.destination.labelsList = flow.destinationLabels
          .filter(filterLabel)
          .map(label => Labels.concatKV(label));
      }
      return new Flow(newRef);
    });

    return PolicyStore.aggregateFlows(flows);
  }

  get ratingStates() {
    if (!this.currentSpec) return null;
    return this.currentSpec.ratingStates;
  }

  get actualPoints() {
    if (!this.currentSpec) return null;
    return this.currentSpec.actualPoints;
  }

  get policyAvgRating() {
    if (!this.currentSpec) return null;
    return this.currentSpec.policyAvgRating;
  }

  get roundedRating() {
    if (!this.currentSpec) return null;
    return this.currentSpec.roundedRating;
  }

  /* PROXY TO SPEC */
  get specPodSelector() {
    if (!this.currentSpec) return null;
    return this.currentSpec.specPodSelector;
  }

  get defaultDenyIngress() {
    if (!this.currentSpec) return null;
    return this.currentSpec.defaultDenyIngress;
  }

  get defaultDenyEgress() {
    if (!this.currentSpec) return null;
    return this.currentSpec.defaultDenyEgress;
  }

  get hasUnsupportedOriginRules() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasUnsupportedOriginRules;
  }

  get hasUnsupportedEndpoints() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasUnsupportedEndpoints;
  }

  get hasUnsupportedRules() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasUnsupportedRules;
  }

  get hasIngressRules() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasIngressRules;
  }

  get hasEgressCidrOutsideCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasEgressCidrOutsideCluster;
  }

  get hasEgressFqdnOutsideCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasEgressFqdnOutsideCluster;
  }

  get selectorCard() {
    if (!this.currentSpec) return null;
    return this.currentSpec.selectorCard;
  }

  get egressOutsideClusterCard() {
    if (!this.currentSpec) return null;
    return this.currentSpec.egressOutsideClusterCard;
  }

  get egressInNamespaceCard() {
    if (!this.currentSpec) return null;
    return this.currentSpec.egressInNamespaceCard;
  }

  get egressInClusterCard() {
    if (!this.currentSpec) return null;
    return this.currentSpec.egressInClusterCard;
  }

  get ingressOutsideClusterCard() {
    if (!this.currentSpec) return null;
    return this.currentSpec.ingressOutsideClusterCard;
  }

  get ingressInNamespaceCard() {
    if (!this.currentSpec) return null;
    return this.currentSpec.ingressInNamespaceCard;
  }

  get ingressInClusterCard() {
    if (!this.currentSpec) return null;
    return this.currentSpec.ingressInClusterCard;
  }

  get allEndpointsList() {
    return this.currentSpec?.allEndpointsList ?? null;
  }

  get hasSomeRules() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeRules;
  }

  get hasSomeIngress() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeIngress;
  }

  get hasSomeEgress() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeEgress;
  }

  get hasFullEgressOutsideCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasFullEgressOutsideCluster;
  }

  get hasEgressOutsideClusterToSpecificPorts() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasEgressOutsideClusterToSpecificPorts;
  }

  get hasFullEgressInCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasFullEgressInCluster;
  }

  get hasFullEgressInNamespace() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasFullEgressInNamespace;
  }

  get hasSomeEgressInNamespace() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeEgressInNamespace;
  }

  get hasSomeEgressSelectorsInNamespace() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeEgressSelectorsInNamespace;
  }

  get hasSomeEgressInCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeEgressInCluster;
  }

  get hasSomeEgressSelectorsInCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeEgressSelectorsInCluster;
  }

  get hasIngressFromOutside() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasIngressFromOutside;
  }

  get hasIngressFromOutsideToSpecificPorts() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasIngressFromOutsideToSpecificPorts;
  }

  get hasFullIngressInCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasFullIngressInCluster;
  }

  get hasFullIngressInNamespace() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasFullIngressInNamespace;
  }

  get hasSomeIngressInNamespace() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeIngressInNamespace;
  }

  get hasSomeIngressSelectorsInNamespace() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeIngressSelectorsInNamespace;
  }

  get hasSomeIngressInCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeIngressInCluster;
  }

  get hasSomeIngressSelectorsInCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeIngressSelectorsInCluster;
  }

  get hasEgressRules() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasEgressRules;
  }

  get isKubeDnsAllowed(): boolean {
    if (!this.currentSpec) return false;
    return this.currentSpec.isKubeDnsAllowed;
  }

  get kubeDnsEndpoint(): PolicyEndpoint | null {
    if (!this.currentSpec) return null;
    return this.currentSpec.kubeDnsEndpoint;
  }

  get isDNSProxyEnabled(): boolean {
    if (!this.currentSpec) return false;
    return this.currentSpec.isDNSProxyEnabled;
  }

  get cardsMap() {
    if (!this.currentSpec) return null;
    return this.currentSpec.cardsMap;
  }

  get cardsList() {
    if (!this.currentSpec) return null;
    return this.currentSpec.cardsList;
  }

  get visibleCardsList() {
    if (!this.currentSpec) return null;
    return this.currentSpec.visibleCardsList;
  }

  get allowedEndpointsSet(): Set<string> | null {
    if (!this.currentSpec) return null;
    return this.currentSpec.allowedEndpointsSet;
  }

  /* PUBLIC ACTIONS */
  goToNextSpec = () => {
    if (!this.currentPolicy || !this.currentSpecs) return;
    let nextIdx = this.currentPolicy.currentSpecIdx + 1;
    if (nextIdx >= this.currentSpecs.length) nextIdx = 0;
    this.currentPolicy.currentSpecIdx = nextIdx;
    this._controls.selectElement(undefined, undefined);
  };

  goToPrevSpec = () => {
    if (!this.currentPolicy || !this.currentSpecs) return;
    let prevIdx = this.currentPolicy.currentSpecIdx - 1;
    if (prevIdx < 0) prevIdx = this.currentSpecs.length - 1;
    this.currentPolicy.currentSpecIdx = prevIdx;
    this._controls.selectElement(undefined, undefined);
  };

  uploadFlows = (
    onSuccess?: (data: {
      originFlowsCnt: number;
      rejectedFlowsCnt: number;
      aggregatedFlows: Flow[];
    }) => void,
    onError?: (error: unknown) => void,
  ) => {
    const disposer = { current: null } as { current: null | (() => void) };

    const reader = createFileReader((text: string) => {
      runInAction(() => {
        this._isFlowsUploading = true;

        setTimeout(() => {
          runInAction(() => {
            try {
              const parts = text.split(/}\r?\n{/);
              const flows: Flow[] = [];
              let originFlowsCnt = 0;
              let rejectedFlowsCnt = 0;
              parts.forEach((part, i) => {
                if (i < parts.length - 1) part += '}';
                if (i > 0) part = '{' + part;

                if (!part.trim()) return;
                const flow = Flow.fromHubbleObserveJsonString(part);
                originFlowsCnt++;
                if (this.flowToCards(flow).length > 0) {
                  flows.push(flow);
                } else {
                  rejectedFlowsCnt++;
                }
              });
              const aggregatedFlows = PolicyStore.aggregateFlows(flows);
              this._flows.add(aggregatedFlows);
              onSuccess?.({
                originFlowsCnt,
                rejectedFlowsCnt,
                aggregatedFlows,
              });
            } catch (err) {
              console.error(err);
              this._onUploadFlowsError?.(err);
              onError?.(err);
            } finally {
              this._isFlowsUploading = false;
              disposer.current?.();
            }
          });
        }, 100);
      });
    });

    const uploader = createFileUploader(reader.reader, ['.json']);

    disposer.current = () => {
      reader.dispose();
      uploader.dispose();
    };

    uploader.open();
  };

  setOnUploadFlowsError = (callback: (err: unknown) => void) => {
    this._onUploadFlowsError = callback;
  };

  setIgnoreFlowLabels = (values: string[]) => {
    this._ignoreFlowLabels = new Set(values);
    storage.saveIgnoreFlowLabels(this._ignoreFlowLabels);
  };

  addToIgnoreFlowLabels = (val: string) => {
    if (!this._ignoreFlowLabels.has(val)) {
      this._ignoreFlowLabels.add(val);
      storage.saveIgnoreFlowLabels(this._ignoreFlowLabels);
    }
  };

  deleteFromIgnoreFlowLabels = (val: string) => {
    this._ignoreFlowLabels.delete(val);
    storage.saveIgnoreFlowLabels(this._ignoreFlowLabels);
  };

  restore = () => {
    console.log('policy: restoring');
    const loadFromPouch = (snapshotId: string) => {
      console.log('policy: loading from indexeddb');
      return this._db.get<PolicyInfoSnapshot>(snapshotId);
    };

    const loadFromLocalStorage = () => {
      console.log('policy: loading from local storage');
      const policyInfo = storage.getPolicyInfo();
      if (policyInfo && !policyInfo._id) {
        policyInfo._id = ControlStore.policyIdGenerator();
      }
      return policyInfo ?? void 0;
    };

    const load = async (): Promise<PolicyInfoSnapshot | null | undefined> => {
      if (!this._controls.policyId) {
        console.log('policy: create empty policy with new id');
        return PolicyStore.createInitialPolicyInfoSnapshot();
      }
      return loadFromPouch(this._controls.policyId).catch(loadFromLocalStorage);
    };

    return load()
      .then(result => {
        result = result || PolicyStore.createInitialPolicyInfoSnapshot();
        this.loadYamlAsNewPolicy(result);
        this._controls.setPolicyId(result._id);
        return result;
      })
      .catch(error => console.error(error));
  };

  loadYamlAsNewPolicy = (
    snapshot: Partial<PolicyInfoSnapshot>,
  ): { ok: true } | { ok: false; errors: string[] } => {
    if (!snapshot.policyYaml) {
      return { ok: false, errors: ['Invalid policy YAML'] };
    }

    const parseInfo = PolicyStore.parseYaml(snapshot.policyYaml);

    if (!parseInfo) return { ok: false, errors: ['Invalid policy YAML'] };
    if (!parseInfo.ok) return { ok: false, errors: parseInfo.errors };

    this._controls.setPolicyKind(
      snapshot.lastPolicyKind ?? parseInfo.policyKind,
    );
    this._controls.setPolicyName(parseInfo.policyName || 'untitled-policy');
    this._controls.setPolicyNamespace(parseInfo.policyNamespace);

    const newSpecs = parseInfo.results.map((result, specIdx) => {
      const spec = new SpecStore({
        controls: this._controls,
        originPolicyKind: snapshot.originPolicyKind ?? parseInfo.policyKind,
        defaultDenyEgress:
          snapshot.defaultDenyEgresses?.[specIdx] ?? result.defaultDenyEgress,
        defaultDenyIngress:
          snapshot.defaultDenyIngresses?.[specIdx] ?? result.defaultDenyIngress,
        unspprtdEgress:
          snapshot.unspprtdEgresses?.[specIdx] ?? result.unspprtdEgress,
        unspprtdIngress:
          snapshot.unspprtdIngresses?.[specIdx] ?? result.unspprtdIngress,
        cards: PolicyStore.createInitialCards(),
        autoStartCardReactions: false,
      });

      result.cards.forEach(card => {
        if (card.isIngress || card.isEgress) {
          spec.setCard(card);
          card.endpointsMap.forEach(endpoint => {
            spec.setAllowedEndpoint(card.fullEndpointId(endpoint.id), true);
          });
          if (!card.firstEndpoint?.isAllWithoutPorts) {
            card.addEndpoints(PolicyEndpoint.newAll());
          }
        } else if (card.isSelector && card.podSelector) {
          spec.selectorCard.setPodSelector(card.podSelector);
        }
      });

      if (!spec.kubeDnsEndpoint) {
        const kubedns = PolicyEndpoint.newKubeDns().enableDNSProxy();
        spec.egressInClusterCard.addEndpoints(kubedns);
      }

      return spec.startCardsReactions();
    });

    const newPolicy = {
      id: snapshot._id ?? ControlStore.policyIdGenerator(),
      isSingleSpec: parseInfo.isSingleSpec,
      specs: newSpecs,
      currentSpecIdx: 0,
    };

    this._policies.set(newPolicy.id, newPolicy);
    this._controls.setPolicyId(newPolicy.id);

    // only for uploads, not restorations
    if (!snapshot.originPolicyKind) {
      track(AnalyticsTrackKind.UploadedPolicy, {
        policyKind: parseInfo.policyKind,
        multiSpec: parseInfo.results.length > 1,
        rulesCnt: newSpecs.reduce(
          (cnt, spec) => cnt + spec.allowedEndpointsSet.size,
          0,
        ),
        unsupportedRulesCnt: newSpecs.reduce(
          (cnt, spec) =>
            cnt + spec.unspprtdEgress.length + spec.unspprtdIngress.length,
          0,
        ),
        specsStats: newSpecs.map(spec => spec.ratingStates),
      });
    }

    return { ok: true };
  };

  downloadYaml = () => {
    if (!this.policyKind || !this.policySpecsYaml || !this.currentSpecs) return;
    track(AnalyticsTrackKind.DownloadPolicyYaml, {
      policyKind: this.policyKind,
      multiSpec: this.currentSpecs.length > 0,
      rulesCnt: this.currentSpecs.reduce(
        (cnt, spec) => cnt + spec.allowedEndpointsSet.size,
        0,
      ),
      specsStats: this.currentSpecs.map(spec => spec.ratingStates),
    });
    const filename = (this.policyName ?? 'policy') + '.yaml';
    const base64 = btoa(this.policySpecsYaml);
    downloadTextAsFile(filename, `base64,${base64}`, 'text/plain');
  };

  createNew = () => {
    this.isCNP
      ? this.loadYamlAsNewPolicy({
          _id: ControlStore.policyIdGenerator(),
          policyYaml: `apiVersion: cilium.io/v2
kind: CiliumNetworkPolicy
metadata: {}`,
        })
      : this.loadYamlAsNewPolicy({
          _id: ControlStore.policyIdGenerator(),
          policyYaml: `apiVersion: networking.k8s.io/v1
kind: NetworkPolicy
metadata: {}`,
        });
  };

  // PROXY TO SPEC

  getCardBy = (cardSide: CardSide, cardKind: CardKind): PolicyCard | null => {
    if (!this.currentSpec) return null;
    return this.currentSpec.getCardBy(cardSide, cardKind);
  };

  setCard = (card: PolicyCard): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.setCard(card);
  };

  setAllowedEndpoint = (endpointId: string, state: boolean): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.setAllowedEndpoint(endpointId, state);
  };

  setSpecPodSelector = (selector: EndpointSelector): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.setSpecPodSelector(selector);
  };

  allowFullInNamespaceEgress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.allowFullInNamespaceEgress();
  };

  denyFullInNamespaceEgress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.denyFullInNamespaceEgress();
  };

  allowFullInClusterEgress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.allowFullInClusterEgress();
  };

  denyFullInClusterEgress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.denyFullInClusterEgress();
  };

  allowFullInNamespaceIngress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.allowFullInNamespaceIngress();
  };

  denyFullInNamespaceIngress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.denyFullInNamespaceIngress();
  };

  allowFullInClusterIngress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.allowFullInClusterIngress();
  };

  denyFullInClusterIngress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.denyFullInClusterIngress();
  };

  cleanupEgressWorld = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.cleanupEgressWorld();
  };

  toggleDefaultDenyIngress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.toggleDefaultDenyIngress();
  };

  enableDefaultDenyIngress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.enableDefaultDenyIngress();
  };

  disableDefaultDenyIngress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.disableDefaultDenyIngress();
  };

  allowKubeDns = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.allowKubeDns();
  };

  denyKubeDns = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.denyKubeDns();
  };

  enableDNSProxy = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.enableDNSProxy();
  };

  disableDNSProxy = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.disableDNSProxy();
  };

  toggleDefaultDenyEgress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.toggleDefaultDenyEgress();
  };

  disableDefaultDenyEgress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.disableDefaultDenyEgress();
  };

  enableDefaultDenyEgress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.enableDefaultDenyEgress();
  };

  toggleAllowedEnpoint = (endpointId: string): boolean => {
    if (!this.currentSpec) return false;
    return this.currentSpec.toggleAllowedEnpoint(endpointId);
  };

  isAllowedEndpoint = (endpointId: string): boolean => {
    if (!this.currentSpec) return false;
    return this.currentSpec.isAllowedEndpoint(endpointId);
  };

  isVisibleCard = (card: PolicyCard): boolean => {
    if (!this.currentSpec) return false;
    return this.currentSpec.isVisibleCard(card);
  };

  getRuleStatusInfo = (
    card: PolicyCard,
    endpoint: PolicyEndpoint,
  ): RuleStatusInfo | null => {
    if (!this.currentSpec) return null;
    return this.currentSpec.getRuleStatusInfo(card, endpoint);
  };

  flowsToCardEndpoints = (
    flows: Flow[],
  ): {
    cardSide: CardSide;
    cardKind: CardKind;
    endpoints: PolicyEndpoint[];
  }[] => {
    const r: {
      cardSide: CardSide;
      cardKind: CardKind;
      endpoints: PolicyEndpoint[];
    }[] = [];
    flows.forEach(flow => r.push(...this.flowToCards(flow)));
    return r;
  };

  flowToCards = (
    flow: Flow,
  ): {
    cardSide: CardSide;
    cardKind: CardKind;
    endpoints: PolicyEndpoint[];
  }[] => {
    if (!this.currentSpec) return [];
    if (flow.isReply) return [];
    if (flow.type !== FlowType.L34 && flow.type !== FlowType.L7) return [];
    if (flow.trafficDirection === TrafficDirection.Unknown) return [];
    if (!flow.destinationPort) return [];
    if (
      flow.sourceNamespace !== this.policyNamespace &&
      flow.destinationNamespace !== this.policyNamespace
    ) {
      return [];
    }
    if (flow.isKubeDnsFlow) return [];

    const dstPort = String(flow.destinationPort);

    if (flow.trafficDirection === TrafficDirection.Egress) {
      if (flow.isKubeDnsFlow) {
        return [
          {
            cardSide: CardSide.Egress,
            cardKind: CardKind.OutsideCluster,
            endpoints: [PolicyEndpoint.newKubeDns()],
          },
        ];
      }

      if (flow.isFqdnFlow) {
        const endpoints: PolicyEndpoint[] = [];
        flow.destinationNamesList.forEach(fqdn => {
          const endpoint = PolicyEndpoint.fromFQDNString(fqdn);
          endpoint.addPortsFromStringArray([dstPort]);
          endpoints.push(endpoint);
        });
        return [
          {
            cardSide: CardSide.Egress,
            cardKind: CardKind.OutsideCluster,
            endpoints,
          },
        ];
      }

      if (flow.isIpFlow) {
        const endpoint = PolicyEndpoint.newAll().addPortsFromStringArray([
          dstPort,
        ]);
        return [
          {
            cardSide: CardSide.Egress,
            cardKind: CardKind.OutsideCluster,
            endpoints: [endpoint],
          },
        ];
      }

      if (
        flow.destinationLabels.length > 0 &&
        flow.sourceNamespace &&
        flow.sourceNamespace === this.policyNamespace
      ) {
        return this.labelsFlowToEndpoints({
          cardSide: CardSide.Egress,
          cards: this.currentSpec.cardsMap.egress,
          labels: flow.destinationLabels,
          port: dstPort,
        });
      }
    }

    if (flow.trafficDirection === TrafficDirection.Ingress) {
      if (flow.isIpFlow) {
        const endpoint = PolicyEndpoint.newAll().addPortsFromStringArray([
          dstPort,
        ]);
        return [
          {
            cardSide: CardSide.Ingress,
            cardKind: CardKind.OutsideCluster,
            endpoints: [endpoint],
          },
        ];
      }

      if (
        flow.sourceLabels.length > 0 &&
        flow.destinationNamespace &&
        flow.destinationNamespace === this.policyNamespace
      ) {
        return this.labelsFlowToEndpoints({
          cardSide: CardSide.Ingress,
          cards: this.currentSpec.cardsMap.ingress,
          labels: flow.sourceLabels,
          port: dstPort,
        });
      }
    }

    return [];
  };

  /* PRIVATE ACTIONS */

  private static createInitialPolicyInfoSnapshot = (): PolicyInfoSnapshot => {
    return {
      _id: ControlStore.policyIdGenerator(),
      originPolicyKind: PolicyKind.KNP,
      lastPolicyKind: PolicyKind.KNP,
      policyYaml: `apiVersion: networking.k8s.io/v1
kind: NetworkPolicy
metadata: {}`,
      defaultDenyIngresses: [null],
      defaultDenyEgresses: [null],
      unspprtdEgresses: [],
      unspprtdIngresses: [],
    };
  };

  private setupReactions = () => {
    autorun(() => {
      this.currentSpecs?.forEach(spec => {
        spec.cardsList.forEach(card => {
          if (card.isInNamespace || card.isSelector) {
            card.setNamespace(this.policyNamespace);
          }
        });
      });
    });
  };

  private labelsFlowToEndpoints = ({
    cardSide,
    labels,
    cards,
    port,
  }: {
    cardSide: CardSide;
    labels: KV[];
    cards: AnyCardsStore;
    port: string;
  }): {
    cardSide: CardSide;
    cardKind: CardKind;
    endpoints: PolicyEndpoint[];
  }[] => {
    if (Labels.haveReserved(labels)) return [];

    const namespace = Labels.findNamespaceInLabels(labels);
    const isOnlyNamespaceLabel = namespace && labels.length === 1;

    const matchLabels = labels.reduce<{
      [key: string]: string;
    }>((obj, { key, value }) => ({ ...obj, [key]: value }), {});

    console.log(matchLabels);

    const endpointKind = isOnlyNamespaceLabel
      ? EndpointKind.NamespaceSelector
      : EndpointKind.LabelsSelector;

    const endpoint = PolicyEndpoint.fromKind(endpointKind)
      .setSelector({ matchLabels })
      .addPortsFromStringArray([port]);

    const card = cards.get(PolicyCard.buildId(cardSide, CardKind.InCluster));
    if (!card) return [];

    return [
      {
        cardSide: card.side,
        cardKind: card.kind,
        endpoints: [endpoint],
      },
    ];
  };

  private setupPersistance = () => {
    const buildSnaphot = (ref: PolicyStore) => {
      if (!ref.currentSpec || !ref.currentSpecs) return null;
      return {
        _id: this._controls.policyId,
        // pick cnp all the time
        policyYaml: ref.policyCnpSpecsYaml,
        originPolicyKind: ref.currentSpec.originPolicyKind,
        lastPolicyKind: ref.policyKind,
        unspprtdEgresses: ref.currentSpecs.map(spec => spec.unspprtdEgress),
        unspprtdIngresses: ref.currentSpecs.map(spec => spec.unspprtdIngress),
        defaultDenyEgresses: ref.currentSpecs.map(
          spec => spec.defaultDenyEgress,
        ),
        defaultDenyIngresses: ref.currentSpecs.map(
          spec => spec.defaultDenyIngress,
        ),
        updatedAt: Date.now(),
      };
    };

    const writeToPouch = debounce((policyInfo: PolicyInfoSnapshot) => {
      const snapshot: PolicyInfoSnapshot = {
        _id: policyInfo._id,
        defaultDenyEgresses: policyInfo.defaultDenyEgresses,
        defaultDenyIngresses: policyInfo.defaultDenyIngresses,
        lastPolicyKind: policyInfo.lastPolicyKind,
        originPolicyKind: policyInfo.originPolicyKind,
        policyYaml: policyInfo.policyYaml,
        unspprtdEgresses: policyInfo.unspprtdEgresses,
        unspprtdIngresses: policyInfo.unspprtdIngresses,
        updatedAt: Date.now(),
      };

      return this._db
        .get<PolicyInfoSnapshot>(policyInfo._id)
        .then(doc => {
          doc.defaultDenyEgresses = snapshot.defaultDenyEgresses;
          doc.defaultDenyIngresses = snapshot.defaultDenyIngresses;
          doc.lastPolicyKind = snapshot.lastPolicyKind;
          doc.originPolicyKind = snapshot.originPolicyKind;
          doc.policyYaml = snapshot.policyYaml;
          doc.unspprtdEgresses = snapshot.unspprtdEgresses;
          doc.unspprtdIngresses = snapshot.unspprtdIngresses;
          doc.updatedAt = snapshot.updatedAt;
          return this._db.put(doc);
        })
        .catch(reason => {
          if (reason?.status !== 404) {
            console.error(reason);
            return;
          }
          return this._db.put(snapshot);
        });
    }, 1000);

    const write = async (policyInfo: PolicyInfoSnapshot) => {
      writeToPouch(policyInfo);
      storage.savePolicyInfo(policyInfo);
      storage.saveLastPolicyId(policyInfo._id);
      return policyInfo;
    };

    reaction(
      () => buildSnaphot(this),
      snapshot => {
        if (!snapshot || !snapshot.policyYaml) return;
        write(snapshot as PolicyInfoSnapshot).catch(error =>
          console.error(error),
        );
      },
    );
  };

  /* PRIVATE STATIC */

  @memoize
  private static getCNPValidator() {
    const ajv = new AJV({ allErrors: true, allowUnionTypes: true });
    addAJVFormats(ajv);
    return { validate: ajv.compile(cnpSchema.schema), ajv };
  }

  @memoize
  private static getKNPValidator() {
    const ajv = new AJV({ allErrors: true });
    addAJVFormats(ajv);
    return { validate: ajv.compile(knpSchema.schema), ajv };
  }

  private static genPolicySpec(
    policyNamespace: string | null,
    spec: SpecStore,
    builder:
      | typeof CiliumNetworkPolicyBuilder
      | typeof KubernetesNetworkPolicyBuilder,
  ) {
    const srcs = new Map<string, PolicyCard>();
    const dsts = new Map<string, PolicyCard>();

    spec.allowedEndpointsSet.forEach((id: string) => {
      const [cardId, endpointId] = PolicyCard.parseFullCardEndpointId(id);

      const src = spec.cardsMap.ingress.get(cardId);
      if (src) {
        const endpoint = src.endpointsMap.get(endpointId);
        if (!endpoint) return;
        const card = srcs.get(cardId) ?? src.clone().flushEndpoints();
        card.addEndpoints(endpoint);
        srcs.set(cardId, card);
        return;
      }

      const dst = spec.cardsMap.egress.get(cardId);
      if (dst) {
        const endpoint = dst.endpointsMap.get(endpointId);
        if (!endpoint) return;
        const card = dsts.get(cardId) ?? dst.clone().flushEndpoints();
        card.addEndpoints(endpoint);
        dsts.set(cardId, card);
        return;
      }
    });

    return builder.generateSpec(
      spec.selectorCard.podSelector,
      srcs,
      dsts,
      spec.defaultDenyIngress,
      spec.defaultDenyEgress,
      policyNamespace,
    );
  }

  private static createInitialCards = (): Map<string, PolicyCard> => {
    const map = new Map<string, PolicyCard>();
    const add = (card: PolicyCard) => map.set(card.id, card);
    add(new PolicyCard(CardSide.Selector, CardKind.InNamespace));
    [CardSide.Ingress, CardSide.Egress].forEach(cardSide => {
      [
        CardKind.All,
        CardKind.OutsideCluster,
        CardKind.InNamespace,
        CardKind.InCluster,
      ].forEach(cardKind => {
        const card = new PolicyCard(cardSide, cardKind);
        const allEndpoint = PolicyEndpoint.newAll();
        if (card.isInCluster) {
          if (card.isEgress) {
            const kubeDns = PolicyEndpoint.newKubeDns();
            card.addEndpoints(kubeDns);
          }
        }
        card.addEndpoints(allEndpoint);
        add(card);
      });
    });
    return map;
  };
}
