import React, { Suspense } from 'react';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import type { Props } from './YAMLEditor';

export const Editor = React.lazy(() => import('./YAMLEditor'));

export const YAMLEditor = (props: Props) => {
  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <NonIdealState
            icon={<Spinner size={100} />}
            title="Loading editor..."
          />
        }
      >
        <Editor {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

class ErrorBoundary extends React.Component<React.PropsWithChildren<{}>> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    console.error("Can't load yaml editor", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <NonIdealState icon="warning-sign" title="Can't load policy YAML" />
      );
    }
    return this.props.children;
  }
}
