import React, { createContext, memo, useContext } from 'react';
import { Store } from './stores/main';

const StoreContext = createContext<Store | null>(null);

export interface Props {
  store: Store;
  children: JSX.Element;
}

export const StoreProvider = memo<Props>(function StoreProvider(props: Props) {
  return (
    <StoreContext.Provider value={props.store}>
      {props.children}
    </StoreContext.Provider>
  );
});

export const useStore = () => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }

  return store;
};
