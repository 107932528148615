import {
  Button,
  Callout,
  Classes,
  Dialog,
  Icon,
  Intent,
} from '@blueprintjs/core';
import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import css from './styles.scss';
import dialogcss from './ShareDialog.scss';
import { copyTextToClipboard } from '~/domain/misc';

export interface Props {
  isOpen: boolean;
  onClose?: () => void;
  shareGist?: () => Promise<string | void>;
  containerRef?: HTMLElement;
}

export const ShareDialog = observer(function ShareDialog(props: Props) {
  const [sharing, setSharing] = useState<boolean>(false);
  const [sharedGistUrl, setSharedGistUrl] = useState<null | string>(null);
  const [shareError, setShareError] = useState<null | string>(null);

  const onShare = useCallback(() => {
    setSharing(true);
    props
      .shareGist?.()
      .then(url => {
        if (!url) throw "Can't share via GitHub Gist";
        setSharedGistUrl(url);
      })
      .catch(error => setShareError(error))
      .finally(() => setSharing(false));
  }, [props.shareGist]);

  const onCopyUrl = useCallback(() => {
    if (!sharedGistUrl) return;
    copyTextToClipboard(sharedGistUrl);
  }, [sharedGistUrl]);

  const onClose = useCallback(() => {
    setSharing(false);
    setSharedGistUrl(null);
    setShareError(null);
    props.onClose?.();
  }, [props.onClose]);

  return (
    <Dialog
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      isOpen={props.isOpen}
      onClose={onClose}
      portalContainer={props.containerRef}
      style={{ backgroundColor: '#fff' }}
    >
      <div className={`${Classes.DIALOG_BODY} ${css.shareDialog}`}>
        <div style={{ textAlign: 'center', marginBottom: '25px' }}>
          <Icon icon="share" iconSize={70} intent="success" />
        </div>
        <h2 className={css.title}>Share policy YAML via GitHub Gist</h2>
        <div style={{ marginBottom: '20px' }}>
          <p>
            Network Policy Editor will automatically write current policy YAML
            to GitHub Gist under your name and provide you with a link you can
            share. Network Policy Editor will request a read/write permissions
            to your GitHub Gist to perform this operation.
          </p>
        </div>
        {!sharedGistUrl && (
          <Callout intent={Intent.WARNING}>
            Browser may ask permission to open popup. Please allow it to show
            GitHub Authorization window.
          </Callout>
        )}
        {sharedGistUrl && (
          <Callout intent={Intent.SUCCESS} style={{ marginTop: '10px' }}>
            <h4>
              Link to share{' '}
              <Button
                outlined
                small
                onClick={onCopyUrl}
                className={dialogcss.copyButton}
                intent="success"
              >
                Copy
              </Button>
            </h4>
            <pre className={dialogcss.shareUrl}>{sharedGistUrl}</pre>
          </Callout>
        )}
        {shareError && (
          <Callout
            intent={Intent.DANGER}
            title="Sharing error"
            style={{ marginTop: '10px' }}
          >
            <pre className={dialogcss.shareError}>{String(shareError)}</pre>
          </Callout>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div
          className={Classes.DIALOG_FOOTER_ACTIONS}
          style={{ alignItems: 'center' }}
        >
          <Button onClick={onClose}>Close</Button>
          {!sharedGistUrl && (
            <Button
              onClick={onShare}
              intent="success"
              disabled={Boolean(sharedGistUrl || sharing)}
            >
              {sharing ? 'Sharing…' : 'Share'}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
});
