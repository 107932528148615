import React from 'react';
import {
  Intent,
  IToaster,
  IToasterProps,
  IconName,
  IActionProps,
  ILinkProps,
  IToastProps,
} from '@blueprintjs/core';

import {
  Notification,
  DismissAction,
  UpdateAction,
  ShowAction,
  DismissHandler,
} from '~/notifier/notification';
import { NotifierPosition } from '~/notifier/general';
import * as helpers from '~/notifier/helpers';

type Actions = [
  ShowAction | null | undefined,
  DismissAction | null | undefined,
  UpdateAction | null | undefined,
];

export interface Props {
  position?: NotifierPosition;
  maxNotifications?: number;
}

export class Notifier {
  public static readonly timeout = 5000;
  public static readonly maxNotificationsOnScreen = 5;

  public static prepareToasterProps(props: Props): IToasterProps {
    const position = helpers.position(
      props.position ?? NotifierPosition.TopCenter,
    );

    const maxToasts =
      props.maxNotifications ?? Notifier.maxNotificationsOnScreen;

    return { position, maxToasts };
  }

  private toaster: IToaster | null = null;

  public setBackend(toaster: IToaster) {
    this.toaster = toaster;
  }

  public showSimple(props: IToastProps) {
    return this.simple(props).show();
  }

  public showInfo(props: IToastProps) {
    return this.info(props).show();
  }

  public showSuccess(props: IToastProps) {
    return this.success(props).show();
  }

  public showError(props: IToastProps) {
    return this.error(props).show();
  }

  public showWarning(props: IToastProps) {
    return this.warning(props).show();
  }

  // These methods dont call show() on newly created notification
  public simple(props: IToastProps): Notification {
    this.setupCheck();

    const timeout = props.timeout ?? Notifier.timeout;
    const icon = props.icon ?? 'info-sign';

    return this.createNotification({
      ...props,
      intent: Intent.NONE,
      timeout,
      icon,
    });
  }

  public info(props: IToastProps): Notification {
    this.setupCheck();

    const timeout = props.timeout ?? Notifier.timeout;
    const icon = props.icon ?? 'info-sign';

    return this.createNotification({
      ...props,
      intent: Intent.PRIMARY,
      timeout,
      icon,
    });
  }

  public success(props: IToastProps): Notification {
    this.setupCheck();

    const timeout = props.timeout ?? Notifier.timeout;
    const icon = props.icon ?? 'tick-circle';

    return this.createNotification({
      ...props,
      intent: Intent.SUCCESS,
      timeout,
      icon,
    });
  }

  public error(props: IToastProps): Notification {
    this.setupCheck();

    const timeout = props.timeout ?? Notifier.timeout;
    const icon = props.icon ?? 'error';

    return this.createNotification({
      ...props,
      intent: Intent.DANGER,
      timeout,
      icon,
    });
  }

  public warning(props: IToastProps): Notification {
    this.setupCheck();

    const timeout = props.timeout ?? Notifier.timeout;
    const icon = props.icon ?? 'warning-sign';

    return this.createNotification({
      ...props,
      intent: Intent.WARNING,
      timeout,
      icon,
    });
  }

  public dismissAll() {
    this.setupCheck();
    this.toaster?.clear();
  }

  private createNotification(props: IToastProps): Notification {
    const acts = this.createNotificationActions(props);
    return new Notification(...acts);
  }

  private createNotificationActions(props: IToastProps): Actions {
    const show = (dmHandler?: DismissHandler) => {
      return this.toaster?.show({ ...props, onDismiss: dmHandler });
    };

    const dismiss: DismissAction = id => {
      if (id) {
        return this.toaster?.dismiss(id);
      }
    };

    const update: UpdateAction = (id, message, timeout) => {
      if (id) {
        timeout = timeout ?? props.timeout;
        this.toaster?.show({ ...props, message, timeout }, id);
      }
    };

    return [show, dismiss, update];
  }

  private setupCheck() {
    if (this.toaster != null) return;

    throw new Error(`you must call notifier.setup() once before using it `);
  }
}
