import { Button } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import React from 'react';
import { EndpointMatchExpression } from '~/domain/cimulator/types';
import css from './AdvancedSelectorInput.scss';
import crudcss from './CrudPopover.scss';
import {
  MatchExpressionsInput,
  Props as MatchExpressionsInputProps,
} from './MatchExpressionsInput';
import {
  MatchLabelsInput,
  Props as MatchLabelsInputProps,
} from './MatchLabelsInput';

export interface Props {
  matchLabels: MatchLabelsInputProps;
  matchExpressions: MatchExpressionsInputProps[];
  onDeleteMatchExpression?: (idx: number) => void;
  onUpdateMatchExpression?: (
    idx: number,
    expr: EndpointMatchExpression,
  ) => void;
}

export const AdvancedSelectorInput = observer(function AdvancedSelectorInput(
  props: Props,
) {
  return (
    <div className={css.wrapper}>
      <label className={crudcss.section}>
        <MatchLabelsInput {...props.matchLabels} />
      </label>
      <label className={crudcss.section}>
        {props.matchExpressions.map((expr, index) => {
          return (
            <MatchExpressionsInput
              key={index}
              {...expr}
              onDelete={props.onDeleteMatchExpression}
              onChange={props.onUpdateMatchExpression}
            />
          );
        })}
      </label>
    </div>
  );
});
