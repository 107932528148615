import React from 'react';

export type DismissAction = (id: string | null | undefined) => void;
export type UpdateAction = (
  id: string | null | undefined,
  message: React.ReactNode,
  timeout?: number,
) => void;
export type ShowAction = (
  dismissHandler?: DismissHandler | undefined,
) => string | undefined;

export type DismissHandler = (timeouted: boolean) => void;

export class Notification {
  private isDismissed: boolean;
  private dismissHandler: DismissHandler | undefined;
  private notificationId?: string | null;

  private dismissAction?: DismissAction | null;
  private updateAction?: UpdateAction | null;
  private showAction?: ShowAction | null;

  constructor(
    show?: ShowAction | null,
    dismiss?: DismissAction | null,
    update?: UpdateAction | null,
  ) {
    this.notificationId = null;
    this.isDismissed = false;
    this.dismissHandler = undefined;

    this.showAction = show;
    this.updateAction = update;
    this.dismissAction = dismiss;
  }

  public show(): Notification | null {
    if (this.dismissed) return null;

    this.notificationId = this.showAction?.(this.dismissHandler);

    return this;
  }

  public hide() {
    return this.dismiss();
  }

  public dismiss() {
    if (this.inactive) return;
    this.dismissAction?.(this.notificationId);
    this.isDismissed = true;
  }

  public onDismiss(handler: DismissHandler) {
    this.dismissHandler = handler;
  }

  public update(
    message: React.ReactNode,
    timeout?: number,
  ): Notification | null {
    if (this.inactive) return null;

    this.updateAction?.(this.notificationId, message, timeout);

    return this;
  }

  public get dismissed() {
    return this.isDismissed;
  }

  public get id() {
    return this.notificationId;
  }

  private get inactive(): boolean {
    return this.dismissed || this.notificationId == null;
  }
}
