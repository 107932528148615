import React, { memo, ReactNode } from 'react';
import { useDrag } from '@use-gesture/react';

import css from './styles.scss';

export interface Props {
  onResize?: (dy: number) => void;
  children: ReactNode;
}

export const Component = function DragPanelComponent(props: Props) {
  const bind = useDrag(e => {
    const dy = e.delta[1];
    props.onResize && props.onResize(dy);
  });

  return (
    <div {...bind()} className={css.dragPanel}>
      {props.children}
    </div>
  );
};

export const DragPanel = memo<Props>(Component);
DragPanel.displayName = 'DragPanelMemoized';
