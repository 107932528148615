import { Button, Popover } from '@blueprintjs/core';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  PolicyCard as DomainPolicyCard,
  PolicyCard,
} from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import { CardSide, PolicyKind } from '~/domain/cimulator/types';
import { useStore } from '~/store';
import {
  AssistantEmitterActions,
  emitter,
} from '~/store/stores/assistant/emitter';
import { usePopover } from '~/ui/hooks/usePopover';
import { AnalyticsTrackKind, track } from '~/utils/analytics';
import { CrudPopover } from './CrudPopover';
import { RuleKind, RuleKindsByPolicy } from './general';
import css from './styles.scss';

interface Props {
  card: DomainPolicyCard;
  defaultDenyIngress: boolean;
  defaultDenyEgress: boolean;
  onToggleDefaultDenyIngress?: () => void;
  onToggleDefaultDenyEgress?: () => void;
  onSelectElement?: (card?: PolicyCard, endpoint?: PolicyEndpoint) => void;
}

const icons: { [key: string]: [string, number, number] } = {
  all: ['/icons/logos/all-2.png', 17.5, 17],
  world: ['/icons/logos/world-2.png', 26, 26],
  cluster: ['/icons/logos/cluster-2.png', 28.88, 28.22],
  namespace: ['/icons/logos/namespace-2.png', 27.85, 23.99],
};

export const CardHeader = observer(function CardHeader(props: Props) {
  const store = useStore();

  const [initRuleKind, setInitRuleKind] = useState<RuleKind | null>(null);

  const popover = usePopover({
    popoverProps: {
      onOpened: useCallback(() => {
        if (props.card.side === CardSide.Selector) {
          track(AnalyticsTrackKind.PolicyRuleCRUD, {
            action: 'open-pod-selector',
          });
        } else if (initRuleKind) {
          track(AnalyticsTrackKind.PolicyRuleCRUD, {
            action: 'start-new-rule',
            cardSide: props.card.side,
            cardKind: props.card.kind,
            ruleKind: initRuleKind,
          });
        } else {
          track(AnalyticsTrackKind.PolicyRuleCRUD, {
            action: 'open-kinds-list',
            cardSide: props.card.side,
            cardKind: props.card.kind,
          });
        }
      }, [initRuleKind, props.card.side, props.card.kind]),
    },
  });

  useEffect(() => {
    return emitter.on(
      AssistantEmitterActions.OpenCardCrudPopup,
      (cardSide, cardKind, ruleKind) => {
        if (cardSide !== props.card.side || cardKind !== props.card.kind) {
          return popover.close();
        }

        if (!ruleKind) {
          return popover.open();
        }

        const policyKinds =
          RuleKindsByPolicy[store.policy.policyKind ?? PolicyKind.KNP];
        if (!policyKinds[cardSide][cardKind].includes(ruleKind)) {
          return popover.close();
        }

        setInitRuleKind(ruleKind);
        popover.open();
      },
    );
  }, [props.card, store.policy.policyKind, popover]);

  useEffect(() => {
    if (!popover.isOpen) setInitRuleKind(null);
  }, [popover.isOpen]);

  const [iconSrc, iconWidth, iconHeight] = useMemo(() => {
    return icons[
      props.card.isAll
        ? 'all'
        : props.card.isOutsideCluster
        ? 'world'
        : props.card.isInCluster
        ? 'cluster'
        : 'namespace'
    ];
  }, [props.card]);

  const onAddPopoverClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      popover.toggle();
    },
    [popover.toggle],
  );

  const onCrudDone = useCallback(
    (card?: PolicyCard, endpoint?: PolicyEndpoint) => {
      props.onSelectElement?.(card, endpoint);
      popover.close();
    },
    [props.onSelectElement],
  );

  return (
    <div className={css.cardHeader}>
      <div className={css.top}>
        <div className={css.icon}>
          <img src={iconSrc} width={iconWidth} height={iconHeight} />
        </div>

        <div className={css.captions}>
          <div className={css.title}>{props.card.caption}</div>
          {props.card.subcaption && (
            <div className={css.subTitle}>{props.card.subcaption}</div>
          )}
        </div>
        <Popover
          {...popover.props}
          lazy={true}
          hasBackdrop={true}
          position={props.card.isEgress ? 'left' : 'right'}
          content={
            <CrudPopover
              card={props.card}
              initRuleKind={initRuleKind}
              onDone={onCrudDone}
            />
          }
        >
          <Button
            minimal
            className={css.addIcon}
            icon={props.card.isSelector ? 'annotation' : 'plus'}
            onClick={onAddPopoverClick}
          />
        </Popover>
      </div>

      {props.card.isSelector && (
        <DefaultDenyInfo
          defaultDenyIngress={props.defaultDenyIngress}
          defaultDenyEgress={props.defaultDenyEgress}
          onToggleDefaultDenyIngress={props.onToggleDefaultDenyIngress}
          onToggleDefaultDenyEgress={props.onToggleDefaultDenyEgress}
        />
      )}
    </div>
  );
});

interface DefaultDenyInfoProps {
  defaultDenyIngress: boolean;
  defaultDenyEgress: boolean;
  onToggleDefaultDenyIngress?: () => void;
  onToggleDefaultDenyEgress?: () => void;
}

const DefaultDenyInfo = memo(function DefaultDenyInfo(
  props: DefaultDenyInfoProps,
) {
  const ingressIcon = props.defaultDenyIngress ? (
    <LockClosedIcon />
  ) : (
    <LockOpenedIcon />
  );

  const egressIcon = props.defaultDenyEgress ? (
    <LockClosedIcon />
  ) : (
    <LockOpenedIcon />
  );

  const ingressCls = classnames({
    [css.left]: true,
    [css.allow]: !props.defaultDenyIngress,
  });

  const egressCls = classnames({
    [css.right]: true,
    [css.allow]: !props.defaultDenyEgress,
  });

  const onClickIngress = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      props.onToggleDefaultDenyIngress?.();
    },
    [props.onToggleDefaultDenyIngress],
  );

  const onClickEgress = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      props.onToggleDefaultDenyEgress?.();
    },
    [props.onToggleDefaultDenyEgress],
  );

  return (
    <div className={css.connectors}>
      <Button
        minimal
        className={ingressCls}
        onClick={onClickIngress}
        icon={ingressIcon}
      >
        Ingress
        <br />
        Default {props.defaultDenyIngress ? 'Deny' : 'Allow'}
      </Button>

      <Button
        minimal
        className={egressCls}
        onClick={onClickEgress}
        rightIcon={egressIcon}
      >
        Egress
        <br />
        Default {props.defaultDenyEgress ? 'Deny' : 'Allow'}
      </Button>
    </div>
  );
});

const LockOpenedIcon = () => {
  return (
    <svg width="26" height="24" xmlns="http://www.w3.org/2000/svg">
      <rect width="26" height="24" rx="3" fill="currentColor" />
      <mask id="a" fill="#fff">
        <rect x="6" y="10" width="11" height="8" rx="1" />
      </mask>
      <rect
        x="6"
        y="10"
        width="11"
        height="8"
        rx="1"
        stroke="#fff"
        strokeWidth="4"
        mask="url(#a)"
      />
      <path
        d="M20 10V8.5A2.5 2.5 0 0017.5 6v0A2.5 2.5 0 0015 8.5V10"
        stroke="#fff"
        strokeWidth="2"
      />
    </svg>
  );
};

const LockClosedIcon = () => {
  return (
    <svg width="25" height="24" xmlns="http://www.w3.org/2000/svg">
      <rect width="25" height="24" rx="3" fill="currentColor" />
      <mask id="a" fill="#fff">
        <rect x="7" y="10" width="11" height="8" rx="1" />
      </mask>
      <rect
        x="7"
        y="10"
        width="11"
        height="8"
        rx="1"
        stroke="#fff"
        strokeWidth="4"
        mask="url(#a)"
      />
      <path
        d="M15 10V8.5A2.5 2.5 0 0012.5 6v0A2.5 2.5 0 0010 8.5V10"
        stroke="#fff"
        strokeWidth="2"
      />
    </svg>
  );
};
