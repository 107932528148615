import React from 'react';
import { ITooltipProps } from '@blueprintjs/core';
import { useLayoutEffect, useMemo, useState } from 'react';

import css from './styles.scss';

export function useTooltip(props?: ITooltipProps) {
  const [portalContainer, setPortalContainer] = useState<HTMLElement>();

  useLayoutEffect(() => {
    const portal = document.getElementById('alerts-portal');
    if (portal) setPortalContainer(portal);
  }, []);

  const contentProp = useMemo(() => {
    if (!props?.content) return undefined;
    return <div className={css.tooltipContent}>{props.content}</div>;
  }, [props?.content]);

  return {
    props: {
      portalContainer,
      boundary: 'window',
      className: css.tooltip,
      ...props,
      content: contentProp,
    } as ITooltipProps,
  };
}
