import { Button, Menu, MenuItem, Popover, Spinner } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useStore } from '~/store';
import {
  AssistantEmitterActions,
  emitter as assistantEmitter,
} from '~/store/stores/assistant/emitter';
import { PolicyAssistantTutorial } from '~/store/stores/assistant/general';
import { usePopover } from '~/ui/hooks/usePopover';

import css from './TutorialSelector.scss';

export interface Props {
  active: boolean;
  onChange?: (tutorial: PolicyAssistantTutorial) => void;
}

export const TutorialSelector = observer(function TutorialSelector(
  props: Props,
) {
  const store = useStore();
  const popover = usePopover();

  const onPopoverClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      popover.toggle();
    },
    [popover],
  );

  const tutorials = (
    <Menu>
      {store.assistant.tutorials.map(tutorial => {
        return (
          <MenuItem
            key={tutorial.id}
            text={tutorial.title}
            shouldDismissPopover={true}
            active={tutorial.id === store.assistant.currentTutorial?.id}
            onClick={() => {
              assistantEmitter.emit(
                AssistantEmitterActions.OpenTutorial,
                tutorial,
              );
              props.onChange?.(tutorial);
            }}
          />
        );
      })}
    </Menu>
  );

  return (
    <Popover {...popover.props} minimal content={tutorials} position="bottom">
      <Button
        active={props.active}
        className={css.button}
        text={store.assistant.currentTutorial?.title ?? 'Select tutorial…'}
        alignText="left"
        icon={
          store.assistant.fetchingTutorials ? (
            <Spinner size={16} />
          ) : (
            'caret-down'
          )
        }
        onClick={onPopoverClick}
      />
    </Popover>
  );
});
