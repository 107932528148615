import { Position } from '~/domain/geometry/position';

export enum NotifierPosition {
  TopCenter,
  TopLeft,
  TopRight,
  BottomCenter,
  BottomLeft,
  BottomRight,
}
