import { observer } from 'mobx-react';
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';

import { DragPanel } from '~/components/DragPanel';
import { SlackButton } from '../Misc/SlackButton';
import { usePanelResize, ResizeProps } from './hooks/usePanelResize';

import css from './styles.scss';

export interface ChildrenProps {
  dragPanel?: ReactNode;
  content?: ReactNode;
}

export interface Props {
  onPanelResize?: (resizeProps: ResizeProps) => void;
  children: ChildrenProps;
}

export const ResizablePanelComponent = function (props: Props) {
  const panelResize = usePanelResize();

  useEffect(() => {
    props.onPanelResize?.(panelResize.props);
  }, [props.onPanelResize, panelResize.props]);

  return (
    <div className={css.panel} ref={panelResize.ref} style={panelResize.style}>
      <div className={css.slackButton}>
        <SlackButton />
      </div>
      <div className={css.dragPanel}>
        <DragPanel onResize={panelResize.onResize}>
          {props.children.dragPanel}
        </DragPanel>
      </div>

      <div className={css.content}>{props.children.content}</div>
    </div>
  );
};

export const ResizablePanel: FunctionComponent<Props> = observer(
  ResizablePanelComponent,
);

export { ResizeProps };
