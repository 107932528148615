export const LABEL_PREFIXES = ['', 'k8s:', 'any:'];

export const ENDPOINT_NAME_LABELS = [
  'app.kubernetes.io/name',
  'app',
  'name',
  'functionName',
  'k8s-app',
].reduce<string[]>((accum, label) => {
  return accum.concat(LABEL_PREFIXES.map(prefix => `${prefix}${label}`));
}, []);

export class Labels {
  public static isEndpointNameKey(key: string): boolean {
    return ENDPOINT_NAME_LABELS.includes(key);
  }
}
