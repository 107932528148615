import { NonIdealState, Spinner } from '@blueprintjs/core';
import React, { memo } from 'react';
import css from './styles.scss';

export interface Props {
  text: string;
  transparent?: boolean;
}

export const LoadingOverlay = memo<Props>(function LoadingOverlay(props) {
  return (
    <div className={`${css.loader} ${props.transparent && css.transparent}`}>
      <NonIdealState
        title={<Spinner intent="success" size={80} />}
        description={<span className={css.loaderText}>{props.text}</span>}
      />
    </div>
  );
});
