import {
  Button,
  ButtonGroup,
  ControlGroup,
  Icon,
  InputGroup,
  NonIdealState,
  Popover,
  Tooltip,
} from '@blueprintjs/core';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import policyFromFlowsImg from '~/assets/images/policy-from-flows.png';
import { FlowsTable } from '~/components/FlowsTable';
import { useFlowsTableColumns } from '~/components/FlowsTable/hooks/useColumns';
import { FlowsTableSidebar } from '~/components/FlowsTable/Sidebar';
import { Flow } from '~/domain/flows';
import { copyToClipboard, slug } from '~/domain/misc';
import { useStore } from '~/store';
import { usePopover } from '~/ui/hooks/usePopover';
import { AnalyticsTrackKind, track } from '~/utils/analytics';
import { FlowsSettings } from './FlowsSettings';
import css from './HubblePanel.scss';

export interface Props {
  flows: Flow[];
  kind?: PanelKind;
  selectedFlow?: Flow | null;
  onSelectFlow?: (flow: Flow | null) => void;
  onUploadFlows?: (
    event: React.SyntheticEvent,
    onSuccess?: (flows: Flow[]) => void,
    onError?: (error: unknown) => void,
  ) => void;
  onCloseFlowsTableSidebar?: () => void;
  onSetPanelKind?: (kind: PanelKind) => void;
}

export enum PanelKind {
  Promo = 'promo',
  Help = 'help',
  Table = 'table',
}

export const HubblePanel = observer(function HubblePanel(props: Props) {
  const store = useStore();

  const [kind, setKind] = useState<PanelKind>(props.kind ?? PanelKind.Promo);
  const [prevKind, setPrevKind] = useState<PanelKind | null>(null);
  const [tableWasShown, setTableWasShown] = useState(
    props.kind === PanelKind.Table,
  );
  const [namespace, setNamespace] = useState(
    store.policy.policyNamespace ?? '',
  );

  const flowsTableColumns = useFlowsTableColumns(false);
  const settingsPopover = usePopover({
    stopPropagation: true,
    preventDefault: true,
  });

  const hubbleObserveCmd = useMemo(() => {
    const ns = store.policy.policyNamespace;

    const common = `hubble observe --output jsonpb --last 1000 --follow`;
    if (ns) {
      return `${common} --namespace ${ns} > ${slug(ns)}-flows.json`;
    } else {
      return `${common} > flows.json`;
    }
  }, [store.policy.policyNamespace]);

  const onTypeNamespace = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNamespace(event.target.value);
    },
    [],
  );

  const changeNamespace = useCallback(() => {
    store.controls.setPolicyNamespace(namespace);
  }, [namespace]);

  const copyHubbleObserveCmd = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      copyToClipboard(hubbleObserveCmd);
    },
    [hubbleObserveCmd],
  );

  useEffect(() => {
    props.onSetPanelKind?.(kind);
  }, [kind, props.onSetPanelKind]);

  const showHelp = useCallback(() => {
    setPrevKind(kind);
    setKind(PanelKind.Help);
    track(AnalyticsTrackKind.ShowUploadFlowsHelp);
  }, [kind]);

  const showPromo = useCallback(() => {
    setPrevKind(kind);
    setKind(PanelKind.Promo);
  }, [kind]);

  const showTable = useCallback(() => {
    setPrevKind(kind);
    setKind(PanelKind.Table);
    setTableWasShown(true);
  }, [kind]);

  const onBack = useCallback(() => {
    setPrevKind(kind);
    prevKind ? setKind(prevKind) : showPromo();
  }, [kind, prevKind]);

  const onUploadFlows = useCallback(
    (event: React.SyntheticEvent) => {
      props.onUploadFlows?.(event, showTable);
      track(AnalyticsTrackKind.UploadFlows, {
        source: 'upload-flows-panel',
      });
    },
    [props.onUploadFlows],
  );

  if (kind === PanelKind.Promo) {
    const showUploadButton = !tableWasShown || props.flows.length === 0;
    return (
      <div className={`${css.wrapper} ${css.promo}`}>
        <img src={policyFromFlowsImg} className={css.image} />
        <div className={css.title}>Create Policies from Flows</div>
        <div className={css.description}>
          Cilium Hubble helps collect flows from your Kubernetes cluster
        </div>
        {showUploadButton && !store.policy.policyNamespace && (
          <form
            onSubmit={changeNamespace}
            style={{ fontSize: '14px', marginTop: '10px' }}
          >
            <div style={{ marginBottom: '7px' }}>
              To filter and aggregate flows policy namespace must be provided
            </div>
            <ControlGroup vertical={false}>
              <InputGroup
                large
                value={namespace}
                onChange={onTypeNamespace}
                placeholder="my-namespace"
              />
              <Button type="submit">Apply</Button>
            </ControlGroup>
          </form>
        )}
        <div className={css.actions}>
          {showUploadButton && store.policy.policyNamespace && (
            <ButtonGroup className={css.uploadButton}>
              <Button icon="upload" onClick={onUploadFlows}>
                Upload flows
              </Button>
              <Popover
                {...settingsPopover.props}
                hasBackdrop={true}
                content={<FlowsSettings />}
              >
                <Button icon="cog" onClick={settingsPopover.toggle} />
              </Popover>
            </ButtonGroup>
          )}
          {!showUploadButton && (
            <Button
              icon="upload"
              onClick={showTable}
              className={css.uploadButton}
            >
              Open flows table
            </Button>
          )}
          <Button outlined intent="primary" onClick={showHelp}>
            Help
          </Button>
        </div>
      </div>
    );
  }

  if (kind === PanelKind.Help) {
    return (
      <div className={`${css.wrapper} ${css.help}`}>
        <div className={css.actions}>
          <Button outlined icon="arrow-left" onClick={onBack}>
            Back
          </Button>
        </div>
        <a
          href="https://docs.cilium.io/en/stable/gettingstarted/hubble/"
          target="_blank"
          rel="noreferrer"
          className={css.hubbleObserveCmd}
        >
          <u>{hubbleObserveCmd}</u>
          <Tooltip content="Copy to clipboard" minimal>
            <Icon
              icon="clipboard"
              onClick={copyHubbleObserveCmd}
              iconSize={12}
              className={css.copyIcon}
            />
          </Tooltip>
        </a>
        <div className={css.text}>
          Use command above to get flows with Hubble. Only flows convertable to
          policy rules will be uploaded
        </div>
      </div>
    );
  }

  const title =
    props.flows.length === 1
      ? '1 flow uploaded'
      : `${props.flows.length} flows uploaded`;

  return (
    <div className={`${css.wrapper} ${css.flows}`}>
      <div className={css.header}>
        <div className={css.left}>
          <ButtonGroup className={css.uploadButton}>
            <Button icon="upload" onClick={onUploadFlows}>
              Upload flows
            </Button>
            <Popover
              {...settingsPopover.props}
              hasBackdrop={true}
              content={<FlowsSettings />}
            >
              <Button icon="cog" onClick={settingsPopover.toggle} />
            </Popover>
          </ButtonGroup>
          <div className={css.title}>{title}</div>
        </div>
        <div className={css.right}>
          <Button minimal intent="primary" onClick={showHelp}>
            Help
          </Button>
          <Button icon="cross" minimal onClick={showPromo}></Button>
        </div>
      </div>
      <div className={css.table}>
        {props.flows.length > 0 && (
          <FlowsTable
            flows={props.flows}
            isVisibleColumn={flowsTableColumns.isVisibleColumn}
            onSelectFlow={props.onSelectFlow}
          />
        )}
        {props.flows.length === 0 && (
          <NonIdealState
            icon="disable"
            title="No flows convertable to policy rules"
            description={
              <Button icon="upload" onClick={onUploadFlows}>
                Upload flows
              </Button>
            }
          />
        )}
        {props.selectedFlow && (
          <FlowsTableSidebar
            flow={props.selectedFlow}
            onClose={props.onCloseFlowsTableSidebar}
          />
        )}
      </div>
    </div>
  );
});
