export enum QueryParams {
  PolicyUrl = 'policy-url',
  PolicyTutorial = 'policy-tutorial',
  PolicyGist = 'gist',
  PolicyId = 'id',
}

export class URLUtils {
  static getQueryParam(key: QueryParams) {
    const params = new URLSearchParams(window.location.search);
    return params.get(key);
  }

  static setQueryParam(key: QueryParams, value: string | null) {
    const params = new URLSearchParams(window.location.search);
    if (typeof value === 'string') {
      params.set(key, value);
    } else {
      params.delete(key);
    }
    const nextParams = params.toString();
    window.history.pushState(
      {},
      document.title,
      nextParams ? '/?' + nextParams : '/',
    );
  }
}
