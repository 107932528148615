import { Button, Checkbox, Classes, Dialog } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import lockerImg from '~/assets/images/locker.png';
import { useStore } from '~/store';
import css from './styles.scss';

export interface Props {
  isOpen: boolean;
  onClose?: () => void;
  containerRef?: HTMLElement;
}

export const OnboardingDialog = observer(function OnboardingDialog(
  props: Props,
) {
  const store = useStore();

  const toggleSkip = useCallback(() => {
    store.controls.skipOnboardingDialog = !store.controls.skipOnboardingDialog;
  }, [store.controls.skipOnboardingDialog]);

  return (
    <Dialog
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      isOpen={props.isOpen}
      onClose={props.onClose}
      portalContainer={props.containerRef}
      style={{ backgroundColor: '#fff' }}
    >
      <div className={`${Classes.DIALOG_BODY} ${css.onboarding}`}>
        <h1 className={css.title}>
          Learn How To Create
          <br /> Network Policies for Kubernetes
        </h1>
        <img src={lockerImg} className={css.locker} />
        <p>
          You start with an empty policy that does not restrict or allow
          anything. By default, all traffic for all pods is allowed in
          Kubernetes.
        </p>
        <p>
          The box in the middle represents a <b>podSelector</b>; it selects pods
          in a namespace to apply policy rules. The pictured podSelector is set
          to an empty selector. Leave it like this to select all pods in a
          namespace, or change it to define a more specific subset of pods using
          labels.
        </p>
        <p>
          The boxes on the left and right represent a visual grouping of network
          policy rules for ingress and egress traffic, respectively. Use the [+]
          icon on each box to add policy rules, and mouse over the rules to
          learn more.
        </p>
        <p>
          <b>
            Start by following the tutorial located on the right bottom corner.
          </b>
        </p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div
          className={Classes.DIALOG_FOOTER_ACTIONS}
          style={{ alignItems: 'center' }}
        >
          <Checkbox
            label="Do not show me this again"
            checked={store.controls.skipOnboardingDialog}
            onChange={toggleSkip}
          />
          <Button onClick={props.onClose}>Start</Button>
        </div>
      </div>
    </Dialog>
  );
});
