import { makeAutoObservable } from 'mobx';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { PolicyAssistantEntry, PolicyAssistantTutorial } from '../general';

export class PolicyAssistantMarkdownTutorial
  implements PolicyAssistantTutorial {
  id: string;
  title: string;
  markdown: string | null;
  yaml?: string | null;

  constructor({
    id,
    title,
    markdown,
    yaml,
  }: {
    id: string;
    title: string;
    markdown: string | null;
    yaml?: string | null;
  }) {
    makeAutoObservable(this);

    this.id = id;
    this.title = title;
    this.markdown = markdown;
    this.yaml = yaml;
  }

  get entries() {
    if (!this.markdown) return [];

    return [
      new PolicyAssistantEntry({
        id: this.id,
        title: null,
        description: <ReactMarkdown>{this.markdown}</ReactMarkdown>,
      }),
    ];
  }
}
