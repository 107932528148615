import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from '~/store';
import css from './PolicyRatingPopover.scss';

export const PolicyRatingPopover = observer(function PolicyRatingPopover() {
  const store = useStore();

  if (!store.policy.actualPoints?.length) {
    return (
      <div className={css.wrapper}>
        <div className={css.title}>Policy Rating</div>
        No rating for current policy
      </div>
    );
  }

  const total = store.policy.roundedRating;

  return (
    <div className={css.wrapper}>
      <div className={css.title}>Policy Rating</div>
      <ul className={css.list}>
        {store.policy.actualPoints.map(([kind, point]) => {
          return (
            <li key={kind} className={css.item}>
              <div className={css.content}>
                <div className={css.kind}>
                  <span>{kind}</span>
                </div>
                <div className={css.point}>
                  <div className={css.pointBg}>{point}</div>
                </div>
              </div>
            </li>
          );
        })}
        <li className={`${css.item} ${css.total}`}>
          <div className={css.content}>
            <div className={css.kind}>
              <span>Total:</span>
            </div>
            <div className={css.point}>
              <div className={css.pointBg}>{total}/5</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
});
