import { configure } from 'mobx';
import {
  CimulatorArrowStrategy,
  CimulatorPlacementStrategy,
} from '~/domain/layout/cimulator';
import { PolicyAssistantStore } from './assistant';
import ControlStore from './controls';
import FlowsStore from './flows';
import { PolicyStore } from './policy';
import UserStore from './user';

configure({ enforceActions: 'observed' });

export class Store {
  controls: ControlStore;
  flows: FlowsStore;
  policy: PolicyStore;
  assistant: PolicyAssistantStore;
  placement: CimulatorPlacementStrategy;
  arrows: CimulatorArrowStrategy;
  user: UserStore;

  constructor() {
    this.controls = new ControlStore();
    this.flows = new FlowsStore();
    this.policy = new PolicyStore(this.controls, this.flows);
    this.user = new UserStore();
    this.assistant = new PolicyAssistantStore(this.controls, this.policy);
    this.placement = new CimulatorPlacementStrategy(this.policy);
    this.arrows = new CimulatorArrowStrategy(this.placement, this.policy);
  }
}
