import {
  Button,
  ButtonGroup,
  Classes,
  Popover,
  Tooltip,
} from '@blueprintjs/core';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { YAMLEditor } from '~/components/YAMLEditor';
import { Feature, isFeatureEnabled } from '~/features';
import { useStore } from '~/store';
import { AnalyticsTrackKind, track } from '~/utils/analytics';
import { Rating } from '../PolicyAssistant/Rating';
import { PolicyKindSelector } from './PolicyKindSelector';
import { PolicyRatingPopover } from './PolicyRatingPopover';
import { PolicyWarning } from './PolicyWarning';
import css from './YamlPanel.scss';

export interface Props {
  policyYaml: string;
  selectedCardId?: string | null;
  selectedEndpointId?: string | null;
  onDownloadYaml?: () => void;
  onShareYaml?: () => void;
  onUploadPolicy?: (event: React.SyntheticEvent) => void;
  onCreateNewPolicy?: () => void;
}

export const YamlPanel = observer(function YamlPanel(props: Props) {
  const store = useStore();

  const selectedCard = useMemo(() => {
    return store.policy.cardsList?.find(c => c.id === props.selectedCardId);
  }, [store.policy.cardsList, props.selectedCardId]);

  const selectedEndpoint = useMemo(() => {
    return selectedCard?.endpointsList?.find(
      e => e.id === props.selectedEndpointId,
    );
  }, [selectedCard?.endpointsList, props.selectedEndpointId]);

  const isSelectedEndpointAllowed = useMemo(() => {
    if (!selectedCard || !selectedEndpoint) return false;
    return store.policy.isAllowedEndpoint(
      selectedCard.fullEndpointId(selectedEndpoint.id),
    );
  }, [selectedCard, selectedEndpoint, store.policy.allowedEndpointsSet]);

  const onClickCreateNewPolicy = useCallback(() => {
    props.onCreateNewPolicy?.();
    track(AnalyticsTrackKind.CreatePolicy, {
      source: 'yaml-panel',
    });
  }, [props.onCreateNewPolicy]);

  const onClickUploadPolicy = useCallback(
    (event: React.SyntheticEvent) => {
      if (!isFeatureEnabled(Feature.UploadPolicy)) {
        event.preventDefault();
        return;
      }
      track(AnalyticsTrackKind.UploadPolicy, {
        source: 'yaml-panel',
      });
      props.onUploadPolicy?.(event);
    },
    [props.onUploadPolicy],
  );

  const onPrevSpec = useCallback(() => {
    store.policy.goToPrevSpec();
  }, []);

  const onNextSpec = useCallback(() => {
    store.policy.goToNextSpec();
  }, []);

  const cardEndpointYaml = useMemo(() => {
    if (!selectedCard) return null;
    return store.policy.curPolicyKindBuilder.generateYamlForCardEndpoint(
      selectedCard,
      (isSelectedEndpointAllowed ? selectedEndpoint : null) ?? null,
      selectedCard.isSelector ? false : true,
    );
  }, [
    selectedCard?.hash,
    selectedEndpoint?.hash,
    isSelectedEndpointAllowed,
    store.policy.curPolicyKindBuilder,
  ]);

  const editorClassName = classnames(css.editor, {
    [css.unsupportedWarningOpened]: store.policy.hasUnsupportedRules,
  });

  return (
    <div className={css.wrapper}>
      <div className={css.header}>
        <div className={css.left}>
          <div className={css.leftActions}>
            <Tooltip
              minimal
              interactionKind="hover-target"
              content="Create new policy"
            >
              <Button
                minimal
                small
                onClick={onClickCreateNewPolicy}
                icon="document"
                className={css.newYamlButton}
              />
            </Tooltip>
            <Tooltip
              minimal
              interactionKind="hover-target"
              content="Upload policy YAML"
            >
              <Button
                minimal
                small
                onClick={onClickUploadPolicy}
                icon="upload"
                className={css.uploadYamlButton}
              />
            </Tooltip>
          </div>
        </div>
        <div className={css.center}>
          {(store.policy.currentSpecsCount ?? 0) > 1 && (
            <ButtonGroup className={css.specsSelector}>
              <Tooltip minimal content="Go to prev spec">
                <Button minimal onClick={onPrevSpec} icon="arrow-left"></Button>
              </Tooltip>
              <Tooltip minimal content="Go to next spec">
                <Button
                  minimal
                  onClick={onNextSpec}
                  icon="arrow-right"
                ></Button>
              </Tooltip>
            </ButtonGroup>
          )}
          {store.policy.policyKind && (
            <PolicyKindSelector
              curPolicyKind={store.policy.policyKind}
              onChange={store.controls.setPolicyKind}
            />
          )}
        </div>
        <div className={css.right}>
          <Popover
            content={<PolicyRatingPopover />}
            interactionKind="hover"
            popoverClassName={Classes.POPOVER_CONTENT_SIZING}
            minimal
          >
            <div className={css.policyRating}>
              <div
                className={`${css.policyRatingTitle} ${Classes.TOOLTIP_INDICATOR}`}
              >
                Policy Rating
              </div>
              {typeof store.policy.roundedRating === 'number' ? (
                <Rating
                  points={store.policy.roundedRating}
                  hideTooltip={true}
                />
              ) : (
                'n/a'
              )}
            </div>
          </Popover>
          <Button
            rightIcon="download"
            intent="primary"
            className={css.downloadYamlButton}
            onClick={props.onDownloadYaml}
          >
            Download
          </Button>
          <Tooltip content="Share via private GitHub Gist">
            <Button
              rightIcon={'share'}
              intent="primary"
              className={css.shareYamlButton}
              onClick={props.onShareYaml}
            >
              Share
            </Button>
          </Tooltip>
        </div>
      </div>
      {store.policy.hasUnsupportedRules && <PolicyWarning />}
      <div className={editorClassName}>
        <YAMLEditor
          policyYaml={props.policyYaml}
          yamlToHighlight={cardEndpointYaml ?? null}
        />
      </div>
    </div>
  );
});
