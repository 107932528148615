import objectHash from 'object-hash';
import { ToStringOptions as YAMLOptions } from 'yaml';

export interface KV {
  key: string;
  value: string;
}

export interface Dictionary<T> {
  [key: string]: T;
}

export type ResolveType<T> = T extends PromiseLike<infer U> ? U : T;

export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

export type FirstArgument<F extends Function> = F extends (
  a: infer A,
  ...args: any[]
) => any
  ? A
  : never;

export enum StateChange {
  Unknown = 'unknown',
  Added = 'added',
  Modified = 'modified',
  Deleted = 'deleted',
  Exists = 'exists',
}

export const tooSmall = (num: number): boolean => {
  return Math.abs(num) <= Number.EPSILON;
};

export function slug(str?: string | null) {
  if (!str) return '';
  return str
    .replace(/^\s+|\s+$/g, '')
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
}

export function copyToClipboard(text: string) {
  const textarea = document.createElement('textarea');
  textarea.textContent = text;
  textarea.style.position = 'fixed';
  document.body.appendChild(textarea);
  textarea.select();
  try {
    return document.execCommand('copy');
  } catch (ex) {
    console.warn('Copy to clipboard failed.', ex);
    return false;
  } finally {
    document.body.removeChild(textarea);
  }
}

export const setupDebugProp = (obj: object) => {
  if (window.debugTools == null) {
    window.debugTools = {};
  }

  Object.keys(obj).forEach(key => {
    window.debugTools[key] = (obj as any)[key];
  });
};

export const createHash = (val: any) => {
  return objectHash(val, {
    unorderedArrays: true,
    unorderedObjects: true,
    unorderedSets: true,
  });
};

export const YAML_POLICY_STRINGIFY_OPTS: YAMLOptions = {
  indent: 2,
};

export const YAML_POLICY_PARSE_REVIEWER = (key: any, value: any): any => {
  if (key === 'matchLabels' && value === null) return {};
  if (key === 'matchExpressions' && value === null) return {};
  return value;
};

export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!');
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    },
  );
}

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
