import {
  Button,
  Checkbox,
  IButtonProps,
  ICheckboxProps,
  Icon,
} from '@blueprintjs/core';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import {
  PolicyAssistantAction,
  PolicyAssistantEntry,
} from '~/store/stores/assistant/general';
import { PolicyAssistantActionKind } from '~/store/stores/assistant/general';
import { AnalyticsTrackKind, track } from '~/utils/analytics';
import css from './PolicyAssistantCard.scss';

export interface Props {
  entry: PolicyAssistantEntry;
}

export const PolicyAssistantCard = observer(function PolicyAssistantCard(
  props: Props,
) {
  const title = props.entry.title;
  const description = props.entry.description.get();
  return (
    <div className={classnames(css.wrapper)}>
      {title && <span className={css.title}>{title}</span>}
      {description && <div className={css.description}>{description}</div>}
      {props.entry.actions.length > 0 && (
        <div className={css.actions}>
          {props.entry.actions.map(action => (
            <Action key={action.id} entry={props.entry} action={action} />
          ))}
        </div>
      )}
    </div>
  );
});

interface ActionProps {
  entry: PolicyAssistantEntry;
  action: PolicyAssistantAction;
}

const Action = observer(function PolicyAssistantCardAction(props: ActionProps) {
  const active = Boolean(props.action.active.get());
  const disabled = Boolean(props.action.disabled.get());
  const description = props.action.description.get();

  const onClick = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      props.action.onChange();
      track(AnalyticsTrackKind.TutorialAction, {
        step: props.entry.id,
        action: props.action.id,
        element: 'button',
      });
    },
    [props.action, props.action.onChange],
  );

  const onChange = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      props.action.onChange();
      track(AnalyticsTrackKind.TutorialAction, {
        step: props.entry.id,
        action: props.action.id,
        element: 'checkbox',
      });
    },
    [props.action, props.action.onChange],
  );

  let content: JSX.Element | null = null;
  switch (props.action.kind) {
    case PolicyAssistantActionKind.Button:
      content = (
        <div className={css.buttonAction}>
          <div className={css.buttonActionText}>{props.action.title}</div>
          <Button
            intent="success"
            disabled={disabled}
            onClick={onClick}
            className={css.buttonActionButton}
            {...(props.action.props as IButtonProps)}
          >
            {props.action.buttonText}
          </Button>
        </div>
      );
      break;
    case PolicyAssistantActionKind.Checkbox:
      content = (
        <Checkbox
          checked={active}
          disabled={disabled}
          onChange={onChange}
          labelElement={props.action.title}
          {...(props.action.props as ICheckboxProps)}
        ></Checkbox>
      );
      break;
    default:
      break;
  }

  if (!content) return null;

  return (
    <div className={css.actionWrapper}>
      <div className={css.actionElement}>
        <div className={css.actionElementContent}>{content}</div>
        {typeof props.action.rating === 'number' && (
          <>
            {/* <Rating
              points={props.action.rating}
              disabled={!active || disabled}
            /> */}
            {props.action.kind === PolicyAssistantActionKind.Button && (
              <Icon
                icon="tick"
                intent={active ? 'success' : 'none'}
                style={{ opacity: active ? 1 : 0.1, marginLeft: '12px' }}
              />
            )}
          </>
        )}
      </div>
      {description && <div className={css.description}>{description}</div>}
    </div>
  );
});
