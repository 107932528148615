import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';

export function useFileUpload(
  fileTypes?: string[],
  callback?: (text: string) => void,
) {
  const fileReader = useMemo(() => {
    return createFileReader(callback);
  }, [callback]);

  const fileUploader = useMemo(() => {
    return createFileUploader(fileReader.reader, fileTypes);
  }, [fileReader]);

  useEffect(() => {
    return () => {
      fileReader.dispose();
      fileUploader.dispose();
    };
  }, []);

  return useCallback(
    (event?: SyntheticEvent) => {
      event?.preventDefault();
      fileUploader.open();
    },
    [fileUploader],
  );
}

export function createFileReader(callback?: (text: string) => void) {
  const reader = new FileReader();

  const onload = (event: ProgressEvent<FileReader>) => {
    if (typeof event.target?.result !== 'string') return;
    callback?.(event.target.result);
  };
  reader.addEventListener('load', onload);

  const dispose = () => {
    reader.removeEventListener('load', onload);
  };

  return { reader, dispose };
}

export function createFileUploader(reader: FileReader, fileTypes?: string[]) {
  const uploader = document.createElement('input');
  uploader.type = 'file';
  uploader.style.display = 'none';
  if (fileTypes?.length) uploader.accept = fileTypes.join(',');

  const dispose = () => {
    uploader.removeEventListener('change', onchange);
    document.body.removeChild(uploader);
  };

  const onchange = () => {
    const { files } = uploader;
    if (files?.length !== 1) return;
    reader.readAsText(files[0]);
  };
  uploader.addEventListener('change', onchange);
  document.body.appendChild(uploader);

  return { open: () => uploader.click(), dispose };
}

export function downloadTextAsFile(
  filename: string,
  data: string,
  mime: string,
) {
  const element = document.createElement('a');
  element.setAttribute('href', `data:${mime};${data}`);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
