import { useCallback } from 'react';
import { useNotifier } from '~/notifier';
import { useStore } from '~/store';
import { AnalyticsTrackKind, track } from '~/utils/analytics';

export function useGistShare() {
  const store = useStore();
  const notifier = useNotifier();

  return useCallback(async () => {
    if (!store.policy.policyCurrentSpecYaml) return;

    store.controls.setPolicySharingInProgress(true);

    track(AnalyticsTrackKind.StartSharePolicyViaGist);

    return store.user
      .authGithub(() => {
        notifier
          .warning({
            message:
              'Your browser blocked GitHub Authorization popup. Please allow to show this popup in address bar',
            timeout: 7000,
          })
          .show();
      })
      .then(accessToken => {
        const filename = store.policy.policyName ?? 'untitled-policy';
        return fetch(`https://api.github.com/gists`, {
          method: 'post',
          headers: {
            Accept: 'application/vnd.github.v3+json',
            Authorization: `bearer ${accessToken}`,
          },
          body: JSON.stringify({
            public: false,
            files: {
              [`${filename}.yaml`]: {
                content: store.policy.policyCurrentSpecYaml,
              },
            },
          }),
        })
          .then(response => {
            if (response.status === 401 || response.status === 403) {
              store.user.githubAccessToken = null;
              throw new Error('Unauthorized GitHub access');
            }
            if (response.status !== 201) throw response;
            return response.json();
          })
          .then(gist => {
            track(AnalyticsTrackKind.CompleteSharePolicyViaGist);
            return `https://editor.networkpolicy.io?gist=${gist.id}`;
          })
          .catch(error => {
            track(AnalyticsTrackKind.FailedSharePolicyViaGist);
            console.error('GitHub Gist sharing error:', error);
            notifier
              .error({
                message: `Can't share via GitHub Gist. See browser logs for details`,
                timeout: 5000,
              })
              .show();
          })
          .finally(() => store.controls.setPolicySharingInProgress(false));
      })
      .catch(error => {
        track(AnalyticsTrackKind.FailedSharePolicyViaGist);
        console.error('GitHub oauth error:', error);
        store.controls.setPolicySharingInProgress(false);
        notifier
          .error({
            message: `Can't auth via GitHub. See browser logs for details`,
            timeout: 5000,
          })
          .show();
      });
  }, [store.policy.policyName, store.policy.policyCurrentSpecYaml]);
}
