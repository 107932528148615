export const schema = {
  uri: 'https://cimulator.isovalent.com/knp-schema.json',
  fileMatch: ['*'],
  schema: {
    type: 'object',
    $id: 'https://cimulator.isovalent.com/knp-schema.json',
    definitions: {
      IPBlock: {
        properties: {
          cidr: {
            type: 'string',
          },
          except: {
            items: {
              type: 'string',
            },
            type: 'array',
          },
        },
        type: 'object',
      },
      LabelSelector: {
        properties: {
          matchExpressions: {
            items: {
              $ref: '#/definitions/LabelSelectorRequirement',
            },
            type: 'array',
          },
          matchLabels: {
            additionalProperties: {
              type: 'string',
            },
            type: 'object',
          },
        },
        type: 'object',
      },
      LabelSelectorRequirement: {
        properties: {
          key: {
            type: 'string',
          },
          operator: {
            type: 'string',
          },
          values: {
            items: {
              type: 'string',
            },
            type: 'array',
          },
        },
        type: 'object',
      },
      NetworkPolicyEgressRule: {
        properties: {
          ports: {
            items: {
              $ref: '#/definitions/NetworkPolicyPort',
            },
            type: 'array',
          },
          to: {
            items: {
              $ref: '#/definitions/NetworkPolicyPeer',
            },
            type: 'array',
          },
        },
        type: 'object',
      },
      NetworkPolicyIngressRule: {
        properties: {
          from: {
            items: {
              $ref: '#/definitions/NetworkPolicyPeer',
            },
            type: 'array',
          },
          ports: {
            items: {
              $ref: '#/definitions/NetworkPolicyPort',
            },
            type: 'array',
          },
        },
        type: 'object',
      },
      NetworkPolicyPeer: {
        properties: {
          ipBlock: {
            $ref: '#/definitions/IPBlock',
          },
          namespaceSelector: {
            $ref: '#/definitions/LabelSelector',
          },
          podSelector: {
            $ref: '#/definitions/LabelSelector',
          },
        },
        type: 'object',
      },
      NetworkPolicyPort: {
        properties: {
          port: {
            type: 'number',
          },
          protocol: {
            type: 'string',
          },
        },
        type: 'object',
      },
      NetworkPolicySpec: {
        properties: {
          egress: {
            items: {
              $ref: '#/definitions/NetworkPolicyEgressRule',
            },
            type: 'array',
          },
          ingress: {
            items: {
              $ref: '#/definitions/NetworkPolicyIngressRule',
            },
            type: 'array',
          },
          podSelector: {
            $ref: '#/definitions/LabelSelector',
          },
          policyTypes: {
            items: {
              type: 'string',
            },
            type: 'array',
          },
        },
        type: 'object',
      },
    },
    properties: {
      annotations: {
        additionalProperties: {
          type: 'string',
        },
        type: 'object',
      },
      apiVersion: {
        type: 'string',
      },
      kind: {
        type: 'string',
      },
      labels: {
        additionalProperties: {
          type: 'string',
        },
        type: 'object',
      },
      name: {
        type: 'string',
      },
      namespace: {
        type: 'string',
      },
      resourceVersion: {
        type: 'string',
      },
      spec: {
        $ref: '#/definitions/NetworkPolicySpec',
      },
      uid: {
        type: 'string',
      },
    },
  },
};
