import { RuleKind } from '~/components/PolicyCard/general';
import { CardKind, CardSide } from '~/domain/cimulator/types';
import { EventEmitter } from '~/utils/emitter';
import { PolicyAssistantTutorial } from './general';

export enum AssistantEmitterActions {
  OpenCardCrudPopup = 'open-card-crud-popup',
  SelectCardEndpoint = 'select-card-endpoint',
  ClosePopup = 'close-popup',
  ToggleDefaultDenyIngress = 'toggle-default-deny-ingress',
  ToggleDefaultDenyEgress = 'toggle-default-deny-esgress',
  OpenHubblePanel = 'open-hubble-panel',
  OpenTutorial = 'open-tutorial',
}

export type AssistantEmitterHandlers = {
  [AssistantEmitterActions.OpenCardCrudPopup]: (
    cardSide: CardSide,
    cardKind: CardKind,
    ruleKind?: RuleKind,
  ) => void;
  [AssistantEmitterActions.ClosePopup]: () => void;
  [AssistantEmitterActions.ToggleDefaultDenyIngress]: () => void;
  [AssistantEmitterActions.ToggleDefaultDenyEgress]: () => void;
  [AssistantEmitterActions.OpenHubblePanel]: () => void;
  [AssistantEmitterActions.OpenTutorial]: (
    tutorial: PolicyAssistantTutorial,
  ) => void;
};

export const emitter = new EventEmitter<AssistantEmitterHandlers>();
