import { FlowsTableColumnKey } from '~/components/FlowsTable/general';
import { PolicyInfoSnapshot } from '~/store/stores/policy/types';

const LAST_NAMESPACE_KEY = '@hubble-ui/namespace';
const FLOWS_TABLE_VISIBLE_COLUMNS_KEY = '@hubble-ui/flowstable-visible-columns';
const SHOW_HOST_KEY = '@hubble-ui/show-host';
const SHOW_KUBEDNS_KEY = '@hubble-ui/show-kube-dns';
const SHOW_REMOTE_NODE_KEY = '@hubble-ui/show-remote-node';
const SHOW_PROMETHEUS_SERVICE_KEY = '@hubble-ui/show-prometheus-app';
const DETAILS_PANEL_POS = '@hubble-ui/panel-position';
const POLICY_INFO = '@cimulator/policy-yaml';
const LAST_POLICY_ID = '@cimulator/latest-policy-id';
const VISITS_COUNTER = '@cimulator/visits-counters';
const POLICY_IGNORE_FLOW_LABELS = '@cimulator/policy-ignore-flow-labels';
const POLICY_ASSISTANT_IGNORED_IDS = '@cimulator/policy-assistant-ignored-ids';
const SKIP_ONBOARDING_DIALOG = '@cimulator/skip-onboarding-dialog';
const SKIP_DOWNLOAD_POLICY_DIALOG = '@cimulator/skip-download-policy-dialog';
const SKIP_SHARE_POLICY_DIALOG = '@cimulator/skip-share-policy-dialog';
const SKIP_ON_BEFORE_UNLOAD = '@cimulator/skip-on-before-unload';
const GITHUB_ACCESS_TOKEN = '@cimulator/github-access-token';

export function getVisitsCounter(): number {
  const cnt = localStorage.getItem(VISITS_COUNTER);
  if (cnt && /^\d+$/gi.test(cnt)) return +cnt;
  return 0;
}

export function incrementVisitsCounter() {
  const cnt = getVisitsCounter() ?? 0;
  const nextCnt = cnt + 1;
  localStorage.setItem(VISITS_COUNTER, String(nextCnt));
  return nextCnt;
}

export function getGithubAccessToken(): string | null {
  return localStorage.getItem(GITHUB_ACCESS_TOKEN);
}

export function saveGithubAccessToken(access_token: string) {
  localStorage.setItem(GITHUB_ACCESS_TOKEN, access_token);
}

export function getLastNamespace(): string | null {
  return localStorage.getItem(LAST_NAMESPACE_KEY);
}

export function saveLastNamespace(ns: string) {
  localStorage.setItem(LAST_NAMESPACE_KEY, ns);
}

export function deleteLastNamespace() {
  localStorage.removeItem(LAST_NAMESPACE_KEY);
}

export function getSkipOnboardingDialog(): boolean {
  return localStorage.getItem(SKIP_ONBOARDING_DIALOG) === 'yes';
}

export function saveSkipOnboardingDialog(state: boolean) {
  state
    ? localStorage.setItem(SKIP_ONBOARDING_DIALOG, 'yes')
    : deleteSkipOnboardingDialog();
}

export function toggleSkipOnboardingDialog() {
  getSkipOnboardingDialog()
    ? deleteSkipOnboardingDialog()
    : saveSkipOnboardingDialog(true);
}

export function deleteSkipOnboardingDialog() {
  localStorage.removeItem(SKIP_ONBOARDING_DIALOG);
}

export function getSkipDownloadPolicyDialog(): boolean {
  return localStorage.getItem(SKIP_DOWNLOAD_POLICY_DIALOG) === 'yes';
}

export function saveSkipDownloadPolicyDialog(state: boolean) {
  state
    ? localStorage.setItem(SKIP_DOWNLOAD_POLICY_DIALOG, 'yes')
    : deleteSkipDownloadPolicyDialog();
}

export function toggleSkipDownloadPolicyDialog() {
  getSkipDownloadPolicyDialog()
    ? deleteSkipDownloadPolicyDialog()
    : saveSkipDownloadPolicyDialog(true);
}

export function deleteSkipDownloadPolicyDialog() {
  localStorage.removeItem(SKIP_DOWNLOAD_POLICY_DIALOG);
}

export function getSkipSharePolicyDialog(): boolean {
  return localStorage.getItem(SKIP_SHARE_POLICY_DIALOG) === 'yes';
}

export function saveSkipSharePolicyDialog(state: boolean) {
  state
    ? localStorage.setItem(SKIP_SHARE_POLICY_DIALOG, 'yes')
    : deleteSkipSharePolicyDialog();
}

export function toggleSkipSharePolicyDialog() {
  getSkipSharePolicyDialog()
    ? deleteSkipSharePolicyDialog()
    : saveSkipSharePolicyDialog(true);
}

export function deleteSkipSharePolicyDialog() {
  localStorage.removeItem(SKIP_SHARE_POLICY_DIALOG);
}

export function getSkipOnBeforeUnload(): boolean {
  return localStorage.getItem(SKIP_ON_BEFORE_UNLOAD) === 'yes';
}

export function saveSkipOnBeforeUnload(state: boolean) {
  state
    ? localStorage.setItem(SKIP_ON_BEFORE_UNLOAD, 'yes')
    : deleteSkipOnBeforeUnload();
}

export function toggleSkipOnBeforeUnload() {
  getSkipOnBeforeUnload()
    ? deleteSkipOnBeforeUnload()
    : saveSkipOnBeforeUnload(true);
}

export function deleteSkipOnBeforeUnload() {
  localStorage.removeItem(SKIP_ON_BEFORE_UNLOAD);
}

export function getFlowsTableVisibleColumns(): Set<FlowsTableColumnKey> | null {
  const val = localStorage.getItem(FLOWS_TABLE_VISIBLE_COLUMNS_KEY);
  if (!val) return null;
  return new Set<FlowsTableColumnKey>(JSON.parse(val));
}

export function saveFlowsTableVisibleColumns(clmns: Set<FlowsTableColumnKey>) {
  localStorage.setItem(
    FLOWS_TABLE_VISIBLE_COLUMNS_KEY,
    JSON.stringify(Array.from(clmns)),
  );
}

export function getShowHost(): boolean {
  return localStorage.getItem(SHOW_HOST_KEY) === 'true';
}

export function saveShowHost(val: boolean) {
  localStorage.setItem(SHOW_HOST_KEY, val ? 'true' : 'false');
}

export function getShowKubeDns(): boolean {
  return localStorage.getItem(SHOW_KUBEDNS_KEY) === 'true';
}

export function saveShowKubeDns(val: boolean) {
  localStorage.setItem(SHOW_KUBEDNS_KEY, val ? 'true' : 'false');
}

export function getShowRemoteNode(): boolean {
  return localStorage.getItem(SHOW_REMOTE_NODE_KEY) === 'true';
}

export function saveShowRemoteNode(val: boolean) {
  localStorage.setItem(SHOW_REMOTE_NODE_KEY, val ? 'true' : 'false');
}

export function getShowPrometheusApp(): boolean {
  return localStorage.getItem(SHOW_PROMETHEUS_SERVICE_KEY) === 'true';
}

export function saveShowPrometheusApp(val: boolean) {
  localStorage.setItem(SHOW_PROMETHEUS_SERVICE_KEY, val ? 'true' : 'false');
}

export function getDetailsPanelTop(): number | null {
  const num = localStorage.getItem(DETAILS_PANEL_POS);
  if (!num) return null;

  return parseFloat(num);
}

export function setDetailsPanelTop(top: number) {
  localStorage.setItem(DETAILS_PANEL_POS, `${top}`);
}

/**
 * @deprecated we don't store new policies in local storage anymore
 */
export function getPolicyInfo(): PolicyInfoSnapshot | null {
  const str = localStorage.getItem(POLICY_INFO);
  if (str) {
    try {
      return JSON.parse(str) as PolicyInfoSnapshot;
    } catch (error) {
      localStorage.removeItem(POLICY_INFO);
      console.error(error);
      return null;
    }
  }
  return null;
}

/**
 * @deprecated we don't store new policies in local storage anymore
 */
export function savePolicyInfo(info: PolicyInfoSnapshot) {
  localStorage.setItem(POLICY_INFO, JSON.stringify(info));
}

/**
 * @deprecated we don't store new policies in local storage anymore
 */
export function deletePolicyInfo() {
  localStorage.removeItem(POLICY_INFO);
}

export function getLastPolicyId(): string | null {
  return localStorage.getItem(LAST_POLICY_ID) ?? null;
}

export function saveLastPolicyId(id: string) {
  localStorage.setItem(LAST_POLICY_ID, id);
}

export function getIgnoreFlowLabels(defaults = new Set<string>()): Set<string> {
  const str = localStorage.getItem(POLICY_IGNORE_FLOW_LABELS);
  if (typeof str !== 'string') return defaults;
  const list = str
    .split(',')
    .map(s => s.trim())
    .filter(s => s.length > 0);
  return new Set(list);
}

export function saveIgnoreFlowLabels(set: Set<string>) {
  localStorage.setItem(POLICY_IGNORE_FLOW_LABELS, Array.from(set).join(','));
}

export function deleteIgnoreFlowLabels() {
  localStorage.removeItem(POLICY_IGNORE_FLOW_LABELS);
}

export function getIgnoredPolicyAssistantEntries(): string[] {
  const ids = localStorage.getItem(POLICY_ASSISTANT_IGNORED_IDS);
  if (!ids) return [];
  return ids
    .split(',')
    .map(id => id.trim())
    .filter(id => id.length > 0);
}

export function setIgnoredPolicyAssistantEntries(ids: string[]) {
  localStorage.setItem(POLICY_ASSISTANT_IGNORED_IDS, ids.join(','));
}
