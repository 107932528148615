import { Alert } from '@blueprintjs/core';
import React, { memo } from 'react';
import { TrafficDirection } from '~/domain/cimulator/types';

export interface Props {
  dir: TrafficDirection;
  isOpen: boolean;
  containerRef?: HTMLElement;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const RemoveIngressEgressDialog = memo<Props>(
  function RemoveIngressEgressDialog(props) {
    return (
      <Alert
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
        isOpen={props.isOpen}
        confirmButtonText={`Remove all ${props.dir} rules`}
        cancelButtonText="Cancel"
        intent="danger"
        icon="warning-sign"
        onCancel={props.onCancel}
        onConfirm={props.onConfirm}
        portalContainer={props.containerRef}
      >
        <p>
          All <b>{props.dir}</b> rules will be removed and all traffic on{' '}
          <b>{props.dir}</b> will be allowed
        </p>
      </Alert>
    );
  },
);
