import { ITagInputProps, TagInput } from '@blueprintjs/core';
import React, { memo } from 'react';

import crudcss from './CrudPopover.scss';

export type Props = ITagInputProps;

export const MatchLabelsInput = memo(function MatchLabelsInput(props: Props) {
  return (
    <label className={crudcss.section}>
      <TagInput fill addOnBlur addOnPaste {...props} />
    </label>
  );
});
