import { makeAutoObservable, reaction } from 'mobx';
import { customAlphabet } from 'nanoid';
import { PolicyCard } from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import { PolicyKind } from '~/domain/cimulator/types';
import { Flow } from '~/domain/flows';
import { QueryParams, URLUtils } from '~/domain/url';
import * as storage from '~/storage/local';

export default class ControlStore {
  selectedTableFlow: Flow | null = null;
  policyKind: PolicyKind = PolicyKind.KNP;
  policyName: string | null = null;
  policyNamespace: string | null = null;
  tutorial = 'main';
  selectedCardId: string | null = null;
  selectedEndpointId: string | null = null;
  policyId: string | null = null;
  skipOnboardingDialog = false;
  skipDownloadPolicyDialog = false;
  skipOnBeforeUnload = false;
  policySharingInProgress = false;

  static policyIdGenerator = customAlphabet(
    '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    16,
  );

  constructor() {
    makeAutoObservable(this);

    this.skipOnboardingDialog = storage.getSkipOnboardingDialog();
    reaction(() => this.skipOnboardingDialog, storage.saveSkipOnboardingDialog);

    this.skipDownloadPolicyDialog = storage.getSkipDownloadPolicyDialog();
    reaction(
      () => this.skipDownloadPolicyDialog,
      storage.saveSkipDownloadPolicyDialog,
    );

    this.skipOnBeforeUnload = storage.getSkipOnBeforeUnload();
    reaction(() => this.skipOnBeforeUnload, storage.saveSkipOnBeforeUnload);

    this.policyId =
      URLUtils.getQueryParam(QueryParams.PolicyId) ?? storage.getLastPolicyId();

    reaction(
      () => this.policyId,
      policyId => {
        if (policyId) {
          URLUtils.setQueryParam(QueryParams.PolicyId, policyId);
          storage.saveLastPolicyId(policyId);
        }
      },
    );

    this.initTutorial();
  }

  setPolicySharingInProgress = (state: boolean) => {
    this.policySharingInProgress = state;
  };

  setPolicyNamespace = (ns: string | null) => {
    this.policyNamespace = ns;
  };

  selectTableFlow = (flow: Flow | null) => {
    this.selectedTableFlow = flow;
  };

  setPolicyKind = (kind: PolicyKind) => {
    this.policyKind = kind;
  };

  setPolicyName = (policyName: string | null) => {
    this.policyName = policyName;
  };

  setTutorial = (tutorial: string) => {
    this.tutorial = tutorial;
  };

  selectCardId = (cardId: string | null) => {
    this.selectedCardId = cardId;
  };

  selectEndpointId = (endpointId: string | null) => {
    this.selectedEndpointId = endpointId;
  };

  selectElement = (card?: PolicyCard, endpoint?: PolicyEndpoint) => {
    if (!card) {
      return this.selectCardId(null), this.selectEndpointId(null);
    }
    if (card.isSelector) {
      return this.selectCardId(card.id), this.selectEndpointId(null);
    }
    if (endpoint) {
      return this.selectCardId(card.id), this.selectEndpointId(endpoint.id);
    }
    return this.selectCardId(null), this.selectEndpointId(null);
  };

  setPolicyId = (id: string) => {
    this.policyId = id;
  };

  reset = () => {
    this.tutorial = 'main';
    this.policyNamespace = null;
    this.selectedTableFlow = null;
    this.policyKind = PolicyKind.CNP;
    this.selectedCardId = null;
    this.selectedEndpointId = null;
  };

  private initTutorial() {
    const url = URLUtils.getQueryParam(QueryParams.PolicyTutorial);
    if (typeof url === 'string') {
      this.tutorial = url;
    }
  }
}
