export const schema = {
  uri: 'https://cimulator.isovalent.com/cnp-schema.json',
  fileMatch: ['*'],
  schema: {
    type: 'object',
    $id: 'https://cimulator.isovalent.com/cnp-schema.json',
    definitions: {
      AWSGroup: {
        properties: {
          labels: {
            additionalProperties: {
              type: 'string',
            },
            type: 'object',
          },
          region: {
            type: 'string',
          },
          securityGroupsIds: {
            items: {
              type: 'string',
            },
            type: 'array',
          },
          securityGroupsNames: {
            items: {
              type: 'string',
            },
            type: 'array',
          },
        },
        type: 'object',
      },
      ApimachineryTime: {
        properties: {
          Time: {
            description:
              'Enables basic storage and retrieval of dates and times.',
            format: 'date-time',
            type: 'string',
          },
        },
        type: 'object',
      },
      CIDRRule: {
        properties: {
          cidr: {
            type: 'string',
          },
          except: {
            items: {
              type: 'string',
            },
            type: 'array',
          },
        },
        type: 'object',
      },
      CiliumNetworkPolicyNodeStatus: {
        properties: {
          annotations: {
            additionalProperties: {
              type: 'string',
            },
            type: 'object',
          },
          enforcing: {
            type: 'boolean',
          },
          error: {
            type: 'string',
          },
          lastUpdated: {
            properties: {},
            type: 'object',
          },
          localPolicyRevision: {
            type: 'number',
          },
          ok: {
            type: 'boolean',
          },
        },
        type: 'object',
      },
      CiliumNetworkPolicyStatus: {
        properties: {
          derivativePolicies: {
            additionalProperties: {
              $ref: '#/definitions/CiliumNetworkPolicyNodeStatus',
            },
            type: 'object',
          },
          nodes: {
            additionalProperties: {
              $ref: '#/definitions/CiliumNetworkPolicyNodeStatus',
            },
            type: 'object',
          },
        },
        type: 'object',
      },
      EgressRule: {
        properties: {
          toCIDR: {
            items: {
              type: 'string',
            },
            type: 'array',
          },
          toCIDRSet: {
            items: {
              $ref: '#/definitions/CIDRRule',
            },
            type: 'array',
          },
          toEndpoints: {
            items: {
              $ref: '#/definitions/EndpointSelector',
            },
            type: 'array',
          },
          toEntities: {
            items: {
              type: 'string',
            },
            type: 'array',
          },
          toFQDNs: {
            items: {
              $ref: '#/definitions/FQDNSelector',
            },
            type: 'array',
          },
          toGroups: {
            items: {
              $ref: '#/definitions/ToGroups',
            },
            type: 'array',
          },
          toPorts: {
            items: {
              $ref: '#/definitions/PortRule',
            },
            type: 'array',
          },
          toRequires: {
            items: {
              $ref: '#/definitions/EndpointSelector',
            },
            type: 'array',
          },
          toServices: {
            items: {
              $ref: '#/definitions/Service',
            },
            type: 'array',
          },
        },
        type: 'object',
      },
      EndpointSelector: {
        properties: {
          matchExpressions: {
            items: {
              $ref: '#/definitions/LabelSelectorRequirement',
            },
            type: 'array',
          },
          matchLabels: {
            additionalProperties: {
              type: 'string',
            },
            type: 'object',
          },
        },
        type: 'object',
      },
      FQDNSelector: {
        properties: {
          matchName: {
            type: 'string',
          },
          matchPattern: {
            type: 'string',
          },
        },
        type: 'object',
      },
      HeaderMatch: {
        properties: {
          mismatch: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          secret: {
            $ref: '#/definitions/Secret',
          },
          value: {
            type: 'string',
          },
        },
        type: 'object',
      },
      IngressRule: {
        properties: {
          fromCIDR: {
            items: {
              type: 'string',
            },
            type: 'array',
          },
          fromCIDRSet: {
            items: {
              $ref: '#/definitions/CIDRRule',
            },
            type: 'array',
          },
          fromEndpoints: {
            items: {
              $ref: '#/definitions/EndpointSelector',
            },
            type: 'array',
          },
          fromEntities: {
            items: {
              type: 'string',
            },
            type: 'array',
          },
          fromRequires: {
            items: {
              $ref: '#/definitions/EndpointSelector',
            },
            type: 'array',
          },
          toPorts: {
            items: {
              $ref: '#/definitions/PortRule',
            },
            type: 'array',
          },
        },
        type: 'object',
      },
      K8SServiceNamespace: {
        properties: {
          namespace: {
            type: 'string',
          },
          serviceName: {
            type: 'string',
          },
        },
        type: 'object',
      },
      K8SServiceSelectorNamespace: {
        properties: {
          namespace: {
            type: 'string',
          },
          selector: {
            $ref: '#/definitions/ServiceSelector',
          },
        },
        type: 'object',
      },
      L7Rules: {
        properties: {
          dns: {
            items: {
              $ref: '#/definitions/PortRuleDNS',
            },
            type: 'array',
          },
          http: {
            items: {
              $ref: '#/definitions/PortRuleHTTP',
            },
            type: 'array',
          },
          kafka: {
            items: {
              $ref: '#/definitions/PortRule',
            },
            type: 'array',
          },
          l7: {
            items: {
              properties: {},
              type: 'object',
            },
            type: 'array',
          },
          l7proto: {
            type: 'string',
          },
        },
        type: 'object',
      },
      Label: {
        properties: {
          key: {
            type: 'string',
          },
          source: {
            type: 'string',
          },
          value: {
            type: 'string',
          },
        },
        type: 'object',
      },
      LabelSelectorRequirement: {
        properties: {
          key: {
            type: 'string',
          },
          operator: {
            type: 'string',
          },
          values: {
            items: {
              type: 'string',
            },
            type: 'array',
          },
        },
        type: 'object',
      },
      ManagedFieldsEntry: {
        properties: {
          apiVersion: {
            type: 'string',
          },
          fieldsType: {
            type: 'string',
          },
          fieldsV1: {
            properties: {},
            type: 'object',
          },
          manager: {
            type: 'string',
          },
          operation: {
            type: 'string',
          },
          time: {
            $ref: '#/definitions/ApimachineryTime',
          },
        },
        type: 'object',
      },
      OwnerReference: {
        properties: {
          apiVersion: {
            type: 'string',
          },
          blockOwnerDeletion: {
            type: 'boolean',
          },
          controller: {
            type: 'boolean',
          },
          kind: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          uid: {
            type: 'string',
          },
        },
        type: 'object',
      },
      PortProtocol: {
        properties: {
          port: {
            type: ['integer', 'string'],
          },
          protocol: {
            type: 'string',
          },
        },
        type: 'object',
      },
      PortRule: {
        properties: {
          apiKey: {
            type: 'string',
          },
          apiVersion: {
            type: 'string',
          },
          clientID: {
            type: 'string',
          },
          originatingTLS: {
            $ref: '#/definitions/TLSContext',
          },
          ports: {
            items: {
              $ref: '#/definitions/PortProtocol',
            },
            type: 'array',
          },
          role: {
            type: 'string',
          },
          rules: {
            $ref: '#/definitions/L7Rules',
          },
          terminatingTLS: {
            $ref: '#/definitions/TLSContext',
          },
          topic: {
            type: 'string',
          },
        },
        type: 'object',
      },
      PortRuleDNS: {
        properties: {
          matchName: {
            type: 'string',
          },
          matchPattern: {
            type: 'string',
          },
        },
        type: 'object',
      },
      PortRuleHTTP: {
        properties: {
          headerMatches: {
            items: {
              $ref: '#/definitions/HeaderMatch',
            },
            type: 'array',
          },
          headers: {
            items: {
              type: 'string',
            },
            type: 'array',
          },
          host: {
            type: 'string',
          },
          method: {
            type: 'string',
          },
          path: {
            type: 'string',
          },
        },
        type: 'object',
      },
      Rule: {
        properties: {
          description: {
            type: 'string',
          },
          egress: {
            items: {
              $ref: '#/definitions/EgressRule',
            },
            type: 'array',
          },
          endpointSelector: {
            $ref: '#/definitions/EndpointSelector',
          },
          ingress: {
            items: {
              $ref: '#/definitions/IngressRule',
            },
            type: 'array',
          },
          labels: {
            items: {
              $ref: '#/definitions/Label',
            },
            type: 'array',
          },
          nodeSelector: {
            $ref: '#/definitions/EndpointSelector',
          },
        },
        type: 'object',
      },
      Secret: {
        properties: {
          name: {
            type: 'string',
          },
          namespace: {
            type: 'string',
          },
        },
        type: 'object',
      },
      Service: {
        properties: {
          k8sService: {
            $ref: '#/definitions/K8SServiceNamespace',
          },
          k8sServiceSelector: {
            $ref: '#/definitions/K8SServiceSelectorNamespace',
          },
        },
        type: 'object',
      },
      ServiceSelector: {
        properties: {
          matchExpressions: {
            items: {
              $ref: '#/definitions/LabelSelectorRequirement',
            },
            type: 'array',
          },
          matchLabels: {
            additionalProperties: {
              type: 'string',
            },
            type: 'object',
          },
        },
        type: 'object',
      },
      TLSContext: {
        properties: {
          certificate: {
            type: 'string',
          },
          privateKey: {
            type: 'string',
          },
          secret: {
            $ref: '#/definitions/Secret',
          },
          trustedCA: {
            type: 'string',
          },
        },
        type: 'object',
      },
      ToGroups: {
        properties: {
          aws: {
            $ref: '#/definitions/AWSGroup',
          },
        },
        type: 'object',
      },
    },
    properties: {
      annotations: {
        additionalProperties: {
          type: 'string',
        },
        type: 'object',
      },
      apiVersion: {
        type: 'string',
      },
      clusterName: {
        type: 'string',
      },
      creationTimestamp: {
        properties: {},
        type: 'object',
      },
      deletionGracePeriodSeconds: {
        type: 'number',
      },
      deletionTimestamp: {
        properties: {},
        type: 'object',
      },
      finalizers: {
        items: {
          type: 'string',
        },
        type: 'array',
      },
      generateName: {
        type: 'string',
      },
      generation: {
        type: 'number',
      },
      kind: {
        type: 'string',
      },
      labels: {
        additionalProperties: {
          type: 'string',
        },
        type: 'object',
      },
      managedFields: {
        items: {
          $ref: '#/definitions/ManagedFieldsEntry',
        },
        type: 'array',
      },
      name: {
        type: 'string',
      },
      namespace: {
        type: 'string',
      },
      ownerReferences: {
        items: {
          $ref: '#/definitions/OwnerReference',
        },
        type: 'array',
      },
      resourceVersion: {
        type: 'string',
      },
      selfLink: {
        type: 'string',
      },
      spec: {
        $ref: '#/definitions/Rule',
      },
      specs: {
        items: {
          $ref: '#/definitions/Rule',
        },
        type: 'array',
      },
      status: {
        $ref: '#/definitions/CiliumNetworkPolicyStatus',
      },
      uid: {
        type: 'string',
      },
    },
  },
};
