import { makeAutoObservable, runInAction } from 'mobx';
import ControlStore from '../controls';
import { PolicyStore } from '../policy';
import { PolicyAssistantTutorial, TUTORIALS_REPO } from './general';
import { PolicyAssistantMainTutorial } from './tutorials/main';
import { PolicyAssistantMarkdownTutorial } from './tutorials/markdown';

export class PolicyAssistantStore {
  fetchingTutorials = false;

  private _controls: ControlStore;
  private _tutorials: PolicyAssistantTutorial[];

  constructor(controls: ControlStore, policy: PolicyStore) {
    makeAutoObservable(this);

    this._controls = controls;
    this._tutorials = [new PolicyAssistantMainTutorial(policy)];
  }

  get tutorials() {
    return this._tutorials.slice();
  }

  get currentTutorial() {
    return this._tutorials.find(t => t.id === this._controls.tutorial) ?? null;
  }

  startTutorialsFetch = () => {
    this.fetchingTutorials = true;
  };

  stopTutorialsFetch = () => {
    this.fetchingTutorials = false;
  };

  fetchTutorialsList = async () => {
    this.startTutorialsFetch();
    const metadata = await this.fetchContentFromGithub(
      `/repos/${TUTORIALS_REPO}/contents/tutorials/metadata.json`,
    );
    JSON.parse(metadata).tutorials.forEach((item: any) => {
      const tutorial = new PolicyAssistantMarkdownTutorial({
        ...{ id: item.id, title: item.title, markdown: null },
        ...(item.yaml ? { yaml: null } : {}),
      });
      runInAction(() => this._tutorials.push(tutorial));
    });
    this.stopTutorialsFetch();
  };

  fetchTutorialMarkdown = async (tutorial: PolicyAssistantMarkdownTutorial) => {
    return this.fetchContentFromGithub(
      `/repos/${TUTORIALS_REPO}/contents/tutorials/${tutorial.id}.md`,
    ).then(md => {
      return runInAction(() => (tutorial.markdown = md));
    });
  };

  fetchTutorialYaml = async (tutorial: PolicyAssistantMarkdownTutorial) => {
    try {
      this.startTutorialsFetch();
      return this.fetchContentFromGithub(
        `/repos/${TUTORIALS_REPO}/contents/tutorials/${tutorial.id}.yaml`,
      ).then(yaml => {
        return runInAction(() => (tutorial.yaml = yaml));
      });
    } finally {
      this.stopTutorialsFetch();
    }
  };

  private fetchContentFromGithub = async (url: string) => {
    return fetch(`https://api.github.com${url}`, {
      headers: { Accept: 'application/vnd.github.v3+json' },
    })
      .then(response => {
        if (response.status !== 200) {
          console.error(response);
          throw new Error("Can't fetch content for tutorial");
        }
        return response.json();
      })
      .then(json => atob(json.content));
  };
}
