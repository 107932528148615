import { makeAutoObservable, reaction } from 'mobx';
import { EndpointSelector } from '~/domain/cilium/cnp/types';
import { PolicyCard } from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import {
  CardKind,
  CardSide,
  CardsMap,
  DefaultDenyKind,
  PolicyKind,
  IneffectiveRuleKind,
} from '~/domain/cimulator/types';
import ControlStore from '../controls';
import {
  CardsStore,
  EgressCardsStore,
  IngressCardsStore,
  RatingPoints,
  RatingRuleKind,
  RuleStatusInfo,
  RuleStatusKind,
} from './types';

export class SpecStore {
  private _controls: ControlStore;

  private _cards: Map<string, PolicyCard>;
  private _ingressCards: IngressCardsStore;
  private _egressCards: EgressCardsStore;

  private _defaultDenyIngress: DefaultDenyKind | null;
  private _defaultDenyEgress: DefaultDenyKind | null;

  private _originPolicyKind: PolicyKind;
  private _unspprtdIngress: any[];
  private _unspprtdEgress: any[];

  private _allowedEndpoints: Set<string>;

  constructor({
    controls,
    originPolicyKind,
    cards,
    defaultDenyIngress,
    defaultDenyEgress,
    unspprtdIngress,
    unspprtdEgress,
    autoStartCardReactions,
  }: {
    controls: ControlStore;
    originPolicyKind: PolicyKind;
    cards: CardsMap;
    defaultDenyIngress: DefaultDenyKind | null;
    defaultDenyEgress: DefaultDenyKind | null;
    unspprtdIngress: any[];
    unspprtdEgress: any[];
    autoStartCardReactions: boolean;
  }) {
    makeAutoObservable(this, {
      getCardBy: false,
      isVisibleCard: false,
      isAllowedEndpoint: false,
      getRuleStatusInfo: false,
    });

    this._controls = controls;

    this._originPolicyKind = originPolicyKind;

    this._cards = cards;
    this._ingressCards = this.createCardStore(CardSide.Ingress, [
      CardKind.All,
      CardKind.OutsideCluster,
      CardKind.InNamespace,
      CardKind.InCluster,
    ]);
    this._egressCards = this.createCardStore(CardSide.Egress, [
      CardKind.All,
      CardKind.OutsideCluster,
      CardKind.InNamespace,
      CardKind.InCluster,
    ]);
    if (autoStartCardReactions) this.startCardsReactions();

    this._defaultDenyIngress = defaultDenyIngress;
    this._defaultDenyEgress = defaultDenyEgress;

    this._unspprtdIngress = unspprtdIngress;
    this._unspprtdEgress = unspprtdEgress;

    this._allowedEndpoints = new Set();
  }

  /* PUBLIC GETTERS */
  get policyKind() {
    return this._controls.policyKind;
  }

  get originPolicyKind() {
    return this._originPolicyKind;
  }

  get specPodSelector() {
    return this.selectorCard.podSelector;
  }

  get defaultDenyIngress() {
    return this._defaultDenyIngress;
  }

  get defaultDenyEgress() {
    return this._defaultDenyEgress;
  }

  get unspprtdIngress() {
    return this._unspprtdIngress;
  }

  get unspprtdEgress() {
    return this._unspprtdEgress;
  }

  get hasIngressRules() {
    return Boolean(this.defaultDenyIngress) || this.hasSomeIngress;
  }

  get ratingStates(): { [key in RatingRuleKind]: boolean } {
    return {
      [RatingRuleKind['Ingress default deny is enabled']]: Boolean(
        this.defaultDenyIngress,
      ),
      [RatingRuleKind['Ingress is in default allow']]: !this.hasIngressRules,
      [RatingRuleKind['Egress default deny is enabled']]: Boolean(
        this.defaultDenyEgress,
      ),
      [RatingRuleKind['Egress is in default allow']]: !this.hasEgressRules,
      [RatingRuleKind['Egress outside cluster is default allow']]: this
        .hasFullEgressOutsideCluster,
      [RatingRuleKind['Egress outside cluster']]: this
        .hasFullEgressOutsideCluster,
      [RatingRuleKind['Egress outside cluster to specific ports']]: this
        .hasEgressOutsideClusterToSpecificPorts,
      [RatingRuleKind['Egress outside cluster to CIDR']]: this
        .hasEgressCidrOutsideCluster,
      [RatingRuleKind['Egress outside cluster to FQDN']]: this
        .hasEgressFqdnOutsideCluster,
      [RatingRuleKind['Ingress from outside cluster']]: this
        .hasIngressFromOutside,
      [RatingRuleKind['Ingress from outside cluster to specific ports']]: this
        .hasIngressFromOutsideToSpecificPorts,
      [RatingRuleKind['Allow within namespace for ingress']]: this
        .hasFullIngressInNamespace,
      [RatingRuleKind['Allow within namespace for egress']]: this
        .hasFullEgressInNamespace,
      [RatingRuleKind[
        'Allow within namespace for pod selector and no rule to allow within namespace for ingress'
      ]]:
        this.hasSomeIngressSelectorsInNamespace &&
        !this.hasFullIngressInNamespace,
      [RatingRuleKind[
        'Allow within namespace for pod selector and no rule to allow within namespace for egress'
      ]]:
        this.hasSomeEgressSelectorsInNamespace &&
        !this.hasFullEgressInNamespace,
      [RatingRuleKind['Allow within cluster for ingress']]: this
        .hasFullIngressInCluster,
      [RatingRuleKind['Allow within cluster for egress']]: this
        .hasFullEgressInCluster,
    };
  }

  get actualPoints() {
    const points: Array<[RatingRuleKind, number]> = [];
    Object.entries(this.ratingStates).forEach(([key, state]) => {
      if (!state) return;
      const kind = key as RatingRuleKind;
      points.push([kind, RatingPoints[kind]]);
    });
    return points;
  }

  get policyAvgRating() {
    const points: { [key in string]: number } = {};
    Object.entries(this.ratingStates).forEach(([kind, state]) => {
      if (!state) return;
      points[kind] = RatingPoints[kind as RatingRuleKind];
    });
    const cnt = Object.keys(points).length;
    if (cnt === 0) return null;
    const total = Object.values(points).reduce((res, val) => res + val, 0);
    return Number((total / cnt).toFixed(2));
  }

  get roundedRating() {
    return typeof this.policyAvgRating === 'number'
      ? Math.round(this.policyAvgRating)
      : null;
  }

  get selectorCard() {
    return this._cards.get(
      PolicyCard.buildId(CardSide.Selector, CardKind.InNamespace),
    ) as PolicyCard;
  }

  get egressAllCard() {
    return this.getCardBy(CardSide.Egress, CardKind.All) as PolicyCard;
  }

  get egressOutsideClusterCard() {
    return this.getCardBy(
      CardSide.Egress,
      CardKind.OutsideCluster,
    ) as PolicyCard;
  }

  get egressInNamespaceCard() {
    return this.getCardBy(CardSide.Egress, CardKind.InNamespace) as PolicyCard;
  }

  get egressInClusterCard() {
    return this.getCardBy(CardSide.Egress, CardKind.InCluster) as PolicyCard;
  }

  get ingressAllCard() {
    return this.getCardBy(CardSide.Ingress, CardKind.All) as PolicyCard;
  }

  get ingressOutsideClusterCard() {
    return this.getCardBy(
      CardSide.Ingress,
      CardKind.OutsideCluster,
    ) as PolicyCard;
  }

  get ingressInNamespaceCard() {
    return this.getCardBy(CardSide.Ingress, CardKind.InNamespace) as PolicyCard;
  }

  get ingressInClusterCard() {
    return this.getCardBy(CardSide.Ingress, CardKind.InCluster) as PolicyCard;
  }

  get allEndpointsList() {
    const endpoints: PolicyEndpoint[] = [];
    this.cardsList.forEach(card => {
      card.endpointsList.forEach(endpoint => {
        endpoints.push(endpoint);
      });
    });
    return endpoints;
  }

  get hasUnsupportedOriginRules() {
    return (
      Boolean(this._unspprtdIngress.length) ||
      Boolean(this._unspprtdEgress.length)
    );
  }

  get hasUnsupportedEndpoints() {
    return this.cardsList.some(card => {
      return card.endpointsList.some(endpoint => {
        return (
          card.getUnsupportedReasonInfo(this.policyKind, endpoint) &&
          this.isAllowedEndpoint(card.fullEndpointId(endpoint.id))
        );
      });
    });
  }

  get hasUnsupportedRules() {
    return this.hasUnsupportedOriginRules || this.hasUnsupportedEndpoints;
  }

  get hasSomeRules() {
    return (
      this._allowedEndpoints.size > 0 ||
      this.defaultDenyEgress ||
      this.defaultDenyIngress
    );
  }

  get hasSomeIngress() {
    return this._ingressCards.list.some(card =>
      card.endpointsList.some(endpoint =>
        this._allowedEndpoints.has(card.fullEndpointId(endpoint.id)),
      ),
    );
  }

  get hasSomeEgress() {
    return this._egressCards.list.some(card =>
      card.endpointsList.some(endpoint =>
        this._allowedEndpoints.has(card.fullEndpointId(endpoint.id)),
      ),
    );
  }

  get hasFullEgressOutsideCluster() {
    return this.egressOutsideClusterCard.endpointsList.some(endpoint => {
      return (
        endpoint.isAllWithoutPorts &&
        this._allowedEndpoints.has(
          this.egressOutsideClusterCard.fullEndpointId(endpoint.id),
        )
      );
    });
  }

  get hasFullEgressInNamespace() {
    return this.egressInNamespaceCard.endpointsList.some(endpoint => {
      return (
        endpoint.isAllWithoutPorts &&
        this._allowedEndpoints.has(
          this.egressInNamespaceCard.fullEndpointId(endpoint.id),
        )
      );
    });
  }

  get hasFullEgressInCluster() {
    return this.egressInClusterCard.endpointsList.some(endpoint => {
      return (
        endpoint.isAllWithoutPorts &&
        this._allowedEndpoints.has(
          this.egressInClusterCard.fullEndpointId(endpoint.id),
        )
      );
    });
  }

  get hasSomeEgressInNamespace() {
    return this.egressInNamespaceCard.endpointsList.some(endpoint => {
      return this._allowedEndpoints.has(
        this.egressInNamespaceCard.fullEndpointId(endpoint.id),
      );
    });
  }

  get hasSomeEgressSelectorsInNamespace() {
    return this.egressInNamespaceCard.endpointsList.some(endpoint => {
      return (
        endpoint.isSelector &&
        this._allowedEndpoints.has(
          this.egressInNamespaceCard.fullEndpointId(endpoint.id),
        )
      );
    });
  }

  get hasSomeEgressInCluster() {
    return (
      this.hasSomeEgressInNamespace ||
      this.egressInClusterCard.endpointsList.some(endpoint => {
        if (endpoint.isKubeDns) return false;
        return this._allowedEndpoints.has(
          this.egressInClusterCard.fullEndpointId(endpoint.id),
        );
      })
    );
  }

  get hasSomeEgressSelectorsInCluster() {
    return (
      this.hasSomeEgressSelectorsInNamespace ||
      this.egressInClusterCard.endpointsList.some(endpoint => {
        if (endpoint.isKubeDns) return false;
        return (
          endpoint.isSelector &&
          this._allowedEndpoints.has(
            this.egressInClusterCard.fullEndpointId(endpoint.id),
          )
        );
      })
    );
  }

  get hasIngressFromOutside() {
    return this.ingressOutsideClusterCard.endpointsList.some(endpoint => {
      return (
        endpoint.isAll &&
        this._allowedEndpoints.has(
          this.ingressOutsideClusterCard.fullEndpointId(endpoint.id),
        )
      );
    });
  }

  get hasIngressFromOutsideToSpecificPorts() {
    return this.ingressOutsideClusterCard.endpointsList.some(endpoint => {
      return (
        endpoint.isAllWithPorts &&
        this._allowedEndpoints.has(
          this.ingressOutsideClusterCard.fullEndpointId(endpoint.id),
        )
      );
    });
  }

  get hasFullIngressInCluster() {
    return this.ingressInClusterCard.endpointsList.some(endpoint => {
      return (
        endpoint.isAllWithoutPorts &&
        this._allowedEndpoints.has(
          this.ingressInClusterCard.fullEndpointId(endpoint.id),
        )
      );
    });
  }

  get hasFullIngressInNamespace() {
    return this.ingressInNamespaceCard.endpointsList.some(endpoint => {
      return (
        endpoint.isAllWithoutPorts &&
        this._allowedEndpoints.has(
          this.ingressInNamespaceCard.fullEndpointId(endpoint.id),
        )
      );
    });
  }

  get hasSomeIngressInNamespace() {
    return this.ingressInNamespaceCard.endpointsList.some(endpoint => {
      return this._allowedEndpoints.has(
        this.ingressInNamespaceCard.fullEndpointId(endpoint.id),
      );
    });
  }

  get hasSomeIngressSelectorsInNamespace() {
    return this.ingressInNamespaceCard.endpointsList.some(endpoint => {
      return (
        endpoint.isSelector &&
        this._allowedEndpoints.has(
          this.ingressInNamespaceCard.fullEndpointId(endpoint.id),
        )
      );
    });
  }

  get hasSomeIngressInCluster() {
    return (
      this.hasSomeIngressInNamespace ||
      this.ingressInClusterCard.endpointsList.some(endpoint => {
        return this._allowedEndpoints.has(
          this.ingressInClusterCard.fullEndpointId(endpoint.id),
        );
      })
    );
  }

  get hasSomeIngressSelectorsInCluster() {
    return (
      this.hasSomeIngressSelectorsInNamespace ||
      this.ingressInClusterCard.endpointsList.some(endpoint => {
        return (
          endpoint.isSelector &&
          this._allowedEndpoints.has(
            this.ingressInClusterCard.fullEndpointId(endpoint.id),
          )
        );
      })
    );
  }

  get hasEgressRules() {
    return Boolean(this._defaultDenyEgress) || this.hasSomeEgress;
  }

  get hasEgressOutsideClusterToSpecificPorts() {
    return this.egressOutsideClusterCard.endpointsList.some(endpoint => {
      return (
        endpoint.isAllWithPorts &&
        this._allowedEndpoints.has(
          this.egressOutsideClusterCard.fullEndpointId(endpoint.id),
        )
      );
    });
  }

  get hasEgressCidrOutsideCluster() {
    return this.egressOutsideClusterCard.endpointsList.some(
      endpoint => endpoint.isCIDR,
    );
  }

  get hasEgressFqdnOutsideCluster() {
    return this.egressOutsideClusterCard.endpointsList.some(
      endpoint => endpoint.isFQDN,
    );
  }

  get isKubeDnsAllowed(): boolean {
    if (!this.kubeDnsEndpoint) return false;
    return this.isAllowedEndpoint(
      this.egressInClusterCard.fullEndpointId(this.kubeDnsEndpoint.id),
    );
  }

  get kubeDnsEndpoint(): PolicyEndpoint | null {
    return (
      this.egressInClusterCard.endpointsList.find(
        endpoint => endpoint.isKubeDns,
      ) ?? null
    );
  }

  get isDNSProxyEnabled(): boolean {
    return (
      Boolean(this.kubeDnsEndpoint?.isDNSProxyEnabled) && this.isKubeDnsAllowed
    );
  }

  get allCardsMap() {
    return new Map(this._cards);
  }

  get cardsMap() {
    return {
      selector: this.selectorCard,
      ingress: this._ingressCards,
      egress: this._egressCards,
    };
  }

  get cardsList() {
    return [
      this.selectorCard,
      ...this._ingressCards.list,
      ...this._egressCards.list,
    ];
  }

  get visibleCardsList() {
    return this.cardsList.filter(this.isVisibleCard);
  }

  get allowedEndpointsSet(): Set<string> {
    return new Set(this._allowedEndpoints);
  }

  /* PUBLIC ACTIONS */
  isVisibleCard = (card: PolicyCard) => {
    if (!card.isAll) return true;
    return card.endpointsList.some(endpoint => {
      return this.isAllowedEndpoint(card.fullEndpointId(endpoint.id));
    });
  };

  startCardsReactions = () => {
    const disposers: { [key: string]: () => void } = {};
    const createRaction = (card: PolicyCard) => {
      disposers[card.id] = reaction(
        () => card.hash,
        () => {
          disposers[card.id]?.();
          const cloned = card.clone();
          this._cards.set(cloned.id, cloned);
          createRaction(cloned);
        },
      );
    };
    this._cards.forEach(createRaction);
    return this;
  };

  setSpecPodSelector = (selector: EndpointSelector) => {
    this.selectorCard.setPodSelector(selector);
  };

  getCardBy = (cardSide: CardSide, cardKind: CardKind): PolicyCard | null => {
    return this._cards.get(PolicyCard.buildId(cardSide, cardKind)) ?? null;
  };

  setCard = (card: PolicyCard) => {
    this._cards.set(card.id, card);
  };

  setAllowedEndpoint = (endpointId: string, state: boolean) => {
    if (state) {
      this._allowedEndpoints.add(endpointId);
    } else {
      this._allowedEndpoints.delete(endpointId);
      this.onDenyReaction(endpointId);
    }
  };

  allowFullInNamespaceEgress = () => {
    const card = this.egressInNamespaceCard;
    let endpoint = card.endpointsList.find(e => e.isAllWithoutPorts);
    if (!endpoint) {
      endpoint = PolicyEndpoint.newAll();
      card.addEndpoints(endpoint);
    }
    this._allowedEndpoints.add(card.fullEndpointId(endpoint.id));
  };

  denyFullInNamespaceEgress = () => {
    const card = this.egressInNamespaceCard;
    const endpoint = card.endpointsList.find(e => e.isAllWithoutPorts);
    if (!endpoint) return;
    this._allowedEndpoints.delete(card.fullEndpointId(endpoint.id));
  };

  allowFullInNamespaceIngress = () => {
    const card = this.ingressInNamespaceCard;
    let endpoint = card.endpointsList.find(e => e.isAllWithoutPorts);
    if (!endpoint) {
      endpoint = PolicyEndpoint.newAll();
      card.addEndpoints(endpoint);
    }
    this._allowedEndpoints.add(card.fullEndpointId(endpoint.id));
  };

  denyFullInNamespaceIngress = () => {
    const card = this.ingressInNamespaceCard;
    const endpoint = card.endpointsList.find(e => e.isAllWithoutPorts);
    if (!endpoint) return;
    this._allowedEndpoints.delete(card.fullEndpointId(endpoint.id));
  };

  allowFullInClusterEgress = () => {
    const card = this.egressInClusterCard;
    let endpoint = card.endpointsList.find(e => e.isAllWithoutPorts);
    if (!endpoint) {
      endpoint = PolicyEndpoint.newAll();
      card.addEndpoints(endpoint);
    }
    this._allowedEndpoints.add(card.fullEndpointId(endpoint.id));
  };

  denyFullInClusterEgress = () => {
    const card = this.egressInClusterCard;
    const endpoint = card.endpointsList.find(e => e.isAllWithoutPorts);
    if (!endpoint) return;
    this._allowedEndpoints.delete(card.fullEndpointId(endpoint.id));
  };

  allowFullInClusterIngress = () => {
    const card = this.ingressInClusterCard;
    let endpoint = card.endpointsList.find(e => e.isAllWithoutPorts);
    if (!endpoint) {
      endpoint = PolicyEndpoint.newAll();
      card.addEndpoints(endpoint);
    }
    this._allowedEndpoints.add(card.fullEndpointId(endpoint.id));
  };

  denyFullInClusterIngress = () => {
    const card = this.ingressInClusterCard;
    const endpoint = card.endpointsList.find(e => e.isAllWithoutPorts);
    if (!endpoint) return;
    this._allowedEndpoints.delete(card.fullEndpointId(endpoint.id));
  };

  cleanupEgressWorld = () => {
    this._egressCards.forEach(card => {
      if (!card.isOutsideCluster) return;
      card.endpointsList.forEach(endpoint => {
        if (!endpoint.isAll) return;
        this._allowedEndpoints.delete(card.fullEndpointId(endpoint.id));
        if (!endpoint.isAllWithoutPorts) {
          card.removeEndpoint(endpoint);
        }
      });
    });
  };

  toggleDefaultDenyIngress = () => {
    if (this.hasIngressRules) {
      this.disableDefaultDenyIngress();
    } else {
      this.enableDefaultDenyIngress();
    }
  };

  enableDefaultDenyIngress = () => {
    this._defaultDenyIngress =
      this._controls.policyKind === PolicyKind.CNP
        ? DefaultDenyKind.CnpEmptyObjectRule
        : DefaultDenyKind.KnpPolicyTypeField;
  };

  disableDefaultDenyIngress = () => {
    this._defaultDenyIngress = null;

    const allowedEgresses = new Set<string>();
    this._allowedEndpoints.forEach(id => {
      const [cardId] = PolicyCard.parseFullCardEndpointId(id);
      if (this._egressCards.has(cardId)) allowedEgresses.add(id);
    });

    this._allowedEndpoints.forEach(id => {
      if (!allowedEgresses.has(id)) {
        this.onDenyReaction(id);
      }
    });

    this._allowedEndpoints = allowedEgresses;
  };

  allowKubeDns = () => {
    if (this.isKubeDnsAllowed) return;
    for (const card of this._egressCards) {
      if (card.isInCluster) {
        const kubeDnsEndpoint = PolicyEndpoint.newKubeDns();
        card.addEndpoints(kubeDnsEndpoint);
        this.setAllowedEndpoint(card.fullEndpointId(kubeDnsEndpoint.id), true);
        return;
      }
    }
  };

  denyKubeDns = () => {
    if (!this.isKubeDnsAllowed || !this.kubeDnsEndpoint) return;
    this.setAllowedEndpoint(
      this.egressInClusterCard.fullEndpointId(this.kubeDnsEndpoint.id),
      false,
    );
  };

  enableDNSProxy = () => {
    if (this.isDNSProxyEnabled) return;

    const kubeDnsEndpoint = this.kubeDnsEndpoint ?? PolicyEndpoint.newKubeDns();
    kubeDnsEndpoint.enableDNSProxy();

    if (!this.kubeDnsEndpoint) {
      this.egressInClusterCard.addEndpoints(kubeDnsEndpoint);
    }

    this.setAllowedEndpoint(
      this.egressInClusterCard.fullEndpointId(kubeDnsEndpoint.id),
      true,
    );
  };

  disableDNSProxy = () => {
    this.kubeDnsEndpoint?.disableDNSProxy();
  };

  toggleDefaultDenyEgress = () => {
    if (this.hasEgressRules) {
      this.disableDefaultDenyEgress();
    } else {
      this.enableDefaultDenyEgress();
    }
  };

  enableDefaultDenyEgress = () => {
    this._defaultDenyEgress =
      this._controls.policyKind === PolicyKind.CNP
        ? DefaultDenyKind.CnpEmptyObjectRule
        : DefaultDenyKind.KnpPolicyTypeField;
  };

  disableDefaultDenyEgress = () => {
    this._defaultDenyEgress = null;

    const allowedIngresses = new Set<string>();
    this._allowedEndpoints.forEach(id => {
      const [cardId] = PolicyCard.parseFullCardEndpointId(id);
      if (this._ingressCards.has(cardId)) allowedIngresses.add(id);
    });

    this._allowedEndpoints.forEach(id => {
      if (!allowedIngresses.has(id)) {
        this.onDenyReaction(id);
      }
    });

    this._allowedEndpoints = allowedIngresses;
  };

  toggleAllowedEnpoint = (endpointId: string): boolean => {
    const nextState = !this._allowedEndpoints.has(endpointId);
    this.setAllowedEndpoint(endpointId, nextState);
    return nextState;
  };

  isAllowedEndpoint = (endpointId: string): boolean => {
    return this._allowedEndpoints.has(endpointId);
  };

  getRuleStatusInfo = (
    card: PolicyCard,
    endpoint: PolicyEndpoint,
  ): RuleStatusInfo => {
    const unsupportedReason = card.getUnsupportedReasonInfo(
      this.policyKind,
      endpoint,
    );
    if (unsupportedReason) {
      return {
        kind: RuleStatusKind.UnsupportedByReason,
        info: unsupportedReason,
      };
    }

    if (endpoint.isFQDN && !this.isDNSProxyEnabled) {
      return {
        kind: RuleStatusKind.Ineffective,
        info: { kind: IneffectiveRuleKind.DisabledDNSProxy },
      };
    }

    if (this.isAllowedEndpoint(card.fullEndpointId(endpoint.id))) {
      return { kind: RuleStatusKind.AllowedByExplicitRule };
    }

    if (!this.hasSomeRules) {
      return { kind: RuleStatusKind.AllowedByDefaultAllow };
    }

    if (card.isOutsideCluster || card.isInCluster || card.isInNamespace) {
      const findStatus = (
        datas: Array<{
          dir: 'ingress' | 'egress';
          card: PolicyCard;
        }>,
      ): null | {
        kind: RuleStatusKind.AllowedByOtherRule;
        card: PolicyCard;
        endpoint: PolicyEndpoint;
      } => {
        const found = datas.filter(data => {
          if (
            (card.isIngress && data.dir === 'ingress') ||
            (card.isEgress && data.dir === 'egress')
          ) {
            if (
              data.card.allEndpoint?.isAllWithoutPorts &&
              this.isAllowedEndpoint(
                data.card.fullEndpointId(data.card.allEndpoint.id),
              )
            ) {
              return true;
            }
          }
          return false;
        });
        return found[0]
          ? {
              kind: RuleStatusKind.AllowedByOtherRule,
              card: found[0].card,
              endpoint: found[0].card.allEndpoint as PolicyEndpoint,
            }
          : null;
      };

      if (card.isInNamespace) {
        const checkForInClusterCard = findStatus([
          { dir: 'ingress', card: this.ingressInClusterCard },
          { dir: 'egress', card: this.egressInClusterCard },
        ]);
        if (checkForInClusterCard) return checkForInClusterCard;
      }

      const checkForAllCard = findStatus([
        { dir: 'ingress', card: this.ingressAllCard },
        { dir: 'egress', card: this.egressAllCard },
      ]);
      if (checkForAllCard) return checkForAllCard;
    }

    return { kind: RuleStatusKind.DeniedByDefaultDeny };
  };

  /* PRIVATE ACTIONS */

  private onDenyReaction = (deniedId: string) => {
    const deleteCardEndpoint = (card: PolicyCard) => {
      const endpoint = card.endpointsList.find(endpoint => {
        if (endpoint.isAllWithoutPorts || endpoint.isKubeDns) return false;
        return card.fullEndpointId(endpoint.id) === deniedId;
      });
      if (endpoint) {
        card.removeEndpoint(endpoint);
      }
    };

    this._ingressCards.forEach(deleteCardEndpoint);
    this._egressCards.forEach(deleteCardEndpoint);
  };

  private createCardStore = <Kinds extends CardKind>(
    cardSide: CardSide,
    cardKinds: Kinds[],
  ): CardsStore<Kinds> => {
    /* eslint-disable-next-line */
    const self = this;
    const store: CardsStore<Kinds> = {
      get list() {
        return cardKinds.map(cardKind => {
          const card = self._cards.get(PolicyCard.buildId(cardSide, cardKind));
          return card as PolicyCard;
        });
      },
      forEach(callback) {
        return store.list.forEach(callback);
      },
      has(id) {
        return store.list.some(card => card.id === id);
      },
      get(id) {
        return store.list.find(card => card.id === id);
      },
      set(card) {
        return self._cards.set(card.id, card);
      },
      [Symbol.iterator]: () => {
        let pointer = 0;
        return {
          next: () => {
            return pointer < store.list.length
              ? { done: false, value: store.list[pointer++] }
              : { done: true };
          },
        };
      },
    };
    return makeAutoObservable(store);
  };
}
