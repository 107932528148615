import React from 'react';
import ReactDOM from 'react-dom';
import webfontloader from 'webfontloader';

webfontloader.load({
  google: {
    families: ['Inter:400,500,600,700', 'Fira Code:400,700'],
  },
});

import './blueprint.scss';
import './index.scss';

import { store, StoreProvider } from '~/store';
import { NotifierProvider } from '~/notifier';

import * as ui from '~/ui';
import { CimulatorApp } from './components/CimulatorApp';

declare global {
  interface Window {
    debugTools: any;
  }
}

ui.setCSSVarsSizes(ui.sizes);
ui.setCSSVarsZIndex(ui.zIndex);

ReactDOM.render(
  <NotifierProvider>
    <StoreProvider store={store}>
      <CimulatorApp />
    </StoreProvider>
  </NotifierProvider>,
  document.getElementById('app'),
);
