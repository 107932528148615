import React, { memo, useCallback } from 'react';
import { AnalyticsTrackKind, track } from '~/utils/analytics';

import css from './SlackButton.scss';

export const SlackButton = memo(function SlackButton() {
  const onClick = useCallback(() => {
    track(AnalyticsTrackKind.AskOnSlack, {
      source: 'slack-overlay-button',
    });
  }, []);

  return (
    <a
      href="https://cilium.herokuapp.com/"
      target="_blank"
      rel="noreferrer"
      className={css.wrapper}
      onClick={onClick}
    >
      <div className={css.label}>Feedback/Questions?</div>
      <div className={css.button}>Ask on Slack</div>
    </a>
  );
});
