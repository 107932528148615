// S I Z E S

// this sizes map uses for both js calculations and css styles
// all sizes in this map should be considered in pixels
export const sizes = {
  endpointWidth: 560,
  endpointShadowSize: 24,
  endpointIconWidth: 80,
  endpointHeaderheight: 115,
  endpointTitleHeight: 29,
  endpointSubtitleHeight: 12,
  endpointHPadding: 200,
  endpointVPadding: 200,
  assistantPanelWidth: 340,
  endpointFunctionHeight: 16,
  distanceBetweenConnectors: 50,
  connectorCardGap: 40,
  minArrowLength: 50,
  arrowStartTopOffset: 50,
  arrowHandleWidth: 20,
  arrowRadius: 5,
  arrowOverlapGap: 20,
  aroundCardPadX: 40,
  aroundCardPadY: 40,
  endpointProtocolHeight: 16,
  egressConnectorWidth: 38,
  ingressConnectorPadding: 24,
  linkWidth: 2,
  linkCurveRadius: 8,
  connectorWidth: 4,
  flowsTableRowHeight: 31,
  flowsTableHeadHeight: 24,
  feetInnerWidth: 2,
  feetOuterWidth: 4,
  topBarHeight: 46,
  arrowHandleHWRatio: 10.87 / 11.61,
};

// C O L O R S
export const colors = {
  startPlateStroke: '#b4b4b4',
  startPlateFill: '#b4b4b4',
  arrowStroke: '#ACB1B8',
  arrowStrokeGreen: '#2F9316',
  arrowStrokeRed: '#D94C4C',
  arrowHandle: '#58626D',
  connectorFill: '#AEB7C0',
  connectorStroke: '#58626D',
  connectorFillGreen: '#A0D693',
  connectorStrokeGreen: '#2F9316',
  connectorStrokeRed: '#D94C4C',
  connectorFillRed: '#F08F8F',
  feetOuterStroke: '#EBEEF0',
  feetNeutralStroke: '#C1C8D0',
  feetRedStroke: '#FF5E5E',
};

export const zIndex = {
  bottomPanel: 20,
  slackButton: 21,
  loadingOverlay: 30,
  alertsPortal: 40,
  popovers: 41,
  tooltips: 42,
  smallScreenOverlay: 50,
};
