import { observer } from 'mobx-react';
import classnames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { Flow } from '~/domain/flows';
import { AnalyticsTrackKind, track } from '~/utils/analytics';
import { useStore } from '~/store';

import css from './FlowActionComponent.scss';
import { PolicyCard } from '~/domain/cimulator/cards';

export interface Props {
  flow: Flow;
}

export const FlowActionComponent = observer(function FlowActionComponent(
  props: Props,
) {
  const store = useStore();

  const entries = useMemo(() => {
    return store.policy.flowToCards(props.flow);
  }, [props.flow, store.policy.cardsMap]);

  const action = useMemo(() => {
    const someAllowed = entries.some(entry => {
      return entry.endpoints.some(endpoint => {
        const fullEndpointId = PolicyCard.buildFullEndpointId(
          entry.cardSide,
          entry.cardKind,
          endpoint.id,
        );
        return store.policy.isAllowedEndpoint(fullEndpointId);
      });
    });

    return someAllowed ? 'deny' : 'allow';
  }, [props.flow, entries, store.policy.allowedEndpointsSet]);

  const label = useMemo(() => {
    return action === 'deny' ? 'Remove rule' : 'Add rule';
  }, [action]);

  const onClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      track(AnalyticsTrackKind.ToggleRuleFromFlow);
      entries.forEach(entry => {
        const card = store.policy.getCardBy(entry.cardSide, entry.cardKind);
        if (!card) return;
        entry.endpoints.forEach(endpoint => {
          const allowState = action === 'allow' ? true : false;
          if (allowState) card.addEndpoints(endpoint);
          const fullEndpointId = card.fullEndpointId(endpoint.id);
          store.policy.setAllowedEndpoint(fullEndpointId, allowState);
          store.controls.selectCardId(allowState ? card.id : null);
          store.controls.selectEndpointId(allowState ? endpoint.id : null);
        });
      });
    },
    [entries, action],
  );

  const className = classnames(css.action, {
    [css.allow]: action === 'allow',
    [css.deny]: action === 'deny',
  });

  return (
    <div onClick={onClick} className={className}>
      {label}
    </div>
  );
});
