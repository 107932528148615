import { ITagProps } from '@blueprintjs/core';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import {
  CardKind,
  CardSide,
  EndpointKind,
  EndpointMatchExpression,
  PolicyKind,
} from '~/domain/cimulator/types';
import { Labels, NamespaceLabelKey } from '~/domain/labels';

export interface RuleData {
  kind: RuleKind | null;
  fqdn: string;
  cidr: string;
  exceptCidrs: string[];
  namespaceSelector: {
    selectAll: boolean;
    matchLabels?: string[];
    matchExpressions?: EndpointMatchExpression[];
  };
  podSelector: {
    matchLabels?: string[];
    matchExpressions?: EndpointMatchExpression[];
  };
  ports: string[];
}

export enum RuleKind {
  All = 'all',
  Host = 'host',
  RemoteNode = 'remote-node',
  Cidr = 'cidr',
  Fqdn = 'fqdn',
  AWS = 'aws',
  KubeDns = 'kube-dns',
  NamespaceSelector = 'namespace',
  PodSelector = 'labels',
  Service = 'service',
}

export const CNPRuleKindsByCard: {
  [key in CardSide]: { [key in CardKind]: RuleKind[] };
} = {
  [CardSide.Ingress]: {
    [CardKind.All]: [RuleKind.All],
    [CardKind.OutsideCluster]: [RuleKind.All, RuleKind.Cidr],
    [CardKind.InCluster]: [
      RuleKind.All,
      RuleKind.NamespaceSelector,
      RuleKind.PodSelector,
      RuleKind.Host,
      RuleKind.RemoteNode,
    ],
    [CardKind.InNamespace]: [RuleKind.All, RuleKind.PodSelector],
  },
  [CardSide.Egress]: {
    [CardKind.All]: [RuleKind.All],
    [CardKind.OutsideCluster]: [RuleKind.All, RuleKind.Cidr, RuleKind.Fqdn],
    [CardKind.InCluster]: [
      RuleKind.All,
      RuleKind.NamespaceSelector,
      RuleKind.PodSelector,
      RuleKind.Service,
      RuleKind.Host,
      RuleKind.RemoteNode,
    ],
    [CardKind.InNamespace]: [
      RuleKind.All,
      RuleKind.PodSelector,
      RuleKind.Service,
    ],
  },
  [CardSide.Selector]: {
    [CardKind.All]: [],
    [CardKind.OutsideCluster]: [],
    [CardKind.InCluster]: [],
    [CardKind.InNamespace]: [],
  },
};

export const KNPRuleKindsByCard: {
  [key in CardSide]: { [key in CardKind]: RuleKind[] };
} = {
  [CardSide.Ingress]: {
    [CardKind.All]: [RuleKind.All],
    [CardKind.OutsideCluster]: [RuleKind.All, RuleKind.Cidr],
    [CardKind.InCluster]: [
      RuleKind.All,
      RuleKind.NamespaceSelector,
      RuleKind.PodSelector,
    ],
    [CardKind.InNamespace]: [RuleKind.All, RuleKind.PodSelector],
  },
  [CardSide.Egress]: {
    [CardKind.All]: [RuleKind.All],
    [CardKind.OutsideCluster]: [RuleKind.All, RuleKind.Cidr, RuleKind.Fqdn],
    [CardKind.InCluster]: [
      RuleKind.All,
      RuleKind.NamespaceSelector,
      RuleKind.PodSelector,
    ],
    [CardKind.InNamespace]: [RuleKind.All, RuleKind.PodSelector],
  },
  [CardSide.Selector]: {
    [CardKind.All]: [],
    [CardKind.OutsideCluster]: [],
    [CardKind.InCluster]: [],
    [CardKind.InNamespace]: [],
  },
};

export const RuleKindsByPolicy: {
  [key in PolicyKind]: { [key in CardSide]: { [key in CardKind]: RuleKind[] } };
} = {
  [PolicyKind.CNP]: CNPRuleKindsByCard,
  [PolicyKind.KNP]: KNPRuleKindsByCard,
};

export const RuleKindLabels = {
  [RuleKind.All]: {
    [CardKind.All]: 'everything',
    [CardKind.OutsideCluster]: 'any endpoint',
    [CardKind.InNamespace]: 'any pod',
    [CardKind.InCluster]: 'everything in the cluster',
  },
  [RuleKind.Host]: 'host',
  [RuleKind.RemoteNode]: 'remote-node',
  [RuleKind.Fqdn]: 'FQDN',
  [RuleKind.Cidr]: 'CIDR',
  [RuleKind.KubeDns]: 'Kubernetes DNS',
  [RuleKind.AWS]: 'AWS security group',
  [RuleKind.NamespaceSelector]: 'namespace selector',
  [RuleKind.PodSelector]: 'pod selector',
  [RuleKind.Service]: 'service',
};

export function ruleKindFromEndpointKind(
  endpoint: PolicyEndpoint,
): RuleKind | null {
  if (!endpoint) return null;

  switch (endpoint.kind) {
    case EndpointKind.All:
      return RuleKind.All;
    case EndpointKind.Host:
      return RuleKind.Host;
    case EndpointKind.RemoteNode:
      return RuleKind.RemoteNode;
    case EndpointKind.Cidr:
      return RuleKind.Cidr;
    case EndpointKind.KubeDns:
      return RuleKind.KubeDns;
    case EndpointKind.Fqdn:
      return RuleKind.Fqdn;
    case EndpointKind.LabelsSelector:
      return RuleKind.PodSelector;
    case EndpointKind.NamespaceSelector:
      return RuleKind.NamespaceSelector;
    case EndpointKind.Service:
      return RuleKind.Service;
    default:
      return null;
  }
}

export function endpointKindFromRuleKind(
  kind?: RuleKind | null,
): EndpointKind | null {
  switch (kind) {
    case RuleKind.All:
      return EndpointKind.All;
    case RuleKind.Host:
      return EndpointKind.Host;
    case RuleKind.RemoteNode:
      return EndpointKind.RemoteNode;
    case RuleKind.Cidr:
      return EndpointKind.Cidr;
    case RuleKind.KubeDns:
      return EndpointKind.KubeDns;
    case RuleKind.Fqdn:
      return EndpointKind.Fqdn;
    case RuleKind.PodSelector:
      return EndpointKind.LabelsSelector;
    case RuleKind.NamespaceSelector:
      return EndpointKind.NamespaceSelector;
    case RuleKind.Service:
      return EndpointKind.Service;
    default:
      return null;
  }
}

export const FQDNMatchPatternRegexString = `^([-a-zA-Z0-9_*]+[.]?)+$`;
export const FQDNMatchPatternRegexStringRegexp = new RegExp(
  FQDNMatchPatternRegexString,
);

export const tagInputTagCommonProps = {
  minimal: true,
  multiline: true,
  style: {
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  onRemove: undefined,
} as ITagProps;

export function toNamespaceMatchLabels(
  policyKind: PolicyKind,
  matchLabels: { [key: string]: string },
) {
  const obj: { [key: string]: string } = {};
  Object.entries(matchLabels).forEach(([key, value]) => {
    if (!value) {
      if (policyKind === PolicyKind.KNP) {
        value = '';
        key = `${NamespaceLabelKey}.labels.${key}`;
      } else {
        value = key;
        key = NamespaceLabelKey;
      }
    } else {
      if (!key.includes(NamespaceLabelKey)) {
        key = `${NamespaceLabelKey}.labels.${key}`;
      }
    }
    obj[key] = value;
  });
  return obj;
}

export function toNamespaceMatchExpressions(
  matchExpressions: EndpointMatchExpression[],
) {
  return matchExpressions.map(expr => {
    let key = expr.key;
    if (!key.includes(NamespaceLabelKey)) {
      key = `${NamespaceLabelKey}.labels.${key}`;
    }
    return { ...expr, key };
  });
}

export function normNamespaceMatchLabels(
  policyKind: PolicyKind,
  matchLabels: string[],
) {
  return matchLabels.reduce((acc, s) => {
    const trimmed = s.trim();
    if (!trimmed.length) return acc;
    const [key, value] = trimmed.split('=').map(v => v.trim());
    if (typeof value !== 'string') {
      if (policyKind === PolicyKind.CNP) acc.push(key);
      else acc.push(`${NamespaceLabelKey}=${value}`);
      return acc;
    }
    let normkey = Labels.normalizeKey(key);
    if (normkey === 'namespace') {
      if (policyKind === PolicyKind.CNP) acc.push(value);
      else acc.push(`${key}=${value}`);
      return acc;
    }
    if (normkey.startsWith('namespace.labels.')) {
      normkey = normkey.slice('namespace.labels.'.length);
    }
    acc.push(`${normkey}=${value}`);
    return acc;
  }, [] as string[]);
}

export function normNamespaceMatchExpressions(
  matchExpressions: EndpointMatchExpression[],
) {
  return matchExpressions.map(expr => {
    let normkey = Labels.normalizeKey(expr.key);
    if (normkey.startsWith('namespace.labels.')) {
      normkey = normkey.slice('namespace.labels.'.length);
    }
    return { ...expr, key: normkey };
  }, [] as string[]);
}

export function normMatchExpressions(
  matchExpressions: EndpointMatchExpression[],
) {
  return matchExpressions.map(expr => ({
    key: expr.key.trim(),
    operator: expr.operator,
    values: expr.values?.map(v => v.trim()).filter(v => v.length),
  }));
}

export function stringArrayToMatchLabels(str: string[]) {
  const selector: { [key: string]: string } = {};
  str.forEach(label => {
    const trimmed = label.trim();
    if (!trimmed) return;
    const kv = trimmed
      .trim()
      .split('=')
      .map(p => p.trim());
    selector[kv[0]] = kv[1] ?? '';
  });
  return selector;
}

export function toSelector(selector: {
  matchLabels?: string[];
  matchExpressions?: EndpointMatchExpression[];
}): {
  matchLabels?: { [key: string]: string };
  matchExpressions?: EndpointMatchExpression[];
} | null {
  const result: {
    matchLabels?: { [key: string]: string };
    matchExpressions?: EndpointMatchExpression[];
  } = {};

  const matchLabels = selector.matchLabels
    ? stringArrayToMatchLabels(selector.matchLabels)
    : null;

  const matchExpressions = selector.matchExpressions
    ? normMatchExpressions(selector.matchExpressions)
    : null;

  if (!matchLabels && !matchExpressions) return null;

  if (matchLabels) result.matchLabels = matchLabels;
  if (matchExpressions) result.matchExpressions = matchExpressions;

  return result;
}
