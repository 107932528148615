import { Document } from 'yaml';
import {
  CiliumNetworkPolicy,
  EgressRule,
  IngressRule,
  Rule,
} from '~/domain/cilium/cnp/types';
import {
  NetworkPolicy,
  NetworkPolicyEgressRule,
  NetworkPolicyIngressRule,
  NetworkPolicySpec,
} from '~/domain/k8s/knp/types';
import { PolicyCard } from './cards';
import { PolicyEndpoint } from './endpoint';

export enum PolicyKind {
  CNP = 'cnp',
  KNP = 'knp',
}

export enum RuleState {
  Allowed = 'allowed',
  AllowedByDefault = 'allowed by default',
  Denied = 'denied',
}

export enum TrafficDirection {
  Ingress = 'ingress',
  Egress = 'egress',
}

export enum CardSide {
  Ingress = 'ingress',
  Egress = 'egress',
  Selector = 'selector',
}

export enum CardKind {
  All = 'all',
  OutsideCluster = 'world',
  InNamespace = 'in-namespace',
  InCluster = 'cluster',
}

export interface CardGeneralInfo {
  side: CardSide;
  kind: CardKind;
  namespace: string | null;
}

export enum EndpointKind {
  All = 'all',
  Host = 'host',
  RemoteNode = 'remote-node',
  LabelsSelector = 'labels-selector',
  NamespaceSelector = 'namespace-selector',
  Service = 'service',
  Fqdn = 'fqdn',
  Cidr = 'cidr',
  KubeDns = 'kube-dns',
  None = 'none',
}

export interface EndpointMatchExpression {
  key: string;
  operator: EndpointRequirementOperator;
  values?: string[] | null;
}

export enum EndpointRequirementKind {
  MatchLabels = 'matchLabels',
  MatchExpression = 'matchExpression',
}

export enum EndpointRequirementOperator {
  In = 'In',
  NotIn = 'NotIn',
  Exists = 'Exists',
  DoesNotExist = 'DoesNotExist',
}

export enum EndpointAllKind {
  KNPEmptyObject = '- {}',
  KNPZeroCidr = '0.0.0.0/0',
  AllNamespacesSelector = 'knp: - namespaceSelector: {}|cnp: matchExpressions:[{key: io.kubernetes.pod.namespace, operator: Exists}]',
}

export enum EndpointCidrKind {
  Set = 'cidr-set',
  String = 'cidr-string',
}

export type CardsMap = Map<string, PolicyCard>;
export type EndpointsMap = Map<string, PolicyEndpoint>;

export interface LabelSelectorRequirement {
  key: string;
  operator: string;
  values?: string[] | null;
}

export interface Selector {
  matchLabels?: { [key: string]: string };
  matchExpressions?: LabelSelectorRequirement[] | null;
}

export interface PolicyParseResult {
  cards: CardsMap;
  defaultDenyIngress: DefaultDenyKind | null;
  defaultDenyEgress: DefaultDenyKind | null;
  unspprtdIngress: any[];
  unspprtdEgress: any[];
}

export enum DefaultDenyKind {
  KnpPolicyTypeField = '- policyTypes: Ingress/Egress',
  KnpEmptyRulesArray = 'ingress/egress: []',
  CnpEmptyObjectRule = 'ingress/egress: [{}]',
}

export enum UnsupportedReasonKind {
  Unknown = 'unknown',
  CNPNamespaceKey = 'cnp-namespace-key',
  CNPfqdn = 'cnp-fqdn',
  CNPEntity = 'cnp-entity',
}

export type UnsupportedReasonInfo =
  | { kind: UnsupportedReasonKind.Unknown }
  | {
      kind: UnsupportedReasonKind.CNPNamespaceKey;
      namespaceLabel: string;
    }
  | { kind: UnsupportedReasonKind.CNPfqdn; fqdn: string | null }
  | { kind: UnsupportedReasonKind.CNPEntity; entity: string };

export enum IneffectiveRuleKind {
  DisabledDNSProxy = 'disabled-dns-proxy',
}

export type IneffectiveRuleInfo = {
  kind: IneffectiveRuleKind.DisabledDNSProxy;
};

export interface PolicyBuilder<
  PolicySpecType extends Rule | NetworkPolicySpec,
  PolicyIngressRuleType extends IngressRule | NetworkPolicyIngressRule,
  PolicyEgressRuleType extends EgressRule | NetworkPolicyEgressRule,
> {
  parsePolicy: (doc: Document) => {
    policyName: string | null;
    policyNamespace: string | null;
    isSingleSpec: boolean;
    results: PolicyParseResult[];
  };

  generateYaml(
    policyName: string | null,
    policyNamespace: string | null,
    specs: Array<{
      spec: PolicySpecType;
      unspprtdEgress: PolicyEgressRuleType[];
      unspprtdIngress: PolicyIngressRuleType[];
    }>,
    isSingleSpec: boolean,
  ): string | null;

  generateYamlForCardEndpoint(
    card: PolicyCard,
    endpoint: PolicyEndpoint | null,
    onlyRule?: boolean,
  ): string | null;

  generatePolicyHeader(
    name: string | null,
    namespace: string | null,
  ): { [key: string]: any };

  generateSpecForCardEndpoint(
    card: PolicyCard,
    endpoint: PolicyEndpoint | null,
    onlyRule?: boolean,
  ): PolicySpecType;

  generateSpec(
    podSelector: Selector | null,
    ingresses: CardsMap,
    egresses: CardsMap,
    defaultDenyIngress: DefaultDenyKind | null,
    defaultDenyEgress: DefaultDenyKind | null,
    namespace: string | null,
    onlyRule?: boolean,
  ): PolicySpecType;
}
