export enum Feature {
  Simulate = 'simulate',
  UploadPolicy = 'upload-policy',
  UploadFlows = 'upload-flows',
}

export const BetaFeatures = new Set([Feature.Simulate]);

export function isFeatureEnabled(feature: Feature) {
  if (!BetaFeatures.has(feature)) return true;

  return localStorage.getItem(`@cimulator/feature/${feature}`) === 'on';
}

export function isFeatureDisabled(feature: Feature) {
  return !isFeatureEnabled(feature);
}

export function enableFeature(feature: Feature) {
  localStorage.setItem(`@cimulator/feature/${feature}`, 'on');
}

export function disableFeature(feature: Feature) {
  localStorage.removeItem(`@cimulator/feature/${feature}`);
}

(window as any).cimulatorFeatures = {
  Feature: Feature,
  enable: enableFeature,
  disable: disableFeature,
};
