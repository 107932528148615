import { Icon } from '@blueprintjs/core';
import React, { memo } from 'react';
import { Flow, FlowsFilterDirection, Verdict } from '~/domain/flows';
import {
  DnsBodyItem,
  IdentityEntry,
  IPEntry,
  LabelsEntry,
  PodEntry,
  TCPFlagsEntry,
  VerdictEntry,
} from './SidebarComponents';
import css from './styles.scss';

export interface Props {
  flow: Flow;
  onClose?: () => void;
}

export const FlowsTableSidebar = memo<Props>(function FlowsTableSidebar(props) {
  const { flow } = props;

  return (
    <div className={css.sidebar}>
      <header className={css.header}>
        <Icon className={css.close} icon="cross" onClick={props.onClose} />
        <div>Flow Details</div>

        <div className={css.note}>
          This is a sample of details for one flow from the aggregation batch.
          We aggregate flows by source labels, destination labels and
          destination port
        </div>
      </header>
      <section className={css.block}>
        <span className={css.title}>Timestamp</span>
        <div className={css.body}>{flow.isoTimestamp}</div>
      </section>
      <section className={css.block}>
        <span className={css.title}>Verdict</span>
        <div className={css.body}>
          <VerdictEntry verdict={flow.verdict} />
        </div>
      </section>
      {flow.verdict === Verdict.Dropped && (
        <section className={css.block}>
          <span className={css.title}>Drop reason</span>
          <div className={css.body}>{flow.dropReason}</div>
        </section>
      )}
      <section className={css.block}>
        <span className={css.title}>Traffic direction</span>
        <div className={css.body}>{flow.trafficDirectionLabel}</div>
      </section>
      {flow.ciliumEventSubTypeLabel && (
        <section className={css.block}>
          <span className={css.title}>Cilium event type</span>
          <div className={css.body}>{flow.ciliumEventSubTypeLabel}</div>
        </section>
      )}
      {flow.enabledTcpFlags.length > 0 && (
        <section className={css.block}>
          <span className={css.title}>TCP flags</span>
          <div className={css.body}>
            <TCPFlagsEntry
              flags={flow.enabledTcpFlags}
              filterDirection={FlowsFilterDirection.From}
            />
          </div>
        </section>
      )}
      <hr />
      {flow.hasSource && flow.sourcePodName && (
        <section className={css.block}>
          <span className={css.title}>Source pod</span>
          <div className={css.body}>
            <PodEntry pod={flow.sourcePodName} />
          </div>
        </section>
      )}
      {flow.hasSource && typeof flow.sourceIdentity === 'number' && (
        <section className={css.block}>
          <span className={css.title}>Source identity</span>
          <div className={css.body}>
            <IdentityEntry identity={flow.sourceIdentity} />
          </div>
        </section>
      )}
      {flow.hasSource && flow.sourceLabels.length > 0 && (
        <section className={css.block}>
          <span className={css.title}>Source labels</span>
          <div className={css.body}>
            <LabelsEntry labels={flow.sourceLabels} />
          </div>
        </section>
      )}
      {flow.hasSource && flow.sourceIp && (
        <section className={css.block}>
          <span className={css.title}>Source IP</span>
          <div className={css.body}>
            <IPEntry ip={flow.sourceIp} />
          </div>
        </section>
      )}
      <hr />
      {flow.hasDestination && flow.destinationPodName && (
        <section className={css.block}>
          <span className={css.title}>Destination pod</span>
          <div className={css.body}>
            <PodEntry pod={flow.destinationPodName} />
          </div>
        </section>
      )}
      {flow.hasDestination && typeof flow.destinationIdentity === 'number' && (
        <section className={css.block}>
          <span className={css.title}>Destination identity</span>
          <div className={css.body}>
            <IdentityEntry identity={flow.destinationIdentity} />
          </div>
        </section>
      )}
      {flow.hasDestination && flow.destinationLabels.length > 0 && (
        <section className={css.block}>
          <span className={css.title}>Destination labels</span>
          <div className={css.body}>
            <LabelsEntry labels={flow.destinationLabels} />
          </div>
        </section>
      )}
      {flow.hasDestination && flow.destinationIp && (
        <section className={css.block}>
          <span className={css.title}>Destination IP</span>
          <div className={css.body}>
            <IPEntry ip={flow.destinationIp} />
          </div>
        </section>
      )}
      {flow.hasDestination && flow.destinationDns && (
        <section className={css.block}>
          <span className={css.title}>Destination DNS</span>
          <div className={css.body}>
            <DnsBodyItem dns={flow.destinationDns} />
          </div>
        </section>
      )}
      {flow.hasDestination && typeof flow.destinationPort === 'number' && (
        <section className={css.block}>
          <span className={css.title}>Destination port</span>
          <div className={css.body}>{flow.destinationPort}</div>
        </section>
      )}
    </div>
  );
});
