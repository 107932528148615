import { Button, ButtonGroup } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { PolicyKind } from '~/domain/cimulator/types';
import { track, AnalyticsTrackKind } from '~/utils/analytics';

import css from './PolicyKindSelector.scss';

export interface Props {
  curPolicyKind?: PolicyKind;
  onChange?: (kind: PolicyKind) => void;
}

export const PolicyKindSelector = observer(function PolicyKindSelector(
  props: Props,
) {
  const onKNP = useCallback(() => {
    props.onChange?.(PolicyKind.KNP);
    track(AnalyticsTrackKind.ChangePolicyKind, {
      policyKind: PolicyKind.KNP,
      source: 'yaml-panel',
    });
  }, [props.onChange]);

  const onCNP = useCallback(() => {
    props.onChange?.(PolicyKind.CNP);
    track(AnalyticsTrackKind.ChangePolicyKind, {
      policyKind: PolicyKind.CNP,
      source: 'yaml-panel',
    });
  }, [props.onChange]);

  return (
    <ButtonGroup>
      <Button
        small
        outlined
        icon={
          <img
            src="/static/assets/images/k8s-logo.png"
            alt=""
            className={css.k8slogo}
          />
        }
        active={props.curPolicyKind === PolicyKind.KNP}
        onClick={onKNP}
      >
        Kubernetes Network Policy
      </Button>
      <Button
        small
        outlined
        icon={
          <img
            src="/static/assets/images/cilium-logo.png"
            alt=""
            className={css.ciliumlogo}
          />
        }
        active={props.curPolicyKind === PolicyKind.CNP}
        onClick={onCNP}
      >
        Cilium Network Policy
      </Button>
    </ButtonGroup>
  );
  // const items = useMemo(() => {
  //   return [PolicyKind.KNP, PolicyKind.CNP];
  // }, []);

  // const onChange = useCallback((kind: PolicyKind) => props.onChange?.(kind), [
  //   props.onChange,
  // ]);

  // const itemRenderer: ItemRenderer<PolicyKind> = (
  //   kind: PolicyKind,
  //   { handleClick },
  // ) => {
  //   return (
  //     <MenuItem
  //       key={kind}
  //       active={kind === props.curPolicyKind}
  //       text={getPolicyKindTitle(kind)}
  //       onClick={handleClick}
  //       shouldDismissPopover={false}
  //     />
  //   );
  // };

  // return (
  //   <Select
  //     onItemSelect={onChange}
  //     itemRenderer={itemRenderer}
  //     items={items}
  //     filterable={false}
  //     popoverProps={{
  //       captureDismiss: true,
  //       minimal: true,
  //       usePortal: false,
  //       targetTagName: 'div',
  //       position: 'bottom',
  //     }}
  //   >
  //     <Button
  //       text={getPolicyKindTitle(props.curPolicyKind)}
  //       alignText="left"
  //       rightIcon={'caret-down'}
  //     />
  //   </Select>
  // );
});

// function getPolicyKindTitle(kind?: PolicyKind) {
//   return kind === PolicyKind.CNP
//     ? 'Cilium Network Policy'
//     : kind === PolicyKind.KNP
//     ? 'Kubernetes Network Policy'
//     : 'Select network policy format...';
// }
