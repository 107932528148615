import { TagInput } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useStore } from '~/store';

import css from './FlowsSettings.scss';

export const FlowsSettings = observer(function FlowsSettings() {
  const store = useStore();

  const onChangeIgnoreFlowLabels = useCallback((values: React.ReactNode[]) => {
    store.policy.setIgnoreFlowLabels(values as string[]);
  }, []);

  return (
    <div className={css.wrapper} onClick={e => e.stopPropagation()}>
      <div className={css.label}>Ignore labels</div>
      <TagInput
        addOnBlur
        addOnPaste
        tagProps={{
          minimal: true,
          multiline: true,
          style: {
            wordBreak: 'break-all',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        className={css.ignoreLabelsInput}
        onChange={onChangeIgnoreFlowLabels}
        placeholder="deployment=staging, version"
        values={store.policy.ignoreFlowLabelsList}
        inputProps={{ style: { fontSize: '14px' } }}
      />
    </div>
  );
});
