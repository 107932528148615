import classnames from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo, useState } from 'react';
import { Card, CardComponentProps, CoordsFn } from '~/components/Card';
import { EndpointSelector } from '~/domain/cilium/cnp/types';
import { PolicyCard as DomainPolicyCard } from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import { Vec2, XY } from '~/domain/geometry';
import { useStore } from '~/store';
import { CardHeader } from './CardHeader';
import { EndpointItem } from './EndpointItem';
import css from './styles.scss';

export type Props = Omit<CardComponentProps<DomainPolicyCard>, 'onClick'> & {
  selectedCardId?: string | null;
  selectedEndpointId?: string | null;
  defaultDenyIngress: boolean;
  defaultDenyEgress: boolean;
  onToggleDefaultDenyIngress?: () => void;
  onToggleDefaultDenyEgress?: () => void;
};

export const PolicyCard = observer(function PolicyCard(props: Props) {
  const store = useStore();

  const [coordsFn, setCoordsFn] = useState<CoordsFn | null>(() => null);

  const onSelectElement = useCallback(
    (card?: DomainPolicyCard, endpoint?: PolicyEndpoint) => {
      store.controls.selectElement(card, endpoint);
    },
    [],
  );

  const onClick = useCallback(() => {
    if (!props.card.isSelector) return;
    store.controls.selectElement(props.card);
  }, [props.card]);

  // prettier-ignore
  const onHeightChange = useCallback((h: number) => {
    props.onHeightChange?.(h);
  }, [props.onHeightChange]);

  const handleEndpointCoords = useCallback(
    (endpoint: PolicyEndpoint, point: XY) => {
      if (!coordsFn) return;
      const [cardCoords, svgCoords] = coordsFn(point);
      const id = props.card.fullEndpointId(endpoint.id);
      props.onAccessPointCoords?.(id, Vec2.fromXY(svgCoords));
    },
    [props.onAccessPointCoords, props.card, coordsFn],
  );

  const renderedEndpoints = useMemo(() => {
    if (!coordsFn) return null;

    return (
      <div className={css.innerEndpoints}>
        {props.card.endpointsList.map(endpoint => {
          const fullEndpointId = props.card.fullEndpointId(endpoint.id);

          return (
            <EndpointItem
              active={
                props.selectedCardId === props.card.id &&
                props.selectedEndpointId === endpoint.id
              }
              key={fullEndpointId}
              card={props.card}
              endpoint={endpoint}
              onArrowPoint={point => handleEndpointCoords(endpoint, point)}
              onSelectElement={onSelectElement}
            />
          );
        })}
      </div>
    );
  }, [
    coordsFn,
    props.card,
    props.card.endpointsList,
    props.selectedCardId,
    props.selectedEndpointId,
  ]);

  const onEmitCoordsFn = useCallback((fn: CoordsFn) => {
    setCoordsFn(() => fn);
  }, []);

  const wrapperClassName = classnames(css.wrapper, {
    [css.active]:
      props.card.isSelector && props.selectedCardId === props.card.id,
  });

  return (
    <Card
      {...props}
      clickable={props.card.isSelector}
      isBackplate={false}
      onHeightChange={onHeightChange}
      onEmitCoordsFn={onEmitCoordsFn}
    >
      <div className={wrapperClassName} onClick={onClick}>
        <CardHeader
          card={props.card}
          defaultDenyIngress={props.defaultDenyIngress}
          defaultDenyEgress={props.defaultDenyEgress}
          onToggleDefaultDenyIngress={props.onToggleDefaultDenyIngress}
          onToggleDefaultDenyEgress={props.onToggleDefaultDenyEgress}
          onSelectElement={onSelectElement}
        />
        {props.card.isSelector && (
          <EndpointSelector selector={props.card.podSelector} />
        )}
        {!props.card.isSelector && renderedEndpoints}
      </div>
    </Card>
  );
});

const EndpointSelector = observer(function EndpointSelector(props: {
  selector: EndpointSelector | null;
}) {
  if (!props.selector) return null;

  const { matchLabels, matchExpressions } = props.selector;

  if (
    (!matchLabels && !matchExpressions) ||
    (matchLabels && Object.keys(matchLabels).length === 0) ||
    (matchExpressions && Object.keys(matchExpressions).length === 0)
  ) {
    return null;
  }

  let matchLabelsJsx: JSX.Element | null = null;
  let matchExpressionsJsx: JSX.Element | null = null;

  if (matchLabels && Object.keys(matchLabels).length > 0) {
    matchLabelsJsx = (
      <>
        {Object.entries(matchLabels).map(([key, value]) => {
          return (
            <div key={`${key}=${value}`} className={css.endpointSelectorLine}>
              {value ? `${key}=${value}` : key}
            </div>
          );
        })}
      </>
    );
  }

  if (matchExpressions && Object.keys(matchExpressions).length > 0) {
    matchExpressionsJsx = (
      <>
        {matchExpressions.map(req => {
          return (
            <div key={JSON.stringify(req)} className={css.endpointSelectorLine}>
              {req.key} {req.operator} {req.values && req.values.join(', ')}
            </div>
          );
        })}
      </>
    );
  }

  return (
    <div className={css.endpointSelector}>
      {matchLabelsJsx && matchLabelsJsx}
      {matchExpressionsJsx && matchExpressionsJsx}
    </div>
  );
});
