import { RuleKind } from '~/components/PolicyCard/general';
import {
  CardKind,
  CardSide,
  EndpointKind,
  PolicyKind,
} from '~/domain/cimulator/types';
import { RatingRuleKind } from '~/store/stores/policy/types';

export enum AnalyticsTrackKind {
  TutorialAction = 'TutorialAction ',
  DownloadPolicyYaml = 'DownloadPolicyYaml',
  ChangePolicyKind = 'ChangePolicyKind',
  CreatePolicy = 'CreatePolicy',
  UploadPolicy = 'UploadPolicy',
  UploadedPolicy = 'UploadedPolicy',
  UploadInvalidPolicyYaml = 'UploadInvalidPolicyYaml',
  AskOnSlack = 'AskOnSlack',
  FirstVisit = 'FirstVisit',
  FollowingVisit = 'FollowingVisit',
  ShowUploadFlowsPanel = 'ShowUploadFlowsPanel',
  ShowUploadFlowsHelp = 'ShowUploadFlowsHelp',
  UploadFlows = 'UploadFlows',
  UploadedFlows = 'UploadedFlows',
  ToggleRuleFromFlow = 'ToggleRuleFromFlow',
  PolicyRuleCRUD = 'PolicyRuleCRUD',
  StartSharePolicyViaGist = 'StartSharePolicyViaGist',
  CompleteSharePolicyViaGist = 'CompleteSharePolicyViaGist',
  FailedSharePolicyViaGist = 'FailedSharePolicyViaGist',
}

export type AnalyticsTrackPayload = {
  [AnalyticsTrackKind.TutorialAction]: { step: string; action: string };
  [AnalyticsTrackKind.DownloadPolicyYaml]: {
    policyKind: PolicyKind;
    multiSpec: boolean;
    rulesCnt: number;
    specsStats: Array<{ [key in RatingRuleKind]: boolean }>;
  };
  [AnalyticsTrackKind.UploadedPolicy]: {
    policyKind: PolicyKind;
    multiSpec: boolean;
    rulesCnt: number;
    unsupportedRulesCnt: number;
    specsStats: Array<{ [key in RatingRuleKind]: boolean }>;
  };
  [AnalyticsTrackKind.ChangePolicyKind]: {
    policyKind: PolicyKind;
    source: 'yaml-panel';
  };
  [AnalyticsTrackKind.CreatePolicy]: {
    source: 'yaml-panel';
  };
  [AnalyticsTrackKind.UploadPolicy]: {
    source: 'yaml-panel' | 'template' | 'gist';
  };
  [AnalyticsTrackKind.AskOnSlack]: {
    source: 'slack-overlay-button';
  };
  [AnalyticsTrackKind.UploadInvalidPolicyYaml]: undefined;
  [AnalyticsTrackKind.FirstVisit]: {
    query: {
      hasPolicyUrl: boolean;
      policyTutorial: string | null;
    };
  };
  [AnalyticsTrackKind.FollowingVisit]: {
    counter: number;
    query: {
      hasPolicyUrl: boolean;
      policyTutorial: string | null;
    };
  };
  [AnalyticsTrackKind.ShowUploadFlowsPanel]: {
    source: 'right-panel-tab';
  };
  [AnalyticsTrackKind.ShowUploadFlowsHelp]: undefined;
  [AnalyticsTrackKind.UploadFlows]: {
    source: 'upload-flows-panel';
  };
  [AnalyticsTrackKind.UploadedFlows]: {
    aggregatedFlowsCnt: number;
    originFlowsCnt: number;
    rejectedFlowsCnt: number;
  };
  [AnalyticsTrackKind.ToggleRuleFromFlow]: undefined;
  [AnalyticsTrackKind.PolicyRuleCRUD]:
    | {
        action: 'open-kinds-list';
        cardSide: CardSide;
        cardKind: CardKind;
      }
    | {
        action: 'start-new-rule';
        cardSide: CardSide;
        cardKind: CardKind;
        ruleKind: RuleKind;
      }
    | {
        action: 'open-endpoint';
        cardSide: CardSide;
        cardKind: CardKind;
        endpointKind: EndpointKind;
      }
    | {
        action: 'open-pod-selector';
      }
    | {
        action: 'create-endpoint';
        cardSide: CardSide;
        cardKind: CardKind;
        endpointKind: EndpointKind;
      }
    | {
        action: 'update-endpoint';
        cardSide: CardSide;
        cardKind: CardKind;
        endpointKind: EndpointKind;
      }
    | {
        action: 'delete-endpoint';
        cardSide: CardSide;
        cardKind: CardKind;
        endpointKind: EndpointKind;
      };
  [AnalyticsTrackKind.StartSharePolicyViaGist]: undefined;
  [AnalyticsTrackKind.CompleteSharePolicyViaGist]: undefined;
  [AnalyticsTrackKind.FailedSharePolicyViaGist]: undefined;
};

export function track<
  K extends AnalyticsTrackKind,
  P extends AnalyticsTrackPayload[K]
>(
  kind: K,
  payload?: P,
  options?: SegmentAnalytics.SegmentOpts,
  callback?: () => void,
) {
  'analytics' in window &&
    window.analytics.track(kind, payload, options, callback);
}
