import React, { memo } from 'react';
import classnames from 'classnames';

import css from './Rating.scss';
import { Tooltip } from '@blueprintjs/core';

export interface Props {
  points: number;
  disabled?: boolean;
  hideTooltip?: boolean;
}

export const Rating = memo<Props>(function Rating(props) {
  const items: JSX.Element[] = [];
  for (let i = 1; i <= 5; i++) {
    const className = classnames(css.item, {
      [css.colored]: i <= props.points,
    });
    items.push(<div key={i} className={className} />);
  }
  const className = classnames(css.wrapper, { [css.disabled]: props.disabled });

  if (props.hideTooltip) {
    return <div className={className}>{items}</div>;
  }

  const rating = `${props.points}/5`;
  const status = props.disabled ? 'Disabled' : 'Enabled';
  const tooltip = `Rule rating: ${rating}. ${status}`;
  return (
    <Tooltip content={tooltip} minimal>
      <div className={className}>{items}</div>
    </Tooltip>
  );
});
