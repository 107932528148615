import _ from 'lodash';
import { makeAutoObservable } from 'mobx';
import { Flow } from '~/domain/flows';

export default class FlowsStore {
  public static readonly FLOWS_MAX_COUNT = 100000;

  private flows: Array<Flow>;

  constructor() {
    makeAutoObservable(this);
    this.flows = [];
  }

  get list() {
    return this.flows.slice();
  }

  add(flows: Flow[]) {
    this.flows = _(flows)
      .concat(this.flows)
      .uniqBy(f => f.id)
      .sort((a, b) => {
        return (b.millisecondsTimestamp ?? 0) - (a.millisecondsTimestamp ?? 0);
      })
      .slice(0, FlowsStore.FLOWS_MAX_COUNT)
      .value();

    return {
      flowsTotalCount: this.flows.length,
      flowsDiffCount: flows.length,
    };
  }
}
