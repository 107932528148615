import { Classes, Intent, ITooltipProps, Tooltip } from '@blueprintjs/core';
import React from 'react';
import { useTooltip } from '~/ui/hooks/useTooltip';

export const LearningTooltip: React.FunctionComponent<ITooltipProps> = props => {
  const tooltip = useTooltip({
    minimal: true,
    usePortal: false,
    content: props.content,
    openOnTargetFocus: false,
  });

  return (
    <Tooltip
      {...tooltip.props}
      className={Classes.TOOLTIP_INDICATOR}
      autoFocus={false}
      openOnTargetFocus={false}
      content={props.content}
    >
      {props.children}
    </Tooltip>
  );
};

export const CiliumNetworkPoliciesTooltip = ({ capitalize = false }) => (
  <LearningTooltip
    content={
      <p>
        The CiliumNetworkPolicy is very similar to the standard NetworkPolicy.
        The purpose is provide the functionality which is not yet supported in
        NetworkPolicy, for example toFQDNs policy rules.
      </p>
    }
  >
    <>Cilium Network Policy</>
  </LearningTooltip>
);

export const AllowedTooltip = ({ capitalize = false, byOtherRule = false }) => (
  <LearningTooltip
    content={
      <p>
        Pods become isolated by having a NetworkPolicy that selects them. Once
        there is any NetworkPolicy in a namespace selecting a particular pod,
        that pod will reject any connections that are not allowed by any
        NetworkPolicy
      </p>
    }
  >
    <span className={Classes.INTENT_SUCCESS}>
      {capitalize ? 'A' : 'a'}llowed{byOtherRule ? ' by other rule' : ''}
    </span>
  </LearningTooltip>
);

export const DroppedTooltip = ({ capitalize = false }) => (
  <LearningTooltip
    intent={Intent.DANGER}
    content={
      <p>
        Network policies do not conflict; they are additive. If any policy or
        policies select a pod, the pod is restricted to what is allowed by the
        union of those policies ingress/egress rules. Thus, order of evaluation
        does not affect the policy result.
      </p>
    }
  >
    <>{capitalize ? 'D' : 'd'}ropped, unless allowed by other rules</>
  </LearningTooltip>
);

export const IngressTooltip = ({ capitalize = false }) => (
  <LearningTooltip content={<p>Network traffic entering a pod</p>}>
    <>{capitalize ? 'I' : 'i'}ngress traffic</>
  </LearningTooltip>
);

export const EgressTooltip = ({ capitalize = false }) => (
  <LearningTooltip content={<p>Network traffic leaving a pod</p>}>
    <>{capitalize ? 'E' : 'e'}gress traffic</>
  </LearningTooltip>
);
