import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Service, ServiceSelector } from '~/domain/cilium/cnp/types';
import { PolicyCard } from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import {
  EndpointAllKind,
  EndpointKind,
  EndpointMatchExpression,
  EndpointRequirementOperator,
  PolicyKind,
} from '~/domain/cimulator/types';
import {
  endpointKindFromRuleKind,
  normNamespaceMatchExpressions,
  normNamespaceMatchLabels,
  RuleData,
  RuleKind,
  ruleKindFromEndpointKind,
  toNamespaceMatchExpressions,
  toNamespaceMatchLabels,
  toSelector,
} from './general';
import { validateRuleData } from './utils';

export function useStringInputState(defaultState = '') {
  const [value, setValue] = useState<string>(defaultState);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(event.target.value);
    },
    [],
  );

  return useMemo(
    () => ({
      value,
      setValue,
      onChange,
    }),
    [value, setValue, onChange],
  );
}

export function useTypedState<T>(defaultState: T) {
  const [value, setValue] = useState<T>(defaultState);

  const onChange = useCallback((nextValue: T) => {
    setValue(nextValue);
  }, []);

  return useMemo(
    () => ({
      value,
      setValue,
      onChange,
    }),
    [value, setValue, onChange],
  );
}

export function useBooleanState(defaultState = false) {
  const [value, setValue] = useState<boolean>(defaultState);

  const onToggle = useCallback(() => {
    setValue(!value);
  }, [value]);

  return useMemo(
    () => ({
      value,
      setValue,
      onToggle,
    }),
    [value, setValue, onToggle],
  );
}

export function useTagsState(defaultState: string[] = []) {
  const [value, setValue] = useState<string[]>(defaultState);

  const onChange = useCallback((value: React.ReactNode[]) => {
    setValue(value as string[]);
  }, []);

  return useMemo(
    () => ({
      value,
      setValue,
      onChange,
    }),
    [value, setValue, onChange],
  );
}

export function useMatchExpressionsState(
  defaultState: EndpointMatchExpression[],
) {
  const [value, setValue] = useState<EndpointMatchExpression[]>(defaultState);

  const onChange = useCallback((nextValue: EndpointMatchExpression[]) => {
    setValue(nextValue);
  }, []);

  return useMemo(
    () => ({
      value,
      setValue,
      onChange,
    }),
    [value, setValue, onChange],
  );
}

export function useEndpointFromRule(
  policyKind: PolicyKind,
  card: PolicyCard,
  rule: RuleData,
): [PolicyEndpoint | null, string | null] {
  const [endpoint, setEndpoint] = useState<PolicyEndpoint | null>(null);
  const [validationError, setValidationError] = useState<string | null>(null);

  useEffect(() => {
    const validationError = validateRuleData(policyKind, card, rule);
    if (validationError) {
      return setValidationError(validationError.message);
    }

    const endpointKind = endpointKindFromRuleKind(rule.kind);
    if (endpointKind === null) {
      return setValidationError('Unsupported rule type');
    }

    let endpoint: PolicyEndpoint;
    switch (endpointKind) {
      case EndpointKind.KubeDns: {
        endpoint = PolicyEndpoint.newKubeDns();
        break;
      }
      case EndpointKind.Fqdn: {
        endpoint = PolicyEndpoint.newFQDN();
        const fqdn = rule.fqdn.trim();
        if (!fqdn) break;
        endpoint.setFqdnFromMatch(fqdn);
        break;
      }
      case EndpointKind.Cidr: {
        endpoint = PolicyEndpoint.newCIDR();
        const cidr = rule.cidr.trim();
        if (!cidr) break;
        if (cidr === '0.0.0.0/32' && !rule.exceptCidrs.length) {
          endpoint = PolicyEndpoint.newAll();
        } else {
          endpoint.setCidrFromStr(cidr, rule.exceptCidrs);
        }
        break;
      }
      case EndpointKind.Service: {
        endpoint = PolicyEndpoint.newService();

        const podSelector = toSelector(rule.podSelector);
        if (!podSelector) break;

        endpoint.setAllKind(
          rule.namespaceSelector.selectAll
            ? EndpointAllKind.AllNamespacesSelector
            : null,
        );

        const service: Service = {};

        const getPlainMatchLabelValue = (matchLabels?: {
          [key: string]: string | null;
        }) => {
          if (!matchLabels || Object.keys(matchLabels).length !== 1) {
            return null;
          }
          const entry = Object.entries(matchLabels)[0];
          return entry[1] ? null : entry[0];
        };

        const plainMatchLabelValue = getPlainMatchLabelValue(
          podSelector.matchLabels,
        );

        if (!podSelector.matchExpressions?.length && plainMatchLabelValue) {
          service.k8sService = { serviceName: plainMatchLabelValue };
        } else {
          const selector: ServiceSelector = {};
          if (Object.keys(podSelector.matchLabels ?? {}).length) {
            selector.matchLabels = { ...podSelector.matchLabels };
          }
          if (podSelector.matchExpressions?.length) {
            selector.matchExpressions = [...podSelector.matchExpressions];
          }
          if (Object.keys(selector).length) {
            service.k8sServiceSelector = { selector };
          }
        }

        const namespaceSelector = rule.namespaceSelector.selectAll
          ? null
          : toSelector(rule.namespaceSelector);

        if (namespaceSelector?.matchLabels) {
          const plainNamespaceValue = getPlainMatchLabelValue(
            namespaceSelector.matchLabels,
          );
          if (plainNamespaceValue) {
            if (service.k8sService) {
              service.k8sService.namespace = plainNamespaceValue;
            } else if (service.k8sServiceSelector) {
              service.k8sServiceSelector.namespace = plainNamespaceValue;
            } else {
              service.k8sService = { namespace: plainNamespaceValue };
            }
          }
        }
        endpoint.setService(service);
        break;
      }
      case EndpointKind.LabelsSelector: {
        endpoint = PolicyEndpoint.newLabelsSelector();

        const podSelector = toSelector(rule.podSelector);
        if (!podSelector) break;

        endpoint.setAllKind(
          rule.namespaceSelector.selectAll
            ? EndpointAllKind.AllNamespacesSelector
            : null,
        );

        const namespaceSelector = rule.namespaceSelector.selectAll
          ? null
          : toSelector(rule.namespaceSelector);

        if (namespaceSelector?.matchLabels) {
          namespaceSelector.matchLabels = toNamespaceMatchLabels(
            policyKind,
            namespaceSelector.matchLabels,
          );
        }

        if (namespaceSelector?.matchExpressions) {
          namespaceSelector.matchExpressions = toNamespaceMatchExpressions(
            namespaceSelector.matchExpressions,
          );
        }

        const selector = {
          matchLabels: podSelector.matchLabels
            ? { ...podSelector.matchLabels }
            : undefined,
          matchExpressions: podSelector.matchExpressions
            ? [...podSelector.matchExpressions]
            : undefined,
        };

        if (namespaceSelector?.matchLabels) {
          selector.matchLabels = {
            ...selector.matchLabels,
            ...namespaceSelector?.matchLabels,
          };
        }

        if (namespaceSelector?.matchExpressions) {
          if (!selector.matchExpressions) selector.matchExpressions = [];
          selector.matchExpressions = [
            ...selector.matchExpressions,
            ...namespaceSelector.matchExpressions,
          ];
        }
        endpoint.setSelector(selector);
        break;
      }
      case EndpointKind.NamespaceSelector: {
        endpoint = PolicyEndpoint.newNamespaceSelector();
        const selector = toSelector(rule.namespaceSelector);
        if (!selector) break;
        if (selector?.matchLabels) {
          selector.matchLabels = toNamespaceMatchLabels(
            policyKind,
            selector.matchLabels,
          );
        }
        if (selector?.matchExpressions) {
          selector.matchExpressions = toNamespaceMatchExpressions(
            selector.matchExpressions,
          );
        }
        endpoint.setSelector(selector);
        break;
      }
      case EndpointKind.All: {
        endpoint = PolicyEndpoint.newAll();
        break;
      }
      default:
        endpoint = PolicyEndpoint.fromKind(endpointKind);
        break;
    }
    endpoint.addPortsFromStringArray(rule.ports);

    return setValidationError(null), setEndpoint(endpoint);
  }, [
    policyKind,
    card,
    rule.kind,
    rule.podSelector.matchLabels,
    rule.podSelector.matchExpressions,
    rule.namespaceSelector.selectAll,
    rule.namespaceSelector.matchLabels,
    rule.namespaceSelector.matchExpressions,
    rule.fqdn,
    rule.cidr,
    rule.exceptCidrs,
    rule.ports,
  ]);

  return [endpoint, validationError];
}

export function useRuleState(
  policyKind: PolicyKind,
  card: PolicyCard,
  endpoint?: PolicyEndpoint | null,
  initRuleKind?: RuleKind | null,
) {
  const [ruleKind, setRuleKind] = useState<RuleKind | null>(
    endpoint ? ruleKindFromEndpointKind(endpoint) : initRuleKind ?? null,
  );

  const kind = useMemo(
    () => ({
      value: ruleKind,
      setValue: setRuleKind,
      onChange: setRuleKind,
    }),
    [ruleKind, setRuleKind],
  );

  const isKNP = policyKind === PolicyKind.KNP;

  const isKNPInNamespacePodSelector =
    isKNP && card.isInNamespace && ruleKind === RuleKind.PodSelector;

  const isInClusterPodSelector =
    card.isInCluster && ruleKind === RuleKind.PodSelector;

  const namespaceSelectorAll = useBooleanState(
    Boolean(isInClusterPodSelector && endpoint?.selectsAllNamespaces),
  );
  const namespaceSelectorMatchLabels = useTagsState(
    isKNPInNamespacePodSelector
      ? []
      : endpoint?.isService
      ? endpoint?.namespace
        ? [endpoint.namespace]
        : []
      : normNamespaceMatchLabels(
          policyKind,
          endpoint?.namespaceMatchLabelsArray ?? [],
        ),
  );
  const namespaceSelectorMatchExpressions = useMatchExpressionsState(
    isKNPInNamespacePodSelector || endpoint?.isService
      ? []
      : normNamespaceMatchExpressions(
          endpoint?.namespaceMatchExpressions ?? [],
        ),
  );

  const podSelectorMatchLabels = endpoint?.service?.k8sService?.serviceName
    ? useTagsState([endpoint.service.k8sService.serviceName])
    : useTagsState(
        isKNPInNamespacePodSelector
          ? endpoint?.matchLabelsArray ?? []
          : endpoint?.podMatchLabelsArray ?? [],
      );
  const podSelectorMatchExpressions = useMatchExpressionsState(
    isKNPInNamespacePodSelector
      ? endpoint?.matchExpressions ?? []
      : endpoint?.podMatchExpressions ?? [],
  );

  const fqdn = useStringInputState(endpoint?.fqdnMatch ?? '');
  const cidr = useStringInputState(endpoint?.cidrStr ?? '');
  const exceptCidrs = useTagsState(endpoint?.exceptCidrsArray ?? []);

  const ports = useTagsState(endpoint?.portsStringArray ?? []);

  const values: RuleData = useMemo(
    () => ({
      kind: kind.value,
      namespaceSelector: {
        selectAll: namespaceSelectorAll.value,
        matchLabels: namespaceSelectorMatchLabels.value,
        matchExpressions: namespaceSelectorMatchExpressions.value,
      },
      podSelector: {
        matchLabels: podSelectorMatchLabels.value,
        matchExpressions: podSelectorMatchExpressions.value,
      },
      fqdn: fqdn.value,
      cidr: cidr.value,
      exceptCidrs: exceptCidrs.value,
      ports: ports.value,
    }),
    [
      kind.value,
      namespaceSelectorAll.value,
      namespaceSelectorMatchLabels.value,
      namespaceSelectorMatchExpressions.value,
      podSelectorMatchLabels.value,
      podSelectorMatchExpressions.value,
      fqdn.value,
      cidr.value,
      exceptCidrs.value,
      ports.value,
    ],
  );

  const [ruleEndpoint, validationError] = useEndpointFromRule(
    policyKind,
    card,
    values,
  );

  const rule = useMemo(
    () => ({
      kind,
      namespaceSelector: {
        selectAll: namespaceSelectorAll,
        matchLabels: namespaceSelectorMatchLabels,
        matchExpressions: namespaceSelectorMatchExpressions,
      },
      podSelector: {
        matchLabels: podSelectorMatchLabels,
        matchExpressions: podSelectorMatchExpressions,
      },
      fqdn,
      cidr,
      exceptCidrs,
      ports,
      values,
    }),
    [
      kind,
      namespaceSelectorAll,
      namespaceSelectorMatchLabels,
      namespaceSelectorMatchExpressions,
      podSelectorMatchLabels,
      podSelectorMatchExpressions,
      fqdn,
      cidr,
      exceptCidrs,
      ports,
      values,
    ],
  );

  return { rule, ruleEndpoint, validationError };
}

export function useMatchExpressionCrud(
  items: EndpointMatchExpression[],
  onChange: (nextItems: EndpointMatchExpression[]) => void,
) {
  const add = useCallback(() => {
    onChange([
      ...items,
      { key: '', operator: EndpointRequirementOperator.In, values: [] },
    ]);
  }, [onChange, items]);

  const remove = useCallback(
    (idxToDelete: number) => {
      onChange(items.filter((_value, idx) => idx !== idxToDelete));
    },
    [onChange, items],
  );

  const update = useCallback(
    (idxToUpdate: number, updateExpr: EndpointMatchExpression) => {
      onChange(
        items.map((expr, idx) => (idx === idxToUpdate ? updateExpr : expr)),
      );
    },
    [onChange, items],
  );

  return useMemo(() => ({ add, remove, update }), [add, remove, update]);
}
